import { Injectable } from "@angular/core";
import moment from "moment";
import { ProfileSimplified } from "src/app/_interfaces/licenseSimplifiedVersion";
import { HelperService } from "src/app/_services/helper.service";
import { ObjectUtilsService } from "src/app/_services/object-utils.service";
import { GenderUiPbEnum } from "src/app/com/staybloom/common/uiproto/genderUiProto";
import {
  BookersRepresentativeUiPb,
  CustomerReferenceUiPb,
  CustomerTypeEnum,
} from "src/app/com/staybloom/customerShared/uiproto/customerUiProto";
import {
  ProfileTypeUi,
  ProfileUiPb,
} from "src/app/com/staybloom/profile/uiproto/profileUiProto";
import { IdVerificationStatusUiEnum } from "src/app/com/staybloom/profile/uiproto/verificationUiProto";
import { GuestListInfoUiPb } from "src/app/com/staybloom/reservation/uiproto/guestListInfoUiProto";
import {
  VerifiedUserStateUi,
  VerifiedUserUiPb,
} from "src/app/com/staybloom/reservation/uiproto/usageUiProto";
import { ProfileFormData } from "src/app/models/Profile";

@Injectable({
  providedIn: "root",
})
export class ProfileStoreTransformerService {
  constructor(
    private objectUtils: ObjectUtilsService,
    private helperService: HelperService
  ) {}
  // getSpecificGuestProfile(profile: ProfileUiPb) {
  //   let result: ProfileSimplified = {};
  //   result = {
  //     id: profile.id,
  //     firstName: profile?.details?.username?.firstName,
  //     lastName: profile?.details?.username?.lastName,
  //     email:
  //       profile?.defaultProfileForEmail && profile?.defaultProfileForEmail[0],
  //     stayCount:
  //       profile?.details?.enrichmentSnapshot?.snapshotAsGuest?.totalStays,
  //     lastStay:
  //       profile?.details?.enrichmentSnapshot?.snapshotAsGuest?.lastStay?.hotel,
  //     profilePicture: profile?.details?.profilePicture?.signedURL,
  //     mobile:
  //       profile?.details?.mobilePhoneIds &&
  //       profile?.details?.mobilePhoneIds[0]?.mobileNumber,
  //     age: profile?.details?.age?.dateOfBirth?.formattedDate,
  //     gender: profile?.details?.gender,
  //   };
  //   return result;
  // }
  getPrimaryGuest(data: GuestListInfoUiPb) {
    let primaryGuestProfile: ProfileSimplified = {};
    let masterGuest = data?.masterGuest[data.primaryGuestIndex];
    primaryGuestProfile.firstName =
      masterGuest?.details?.username?.firstName || "";
    primaryGuestProfile.lastName =
      masterGuest?.details?.username?.lastName || "";
    primaryGuestProfile.age =
      masterGuest?.details?.age &&
      moment()
        .diff(
          moment(
            masterGuest?.details?.age?.dateOfBirth?.formattedDate,
            "YYYYMMDD"
          ),
          "years"
        )
        .toString();
    primaryGuestProfile.id = masterGuest?.id;
    primaryGuestProfile.email = this.getEmailForProfile(masterGuest);
    primaryGuestProfile.mobile =
      (masterGuest?.details?.mobilePhoneIds &&
        masterGuest?.details?.mobilePhoneIds[0]?.countryCode +
          masterGuest?.details?.mobilePhoneIds[0]?.mobileNumber) ||
      "";
    primaryGuestProfile.nationality =
      masterGuest?.details?.nationality && masterGuest?.details?.nationality;
    primaryGuestProfile.gender =
      masterGuest?.details?.gender && masterGuest?.details?.gender;
    primaryGuestProfile.profileImage =
      masterGuest?.details?.profilePicture &&
      masterGuest?.details?.profilePicture?.signedURL;
    primaryGuestProfile.stayCount =
      masterGuest?.details?.enrichmentSnapshot?.snapshotAsGuest?.totalStays;
    primaryGuestProfile.primaryGuestIndex = data?.primaryGuestIndex;

    return this.objectUtils.removeEmptyKeys(primaryGuestProfile);
  }

  getMasterGuestList(data: ProfileUiPb[]): ProfileSimplified[] {
    let resultProfileArray: ProfileSimplified[] = [];
    data?.forEach((profile: ProfileUiPb) => {
      let guestProfile: ProfileSimplified = {};
      guestProfile = this.profileUiPbToProfileSimplifed(profile);
      // guestProfile.firstName =
      //   (profile.details.username.firstName &&
      //     profile.details.username.firstName) ||
      //   "";
      // guestProfile.lastName =
      //   (profile.details.username.lastName &&
      //     profile.details.username.lastName) ||
      //   "";
      // guestProfile.age =
      //   profile.details.age &&
      //   moment()
      //     .diff(
      //       moment(profile.details.age.dateOfBirth.formattedDate, "YYYYMMDD"),
      //       "years"
      //     )
      //     .toString();
      // guestProfile.id = profile.id;
      // guestProfile.email = profile?.defaultProfileForEmail
      //   ? profile?.defaultProfileForEmail[0]
      //   : "";
      // guestProfile.mobile =
      //   (profile.details.mobilePhoneIds &&
      //     profile.details.mobilePhoneIds[0].countryCode +
      //       profile.details.mobilePhoneIds[0].mobileNumber) ||
      //   "";
      // guestProfile.nationality =
      //   profile.details.nationality && profile.details.nationality;
      // guestProfile.gender = profile.details.gender && profile.details.gender;
      // guestProfile.profileImage =
      //   profile.details.profilePicture &&
      //   profile.details.profilePicture.signedURL;
      // (guestProfile.stayCount =
      //   profile.details?.enrichmentSnapshot?.snapshotAsGuest?.totalStays),
      resultProfileArray.push(guestProfile);
    });
    return resultProfileArray;
  }

  getBooker(buyer: CustomerReferenceUiPb) {
    let booker: ProfileSimplified = {};
    booker.companyName =
      buyer.customerType === CustomerTypeEnum.COMPANY ? buyer?.companyName : "";
    booker.email = buyer?.customerUser?.primaryEmail;
    booker.firstName = buyer?.customerUser?.name?.firstName || "";
    booker.lastName = buyer?.customerUser?.name?.lastName || "";
    booker.id = buyer?.id;
    booker.profileType = buyer?.customerType;
    return booker;
  }

  getBookersRepresentativeProfile(representative: BookersRepresentativeUiPb) {
    let result: ProfileSimplified = {};

    result.firstName = representative?.firstName;
    result.lastName = representative?.lastName;
    result.email = representative?.email;
    result.mobile = representative?.phoneNo;

    return result;
  }
  getVerifiedUsers(users: VerifiedUserUiPb[]): ProfileSimplified[] {
    let response: ProfileSimplified[] = [];
    if (users && users.length) {
      users.forEach((user) => {
        const newUser: ProfileSimplified = {
          firstName: user?.name?.firstName,
          lastName: user?.name?.lastName,
          age: user?.age?.dateOfBirth?.formattedDate,
          email: user?.email?.email,
          gender: user?.gender,
          id: user?.staybloomId,
          mobile: user?.telephone?.value,
          nationality: user?.nationality,
          stayCount: user?.stayCountAtTheTime,
          formC: user?.formC,
          grc: user?.grc,
          mobileNumber: user?.telephone?.value,
          countryCode: user?.telephone?.countryCode,
          checkInType: user?.checkInType,
          originalCheckInType: user?.originalCheckInType,
          verifiedGuestCheckedInTime:
            user?.usageTimings?.startTime?.eventTime?.milliSeconds,
          verifiedGuestCheckOutTime:
            user?.usageTimings?.endTime?.eventTime?.milliSeconds,
          addressDetails: user?.address,
          idProofVerifiedBy: user?.idProofVerifiedBy,
          idVerificationTime: user?.idProofVerificationTime?.milliSeconds,
          checkInVerificationTime: user?.checkInVerificationTime,
          checkInVerified: user?.checkInVerified,
          userState: user?.userState,
          identityDetails: {
            idType:
              user?.verificationInfo?.verifiedIds?.[0]?.idType?.physicalIdType,
            verificationId: user?.verificationInfo?.verifiedIds[0]?.ids[0]?.id,
            providingAuthority:
              user?.verificationInfo?.verifiedIds?.[0]?.ids[0]
                ?.issuingAuthority,
            placeOfIssue:
              user?.verificationInfo?.verifiedIds?.[0]?.ids[0]?.placeOfIssue,
            dateOfIssue:
              user?.verificationInfo?.verifiedIds?.[0]?.ids[0]?.validFrom
                ?.formattedDate,
            validUntil:
              user?.verificationInfo?.verifiedIds?.[0]?.ids[0]?.validTill
                ?.formattedDate,
            idVerificationStatus:
              user?.verificationInfo?.verifiedIds?.[0]?.ids[0]
                ?.idVerificationStatus,
            idProofFront:
              user?.verificationInfo?.verifiedIds?.[0]?.ids[0]?.front
                ?.signedURL,
            idProofBack:
              user?.verificationInfo?.verifiedIds?.[0]?.ids[0]?.back?.signedURL,
          },
        };
        response.push(newUser);
      });
    }

    return response;
  }
  getNotCheckedInGuests(
    guestListInfo: ProfileSimplified[],
    checkedInGuests: ProfileSimplified[]
  ): ProfileSimplified[] {
    let result: ProfileSimplified[] = [];
    // if (checkedInGuests) {
    let checkedInIds = checkedInGuests?.map((ele) => ele.id);
    console.log(checkedInIds);
    guestListInfo?.forEach((ele2, i) => {
      let checkedIndex = checkedInIds.indexOf(ele2.id);
      if (checkedIndex == -1) {
        result.push(ele2);
      }
    });
    return result;
  }

  profileSimplifedToProfileUiPb(
    profileDetailSimplified: ProfileSimplified,
    id: string
  ): ProfileUiPb {
    const transformedData: ProfileUiPb = {
      id: profileDetailSimplified?.id,
      profileType: profileDetailSimplified?.profileType,
      details: {
        username: {
          firstName: profileDetailSimplified?.firstName,
          lastName: profileDetailSimplified?.lastName,
        },
        mobilePhoneIds: [
          {
            countryCode: profileDetailSimplified?.countryCode,
            mobileNumber: profileDetailSimplified?.mobileNumber,
          },
        ],
        age: {
          dateOfBirth: {
            formattedDate: profileDetailSimplified?.age
              ? moment(profileDetailSimplified.age).format("YYYYMMDD")
              : null,
          },
        },
        attributes: profileDetailSimplified?.attributes,
        verificatonIds: [
          {
            id: profileDetailSimplified?.identityDetails?.verificationId,
            issuingAuthority:
              profileDetailSimplified?.identityDetails?.providingAuthority,
            placeOfIssue:
              profileDetailSimplified?.identityDetails?.placeOfIssue,
            identity: profileDetailSimplified?.identityDetails?.idType,
            front: null,
            back: null,
            idVerificationStatus:
              profileDetailSimplified?.identityDetails?.idVerificationStatus,
          },
        ],
        subscriptionStatus: profileDetailSimplified?.subscriptionStatus,
      },
      defaultProfileForEmail: [profileDetailSimplified?.email],
      sellerDBId: {
        id: id,
      },
      freeText: profileDetailSimplified?.notes?.freeText,
      housekeepingFreeText:
        profileDetailSimplified?.notes?.housekeepingFreeText,
    };
    return this.objectUtils.removeEmptyKeys(transformedData);
  }

  hasCheck(data: any[]) {
    if (data && data.length) {
      return data;
    } else {
      return null;
    }
  }

  //ui-integration use

  profileUiPbToProfileSimplifed(profileUiPb: ProfileUiPb): ProfileSimplified {
    const transformedData: ProfileSimplified = {
      id: profileUiPb?.id,
      profileType: profileUiPb?.profileType,
      firstName: profileUiPb?.details?.username?.firstName,
      lastName: profileUiPb?.details?.username?.lastName,
      fullName:
        profileUiPb?.details?.username?.firstName +
        (profileUiPb?.details?.username?.lastName
          ? " " + profileUiPb.details.username.lastName
          : ""),

      email: this.getEmailForProfile(profileUiPb),
      mobile: profileUiPb?.details?.mobilePhoneIds?.length
        ? profileUiPb?.details?.mobilePhoneIds[0]?.countryCode +
          profileUiPb?.details?.mobilePhoneIds[0]?.mobileNumber
        : null,
      countryCode: profileUiPb?.details?.mobilePhoneIds?.length
        ? profileUiPb?.details?.mobilePhoneIds[0]?.countryCode
        : "",
      mobileNumber: profileUiPb?.details?.mobilePhoneIds?.length
        ? profileUiPb?.details?.mobilePhoneIds[0]?.mobileNumber
        : "",
      companyName: profileUiPb?.details?.attributes?.company,
      nationality: profileUiPb?.details?.nationality,
      age: profileUiPb?.details?.age?.dateOfBirth?.formattedDate,
      gender: profileUiPb?.details?.gender,
      profileImage: profileUiPb?.details?.profilePicture?.signedURL,
      stayCount:
        profileUiPb?.details?.enrichmentSnapshot?.snapshotAsGuest?.totalStays,
      lastStay:
        profileUiPb?.details?.enrichmentSnapshot?.snapshotAsGuest?.lastStay,
      profilePicture: profileUiPb?.details?.profilePicture?.signedURL,
      subscriptionStatus: profileUiPb?.details?.subscriptionStatus,
      identityDetails: {
        idType: profileUiPb?.details?.verificatonIds?.length
          ? profileUiPb?.details?.verificatonIds[0]?.identity
          : null,
        verificationId: profileUiPb?.details?.verificatonIds?.length
          ? profileUiPb?.details?.verificatonIds[0]?.id
          : null,
        providingAuthority: profileUiPb?.details?.verificatonIds?.length
          ? profileUiPb?.details?.verificatonIds[0]?.issuingAuthority
          : null,
        placeOfIssue: profileUiPb?.details?.verificatonIds?.length
          ? profileUiPb?.details.verificatonIds[0]?.placeOfIssue
          : null,
        nationality: profileUiPb?.details?.nationality,
        dateOfIssue: profileUiPb?.details?.verificatonIds?.length
          ? this.convertToNewDate(
              profileUiPb?.details?.verificatonIds[0]?.validFrom?.formattedDate
            )
          : null,
        validUntil: profileUiPb?.details?.verificatonIds?.length
          ? this.convertToNewDate(
              profileUiPb?.details?.verificatonIds[0]?.validTill?.formattedDate
            )
          : null,
        idProofFront: profileUiPb?.details?.verificatonIds?.length
          ? profileUiPb?.details?.verificatonIds[0]?.front?.signedURL
          : null,
        idProofBack: profileUiPb?.details?.verificatonIds?.length
          ? profileUiPb?.details.verificatonIds[0]?.back?.signedURL
          : null,
        idVerificationStatus:
          profileUiPb?.details?.verificatonIds &&
          profileUiPb?.details?.verificatonIds[0]?.idVerificationStatus
            ? profileUiPb?.details?.verificatonIds[0]?.idVerificationStatus
            : IdVerificationStatusUiEnum.NOT_VERIFIED,
      },
      addressDetails: profileUiPb?.details?.address,
      attributes: profileUiPb?.details?.attributes,
      notes: {
        freeText: profileUiPb?.freeText,
        housekeepingFreeText: profileUiPb?.housekeepingFreeText,
      },
      suspend: profileUiPb?.suspend,
      snapshotAsGuest:
        profileUiPb?.details?.enrichmentSnapshot?.snapshotAsGuest,
      snapshotAsBooker:
        profileUiPb?.details?.enrichmentSnapshot?.snapshotAsBooker,
      snapshotAsFoodie:
        profileUiPb?.details?.enrichmentSnapshot?.snapshotAsFoodie,
      profileCompletionScore: profileUiPb?.details?.profileCompletionScore
        ? Number(profileUiPb?.details?.profileCompletionScore).toFixed(0)
        : undefined,
    };
    return this.objectUtils.removeEmptyKeys(transformedData);
  }

  //forms use api post
  profileSimplifiedToProfileFormConverter(
    profileSimplified: ProfileSimplified
  ) {
    let transformData = {
      profileDetails: {
        firstName: profileSimplified?.firstName,
        lastName: profileSimplified?.lastName,
        email: profileSimplified?.email,
        age: this.convertToNewDate(profileSimplified?.age),
        mobileNumber: profileSimplified?.mobileNumber,
        countryCode: profileSimplified?.countryCode,
        profileImage: profileSimplified?.profileImage,
        gender: profileSimplified?.gender,
        id: profileSimplified?.id,
        profileCompelitionScore: profileSimplified?.profileCompletionScore,
      },
      identityDetails: profileSimplified?.identityDetails,
      addressDetails: profileSimplified?.addressDetails,
      notes: profileSimplified?.notes,
      attributes: profileSimplified?.attributes,
      subscriptionStatus: profileSimplified?.subscriptionStatus,
      otherSettings: {
        suspend: profileSimplified?.suspend,
      },
    };
    return this.objectUtils.removeEmptyKeys(transformData);
  }
  profileFormToProfileUiPbConverter(
    profileFormData: ProfileFormData,
    profileUiPb: ProfileUiPb = {},
    id: string
  ): ProfileUiPb {
    profileUiPb.id = profileFormData?.profileDetails?.id || "Insert";
    profileUiPb.profileType = ProfileTypeUi.INDIVIDUAL;
    profileUiPb.details = {
      alias: profileUiPb?.details?.alias,
      profilePicture: profileUiPb?.details?.profilePicture,
      profileCode: profileUiPb?.details?.profileCode,
      profileName: profileUiPb?.details?.profileName,
      verificatonIds: [
        {
          id: profileFormData?.identityDetails?.verificationId,
          issuingAuthority:
            profileFormData?.identityDetails?.providingAuthority,
          placeOfIssue: profileFormData?.identityDetails?.placeOfIssue,
          identity: profileFormData?.identityDetails.idType,
          validFrom: {
            formattedDate: profileFormData?.identityDetails?.dateOfIssue
              ? moment(profileFormData?.identityDetails?.dateOfIssue).format(
                  "YYYYMMDD"
                )
              : null,
          },
          validTill: {
            formattedDate: profileFormData?.identityDetails?.validUntil
              ? moment(profileFormData?.identityDetails?.validUntil).format(
                  "YYYYMMDD"
                )
              : null,
          },
          front: profileUiPb?.details?.verificatonIds?.length
            ? profileUiPb.details.verificatonIds[0]?.front
            : null,
          back: profileUiPb?.details?.verificatonIds?.length
            ? profileUiPb.details.verificatonIds[0]?.back
            : null,
          idVerificationStatus: profileFormData?.identityDetails
            ?.idVerificationStatus
            ? profileFormData?.identityDetails?.idVerificationStatus
            : null,
        },
      ],
      mobilePhoneIds: [
        {
          countryCode: profileFormData?.profileDetails?.countryCode,
          mobileNumber: profileFormData?.profileDetails?.mobileNumber,
        },
      ],
      address: profileFormData?.addressDetails,
      nationality: profileFormData?.identityDetails?.nationality,
      age: {
        dateOfBirth: {
          formattedDate: profileFormData?.profileDetails?.age
            ? moment(profileFormData?.profileDetails.age).format("YYYYMMDD")
            : null,
        },
      },
      loyaltySnapshot: profileUiPb?.details?.loyaltySnapshot,
      subscriptionStatus: profileFormData?.subscriptionStatus,
      billingAddress: profileUiPb?.details?.billingAddress,
      username: {
        firstName: profileFormData?.profileDetails.firstName,
        lastName: profileFormData?.profileDetails.lastName,
      },
      GSTInfo: profileUiPb?.details?.GSTInfo,

      gender: profileFormData.profileDetails.gender,
      attributes: profileFormData?.attributes,
      enrichmentSnapshot: profileUiPb?.details?.enrichmentSnapshot,
      isNoBusinessLogicValidation:
        profileUiPb?.details?.isNoBusinessLogicValidation,
    };
    profileUiPb.defaultProfileForEmail = [
      profileFormData?.profileDetails?.email,
    ];
    profileUiPb.sellerDBId = {
      id: id,
    };
    profileUiPb.suspend = profileFormData?.otherSettings.suspend;
    profileUiPb.freeText = profileFormData?.notes?.freeText;
    profileUiPb.housekeepingFreeText =
      profileFormData?.notes?.housekeepingFreeText;

    return this.objectUtils.removeEmptyKeys(profileUiPb);
  }

  profileUiPbToExistingProfileFormConverter(profile: ProfileUiPb) {
    let exitingProfile = {
      guestName:
        profile?.details?.username?.firstName +
        " " +
        profile?.details?.username?.lastName,
      mobile:
        (profile?.details?.mobilePhoneIds?.length
          ? profile?.details?.mobilePhoneIds[0]?.countryCode
          : "") +
        " " +
        (profile?.details?.mobilePhoneIds
          ? profile?.details?.mobilePhoneIds[0]?.mobileNumber
          : ""),
      email: this.getEmailForProfile(profile),
      lastStay:
        profile?.details?.enrichmentSnapshot?.snapshotAsGuest?.lastStay
          ?.hotel || "No last stay",
    };
    return exitingProfile;
  }

  convertToNewDate(data: string) {
    if (data) {
      const formattedDate = moment(data, "YYYYMMDD").format("YYYY-MM-DD");
      return formattedDate;
    } else {
      return new Date();
    }
  }
  getProfileOtherDetail(profileDetail: ProfileSimplified): string {
    const profileArray = [];
    if (profileDetail?.age) {
      profileArray.push(this.getDecadesString(profileDetail.age));
    }
    if (profileDetail?.gender) {
      let gender = profileDetail.gender;
      profileArray.push(gender.charAt(0) + gender.slice(1).toLowerCase());
    }
    if (profileDetail?.nationality) {
      profileArray.push(profileDetail.nationality);
    }
    if (profileDetail?.stayCount) {
      let staycount =
        `${profileDetail.stayCount}` +
        (profileDetail.stayCount > 1 ? " stays" : " stay");
      profileArray.push(staycount);
    }
    return profileArray.join(", ");
  }
  getDecadesString(birthDateStr: string): string {
    const currentYear = new Date().getFullYear();
    const birthYear = Number(birthDateStr.slice(0, 4));

    if (isNaN(birthYear)) {
      return ""; // Return a message if the birth year is invalid or not provided
    }
    const age = currentYear - birthYear;
    const decade = Math.floor(age / 10) * 10;

    if (age < 0) {
      return ""; // Return a message if the person has not been born yet
    } else if (age < 20) {
      return `${age}'yrs`; // Show the exact age if it's less than 20 years old
    } else {
      return `${decade}'s`; // Show the decade's format (e.g., 20's, 30's, 40's, etc.)
    }
  }

  getGuestLoyalty(stayCount: number) {
    let result = { loyalty: "", imageUrl: "" };
    if (stayCount >= 1 && stayCount < 4) {
      result = {
        loyalty: "Road warrior",
        imageUrl: "./assets/images/less-star.svg",
      };
    } else if (stayCount >= 4 && stayCount < 10) {
      result = {
        loyalty: "Jet Setter",
        imageUrl: "./assets/images/more-star-half.svg",
      };
    } else if (stayCount >= 10) {
      result = {
        loyalty: "Ambassador",
        imageUrl: "./assets/images/full-star-border.svg",
      };
    }
    return result;
  }

  // getUserDetails(user: ProfileSimplified) {
  //   const totalStays = user?.stayCount;
  //   const age = user?.age ? this.helperService.getAge(user.age) : undefined;
  //   const nationality = user?.nationality;

  //   const totalStayText =
  //     totalStays === 1
  //       ? `${totalStays} stay`
  //       : totalStays > 1
  //       ? `${totalStays} stays`
  //       : undefined;

  //   const gender =
  //     user?.gender === GenderUiPbEnum.Male
  //       ? "Male"
  //       : user?.gender === GenderUiPbEnum.Female
  //       ? "Female"
  //       : undefined;

  //   const userDetailsArray = [age, gender, nationality, totalStayText].filter(
  //     (item) => item !== undefined
  //   );

  //   const userDetails = userDetailsArray.join(" • ");
  //   return userDetails;
  // }

  getUserDetails(user: ProfileSimplified) {
    const totalStays = user?.stayCount;
    const age = user?.age
      ? user.age !== 0
        ? this.helperService.getAge(user.age)
        : undefined
      : undefined;
    const nationality = user?.nationality;

    const totalStayText =
      totalStays === 1
        ? `${totalStays} stay`
        : totalStays > 1
        ? `${totalStays} stays`
        : undefined;

    const gender =
      user?.gender === GenderUiPbEnum.Male
        ? "Male"
        : user?.gender === GenderUiPbEnum.Female
        ? "Female"
        : undefined;

    const userDetailsArray = [age, gender, nationality, totalStayText].filter(
      (item) => item !== undefined
    );

    const userDetails = userDetailsArray.join(" • ");
    return userDetails;
  }

  getEmailForProfile(profile: ProfileUiPb): string {
    if (profile?.defaultProfileForEmail?.length) {
      return profile?.defaultProfileForEmail[0];
    } else if (profile?.details?.emailIds?.length) {
      return `${profile?.details?.emailIds[0].emailIds.localPart}@${profile?.details?.emailIds[0].emailIds.domainName}`;
    } else {
      return "";
    }
  }
}
