<div
  #container
  class="card_wrapper"
  cdkDrag
  cdkDragBoundary="wrapper"
  [appClickRestrict]="progress"
>
  <div class="card">
    <header class="header" cdkDragHandle>
      <p>{{ widgetInfo.title }}</p>
      <img
        src="/assets/icons/reservation_summary/edit_icon_gold.svg"
        alt="Edit Icon"
        class="edit_icon"
      />
    </header>
    <div
      class="body"
      [hidden]="
        !(
          widgetInfo?.widgetName != 'SIDEKICK_SETUP' ||
          (widgetInfo?.widgetName == 'SIDEKICK_SETUP' &&
            !(showFailure || showSuccess || showDisconnect))
        )
      "
    >
      <p class="desc">{{ widgetInfo.description }}</p>
      <app-dynamic-form-landing
        [formSections]="widgetInfo.form"
        [showButtons]="false"
        [valuesToPatch]="widgetInfo.formValues"
        (closeCalenderEvent)="isClose($event)"
      ></app-dynamic-form-landing>
      <div class="btn_container">
        <button class="btn btn-danger-outline btn-lg" (click)="cancel($event)">
          Cancel
        </button>
        <button
          class="btn btn-primary btn-lg"
          (click)="submitConfirm()"
          [appProgressButton]="progress"
          [buttonText]="'Save'"
          [disabled]="!enableButton"
        >
          Save
        </button>
      </div>
    </div>
    <ng-container *ngIf="widgetInfo?.widgetName == 'SIDEKICK_SETUP'">
      <div class="card__loader" *ngIf="showProgress">
        <img src="/assets/loaders/bloom_spinner.gif" alt="loader" />
        <p class="card__loader__text">
          {{ isLoggedIn ? "Connecting" : "Logging In" }}
        </p>
      </div>
      <div class="card__failure" *ngIf="showFailure">
        <p class="card__failure__text">
          {{ testErrorMessage }}
        </p>
        <object
          class="card__failure__cross"
          type="image/svg+xml"
          data="/assets/icons/animated/cancelled.svg"
        ></object>
        <div class="card__failure__buttons">
          <button
            class="btn btn-danger-outline btn-lg"
            (click)="cancel($event)"
          >
            Close
          </button>
          <button
            *ngIf="!testConnection"
            class="btn btn-primary btn-lg"
            (click)="
              testConnection ? pushSidekickScreen() : retrySidekickConnect()
            "
          >
            Retry
          </button>
          <button
            *ngIf="testConnection"
            class="card__success__text__send"
            (click)="pushSidekickScreen()"
            [class.processing]="!buttonEnabled"
            [ngStyle]="{ 'margin-left': '16px' }"
          >
            <img
              class="card__success__text__send__image"
              [src]="
                !buttonEnabled
                  ? '/assets/icons/action-dialog/iconDisabled.svg'
                  : '/assets/icons/action-dialog/sidekickBtn.svg'
              "
              alt=""
            />
            <span>Retry</span>
          </button>
        </div>
      </div>
      <div class="card__success" *ngIf="showSuccess && !showDisconnect">
        <p class="card__success__text">
          SideKick Device has been connected successfully!
        </p>
        <object
          class="card__success__tick"
          type="image/svg+xml"
          data="/assets/icons/animated/check_mark.svg"
        ></object>
      </div>
      <div class="card__success" *ngIf="showDisconnect">
        <p class="card__success__text">
          Connect to a different SideKick Device?
        </p>
        <div class="card__success__buttons disconnect">
          <button
            class="btn btn-danger-outline btn-lg"
            (click)="disconnectDevice()"
          >
            Disconnect
          </button>
          <button
            class="card__success__text__send"
            (click)="
              pushSidekickScreen(
                SideKickInterfaceTypeUiEnum.SIDEKICK_SCREEN_TEST
              )
            "
            [class.processing]="!buttonEnabled"
          >
            <img
              class="card__success__text__send__image"
              [src]="
                !buttonEnabled
                  ? '/assets/icons/action-dialog/iconDisabled.svg'
                  : '/assets/icons/action-dialog/sidekickBtn.svg'
              "
              alt=""
            />
            <span>Test</span>
          </button>
        </div>
        <div class="divider"></div>
        <button
          class="card__success__text__send"
          (click)="
            pushSidekickScreen(
              SideKickInterfaceTypeUiEnum.SELF_CHECK_IN_DEFAULT
            )
          "
          [class.processing]="!buttonEnabled"
        >
          <img
            class="card__success__text__send__image"
            [src]="
              !buttonEnabled
                ? '/assets/icons/action-dialog/iconDisabled.svg'
                : '/assets/icons/action-dialog/sidekickBtn.svg'
            "
            alt=""
          />
          <span>Push Self Check-In</span>
        </button>
        <button
          class="card__success__text__send"
          (click)="
            pushSidekickScreen(SideKickInterfaceTypeUiEnum.BOOKING_DEFAULT)
          "
          [class.processing]="!buttonEnabled"
        >
          <img
            class="card__success__text__send__image"
            [src]="
              !buttonEnabled
                ? '/assets/icons/action-dialog/iconDisabled.svg'
                : '/assets/icons/action-dialog/sidekickBtn.svg'
            "
            alt=""
          />
          <span>Push Pay At Hotel</span>
        </button>
      </div>
    </ng-container>
  </div>
</div>
