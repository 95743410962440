<app-loader></app-loader>
<div class="layout__wrapper">
  <div class="sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <main class="main_container">
    <app-page-header [data]="pageHeader"></app-page-header>
    <router-outlet></router-outlet>
    <!-- Guest Search -->
    <button
      class="guest_sidekick_btn"
      [appRelativeClickPosition]="roomNotesInfo"
      [disabled]="!isDeviceLoaded"
    >
      <img
        *ngIf="!isSidekickConnected"
        src="/assets/icons/sidekick__disconnected__state.svg"
        alt="SIDEKICK SETUP"
      />
      <img
        *ngIf="isSidekickConnected"
        src="/assets/icons/sidekick__connected__state.svg"
        alt="SIDEKICK CONNECTED"
      />
    </button>
    <button class="guest_search_btn" (click)="openGuestProfiles()">
      <img src="/assets/icons/g_search.svg" alt="Guest Search" />
    </button>
  </main>
</div>
