import { S3ObjectUiPb } from "./../../s3Object/uiproto/s3ObjectUiProto";
import { TimeUiPb } from "./../../common/uiproto/timeUiProto";
import { PhysicalIdentityTypeUiEnum } from "../../customerShared/uiproto/customerUiProto";
export interface VerificationIdUiPb {
  id?: string;
  unique?: boolean;
  verified?: boolean;
  validFrom?: TimeUiPb;
  validTill?: TimeUiPb;
  issuingAuthority?: string;
  placeOfIssue?: string;
  identity?: PhysicalIdentityTypeUiEnum;
  front?: S3ObjectUiPb;
  back?: S3ObjectUiPb;
  idVerificationStatus?: IdVerificationStatusUiEnum;
}

export enum VerificationTypeUiEnum {
  Passport = "PASSPORT",
  Driving_license = "DRIVING_LICENSE",
  Other = "OTHER",
  Adhaar = "AADHAAR",
  Voter_id = "VOTER_ID",
}

export enum IdVerificationStatusUiEnum {
  NOT_VERIFIED = "NOT_VERIFIED",
  VERIFIED_BY_API = "VERIFIED_BY_API",
  VERIFIED_BY_HUMAN = "VERIFIED_BY_HUMAN",
}
