import { Injectable } from "@angular/core";
import { DbService } from "./db.service";

@Injectable({
  providedIn: "root",
})
export class DbCrudLayerService {
  constructor(private db: DbService) {}

  async add(table: string, data: any) {
    return await this.db.table(table).bulkAdd(data);
  }

  async put(table: string, data: any) {
    return await this.db.table(table).bulkPut(data);
  }

  async get(table: string) {
    return await this.db.table(table).toArray();
  }

  async getByRef(table: string, index: string, ref: string) {
    return await this.db
      .table(table)
      .where(index)
      .anyOfIgnoreCase(ref)
      .toArray();
  }

  async delete() {
    return await this.db.delete();
  }
}
