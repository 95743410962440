export const PMS_INDEX_DB_CONSTANT = {
  NAME: "PMSDB",
  INVENTORY_STORE_CONFIG: {
    STORE_NAME: "inventoryStore",
    INVENTORY_STORE_INDEX: "id",
    PROPERTY_REF_INDEX: "propertyRef.id",
  },
  INHOUSE_GUEST_DETAIL_STORE_INDEX_CONFIG: {
    STORE_NAME: "inHouseGuestDetails",
    INHOUSE_GUEST_DETAIL_STORE_INDEX: "inventoryId",
  },
  PRODUCT_DETAILS_STORE_INDEX_CONFIG: {
    STORE_NAME: "productDetails",
    PRODUCT_DETAILS_STORE_INDEX: "id",
  },
  SELLER_DB_STORE_INDEX_CONFIG: {
    STORE_NAME: "sellerDbConfig",
    SELLER_DB_STORE_INDEX: "id",
  },
  CR_MANAGER_STORE_INDEX_CONFIG: {
    STORE_NAME: "CRManagerStore",
    CR_MANAGER_STORE_INDEX: "id",
  },
  CORPORATE_PLAN_STORE_INDEX_CONFIG: {
    STORE_NAME: "corporatePlanStore",
    CORPORATE_PLAN_STORE_INDEX: "id",
  },
  NON_INDIVIDUALS_STORE_INDEX_CONFIG: {
    STORE_NAME: "CustomerProfilesStore",
    NON_INDIVIDUALS_STORE_INDEX: "id",
  },
};
