import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  DialogLayoutEnum,
  DialogTypeEnum,
} from "src/app/_enums/ui-development/ui-development";
import { BookingAccountUiSimplified } from "src/app/_interfaces/bookingAccountSimplified";
import { LicenseSimplified } from "src/app/_interfaces/licenseSimplifiedVersion";
import { DialogControlService } from "src/app/_services/dialog-control.service";
import { ProfileUiPb } from "src/app/com/staybloom/profile/uiproto/profileUiProto";
import { BookingAccountUiPb } from "src/app/com/staybloom/reservation/uiproto/bookingAccountUiProto";
import { UsageForBookingAccountUiPb } from "src/app/com/staybloom/reservation/uiproto/usageServiceUiProto";
import { CheckInType } from "src/app/com/staybloom/reservation/uiproto/usageUiProto";
import { RoomUiPb } from "src/app/com/staybloom/uiproto/room/roomUiProto";
import { ModuleMap } from "src/app/constants/moduleMapConstant";
import { BookingAccountStoreService } from "src/app/stores/staybloom/bookingAccountStore/bookingAccountStore.service";
import { BookingAccountTransformerService } from "src/app/stores/staybloom/bookingAccountStore/bookingAccountTransformer.service";

export interface dialogTemplateContextSimplified {
  license?: LicenseSimplified;
  bookingAccount?: BookingAccountUiPb;
  usageService?: UsageForBookingAccountUiPb;
  roomDetail?: RoomUiPb;
}

@Component({
  selector: "app-booking-reference-dialog",
  templateUrl: "./booking-reference-dialog.component.html",
  styleUrls: ["./booking-reference-dialog.component.scss"],
})
export class BookingReferenceDialogComponent implements OnInit {
  showModule: boolean = false;
  @ViewChild("container", { read: ViewContainerRef, static: true })
  container: ViewContainerRef;
  @ViewChild("moduleContainer") moduleContainer: ElementRef;

  // templateContext = {};
  simplifiedBookingAccount: BookingAccountUiSimplified;
  lazyModuleConfig: any;
  DialogTypeEnum = DialogTypeEnum;
  DialogLayoutEnum = DialogLayoutEnum;

  contextData: any;

  constructor(
    public dialogRef: MatDialogRef<BookingReferenceDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      license: any;
      loadChildren: string;
      type: DialogTypeEnum;
      layout: DialogLayoutEnum;
      action: any;
      previousDialogRef: any;
      profileUiPb: ProfileUiPb;
      bookingAccount: any;
      checkInType: CheckInType;
      roomDetail: RoomUiPb;
      legendDetails: any;
    },
    private bookingAcountStore: BookingAccountStoreService,
    private bookingAccountTransformer: BookingAccountTransformerService,
    public dialogControlService: DialogControlService
  ) {}

  ngOnInit(): void {
    this.initializeDataFetching();
    this.getModuleWithinSameModal();
  }

  getModuleWithinSameModal() {
    if (
      this.data.layout == DialogLayoutEnum.COLUMNLAYOUT ||
      this.data.layout == DialogLayoutEnum.FULLLAYOUT
    ) {
      this.dialogControlService.getMatDialogRefrenceData().subscribe((data) => {
        if (data) {
          this.showModule = false;
        }
        setTimeout(() => {
          this.loadDynamicComponent();
        }, 0);
      });
    }
  }

  loadDynamicComponent() {
    this.showModule = true;
    if (ModuleMap[this.data?.loadChildren]) {
      if (this.data.roomDetail) {
        this.contextData.roomDetail = this.data.roomDetail;
      }
      ModuleMap[this.data?.loadChildren].data = this.contextData;
    }
    this.lazyModuleConfig = ModuleMap[this.data?.loadChildren];
  }

  // Fetch Booking Account Details

  initializeDataFetching() {
    // console.log(this.data);
    if (this.data.layout == DialogLayoutEnum.FULLLAYOUT) {
      this.contextData = this.data;
      this.loadDynamicComponent();
    } else if (this.data.layout == DialogLayoutEnum.COLUMNLAYOUT) {
      if (this.data.type !== DialogTypeEnum.PROFILETYPE) {
        return this.fetchBookingAccountDetails(this.data.license.bookingId);
      } else {
        this.contextData = {
          action: this.data?.action,
          dialogRef: this.data?.previousDialogRef,
          profileUiPb: this.data?.profileUiPb,
          license: this.data?.license,
          bookingAccount: this.data?.bookingAccount,
          checkInType: this.data?.checkInType,
          roomDetail: this.data?.roomDetail,
        };
        this.loadDynamicComponent();
      }
    } else if (this.data.layout == DialogLayoutEnum.LEGEND) {
    }
  }

  fetchBookingAccountDetails(bookingId: string) {
    this.bookingAcountStore
      .getBookingAccountDetail(bookingId)
      .then(async (result) => {
        this.contextData = {
          license: this.data.license,
          bookingAccount: result,
          roomDetail: this.data?.roomDetail,
        };
        this.loadDynamicComponent();
        this.simplifiedBookingAccount =
          await this.bookingAccountTransformer.transformCurrentBookingToSimplified(
            result
          );
      });
  }
}
