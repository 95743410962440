import { Component, Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarRef } from "@angular/material/snack-bar";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UpdateMessage {
  private messageSource = new BehaviorSubject<Array<string>>([""]);
  currentMessage = this.messageSource.asObservable();
  errorSource = new BehaviorSubject<{ message: string; type: string }>({
    message: "",
    type: "",
  });
  currentError = this.errorSource.asObservable();
  private closeSource = new BehaviorSubject<string>("");
  currentClose = this.closeSource.asObservable();

  newMessage(newMessage: Array<string>) {
    this.messageSource.next(newMessage);
  }

  newError(newError: { message: string; type: string }) {
    this.errorSource.next(newError);
  }

  closeToast(message: string) {
    this.closeSource.next(message);
  }
}

@Injectable({
  providedIn: "root",
})
export class SnackbarService {
  currentSnackBar: MatSnackBarRef<any>;
  snackbarOpen: boolean = false;
  isError: boolean = false;
  constructor(
    private _snack: MatSnackBar,
    private messageService: UpdateMessage
  ) {}

  openSnackBar(message: string, delay?: boolean, type?: string) {
    this.callSnackBar(message, type);
  }

  callSnackBar(message: string, imageScr?: string) {
    this.messageService.newMessage([message, imageScr]);
  }

  closeSnackBar(message: string) {
    this.messageService.closeToast(message);
  }

  openErrorSnackBar(message: string, type: string) {
    console.log(message, type);
    this.messageService.newError({ message: message, type: type });
  }
}
