import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from "@angular/material/core";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import {
  MatMomentDateModule,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";

export const DateFormats = {
  parse: {
    dateInput: ["DD/MMM/YYYY", "DD/MM/YYYY", "DD-MM-YYYY", "DD-MMM-YYYY"],
  },
  display: {
    dateInput: "DD MMM YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

export const ReportsDateFormats = {
  parse: {
    dateInput: ["DD/MMM/YYYY", "DD/MM/YYYY", "DD-MM-YYYY", "DD-MMM-YYYY"],
  },
  display: {
    dateInput: "DD MMM 'YY",
    monthYearLabel: "MMM 'YY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM 'YY",
  },
};

@NgModule({
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMomentDateModule,
  ],
  providers: [
    MatNativeDateModule,
    { provide: MAT_DATE_FORMATS, useValue: DateFormats },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_LOCALE, useValue: "en-IN" },
  ],
})
export class MaterialFormModule {}
