import { TimeRangeUiPb } from "./../../common/uiproto/timeUiProto";
import { PriceUiPb } from "./../../common/uiproto/valueUiProto";
import { EnrichmentSnapshotUiPb } from "./enrichmentUiProto";
import { S3ObjectUiPb } from "./../../s3Object/uiproto/s3ObjectUiProto";
import { AgeUiPb } from "./../../common/uiproto/ageUiProto";
import { AddressUiPb, EmailUiPb } from "./../../uiproto/address/addressUiProto";
import { MobileUiPb } from "./../../common/uiproto/phoneNumberUiProto";
import { VerificationIdUiPb } from "./verificationUiProto";
import {
  UserNameUiPb,
  BookersRepresentativeUiPb,
} from "./../../customerShared/uiproto/customerUiProto";
import { TimeUiPb } from "../../common/uiproto/timeUiProto";
import { SellerDBIdReferenceUiPb } from "./../../sellerDbIdStore/uiproto/sellerDBIdEntityUiProto";
import { CountryUiEnum } from "../../uiproto/address/countryUiProto";
import { GenderUiPbEnum } from "../../common/uiproto/genderUiProto";
import { GSTInfoUiPb } from "../../common/uiproto/gstInfoUiProto";

export interface ProfileUiPb {
  id?: string;
  profileType?: ProfileTypeUi;
  details?: ProfileDetailsUiPb;
  defaultProfileForEmail?: string[];
  sellerDBId?: SellerDBIdReferenceUiPb;
  suspend?: boolean;
  creationTime?: TimeUiPb;
  updationTime?: TimeUiPb;
  crManagerSnapshot?: CRManagerSnapshotUiPb;
  freeText?: string;
  label?: string;
  usageDetail?: UsageDetailUiPb;
  housekeepingFreeText?: string;
  email?: EmailUiPb;

  // ui
  checkInType?: boolean;
}

export enum ProfileTypeUi {
  INDIVIDUAL = "INDIVIDUAL",
  NON_INDIVIDUAL = "NON_INDIVIDUAL",
}

export interface ProfileDetailsUiPb {
  username?: UserNameUiPb;
  alias?: UserNameUiPb[];
  profileCode?: string;
  profileName?: string;
  verificatonIds?: VerificationIdUiPb[];
  emailIds?: ProfileEmailReferenceUiPb[];
  mobilePhoneIds?: MobileUiPb[];
  // TODO : Ask about multiple address
  address?: AddressUiPb;
  facebookId?: string;
  nationality?: CountryUiEnum;
  age?: AgeUiPb;
  giftGroupId?: string;
  loyaltySnapshot?: LoyaltySnapshotUiPb; //not saved in database
  subscriptionStatus?: SubscriptionStatusUiPb;
  billingAddress?: AddressUiPb;
  profilePicture?: S3ObjectUiPb;
  GSTInfo?: GSTInfoUiPb;
  gender?: GenderUiPbEnum;
  attributes?: IndividualProfileAttributesUiPb;
  profileCompletionScore?: string;
  enrichmentSnapshot?: EnrichmentSnapshotUiPb;
  isNoBusinessLogicValidation?: boolean;
}

export interface SubscriptionStatusUiPb {
  emailSubscription?: boolean;
  loyaltyProgramSignup?: boolean; //enables eligiblity for Loyalty promotion
  bookersProgramSignup?: boolean;
  ownersProgramSignup?: boolean; //enables eligiblity for Owners Priviledge Card promotion
  emailBounced?: boolean;
}

export interface IndividualProfileAttributesUiPb {
  profession?: string;
  seniority?: string;
  company?: string;
}

//Deprecated
export interface LoyaltySnapshotUiPb {
  lodgerType?: LodgerTypeUiPbEnum;
  license?: LicenseSnapshotUiPb[];
  totalRoomNightsBooked?: number;
  totalRoomNightsCancelled?: number;
  totalRoomRevenueGenerated?: PriceUiPb;
  totalDateRange?: TimeRangeUiPb;
  adr?: PriceUiPb;
}

export interface LicenseSnapshotUiPb {
  bookingId?: string;
  propertyName?: string;
  roomNightsBooked?: number;
  roomNightsCancelled?: number;
  roomRevenueGenerated?: PriceUiPb;
  dateRange?: TimeRangeUiPb;
  productName?: string;
  //optional com.staybloom.reservation.uiproto.DeliveryStatusUi deliveryStatus = 8;
}

export enum LodgerTypeUiPbEnum {
  EXECUTIVE_LODGER = 1,
  SUPERIOR_LODGER = 2,
  DELUXE_LODGER = 3,
  PREMIUM_LODGER = 4,
  OTHER_LODGER = 5,
}

export interface ProfileEmailReferenceUiPb {
  emailIds?: EmailUiPb;
  username?: UserNameUiPb;
  alias?: UserNameUiPb[];
}

export interface ProfileReferenceUiPb {
  id?: string;
  username?: UserNameUiPb;
  emailIds?: ProfileEmailReferenceUiPb;
  profileCode?: string;
  profileName?: string;
  profileType?: ProfileTypeUi;
  sellerDBId?: SellerDBIdReferenceUiPb;
  suspend?: boolean;
}

export interface ProfileExIdPb {
  emailId?: string;
  facebookId?: string;
}

export interface ProfileExIdListReqPb {
  requests?: ProfileExIdPb[];
}

export interface ProfileExIdListRespPb {
  profiles?: ProfileUiPb[];
}

export interface CRManagerSnapshotUiPb {
  id?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
}

export interface UsageDetailUiPb {
  isSBTUser?: boolean;
  bookersRepresentative?: BookersRepresentativeUiPb[];
  isFreePayAtHotelAllowed?: boolean;
}
