export interface FormDataSimplified {
  sectionName?: string;
  sectionFromGroup?: string;
  sectionFormArray?: string;
  disabled?: boolean;
  fields?: fieldSimplified[];
}

export interface fieldSimplified {
  name?: string;
  type?: FieldTypeEnumSimplified;
  label?: string;
  value?: any;
  required?: boolean;
  download?: boolean;
  width?: FieldWidthEnumSimplified;
  options?: FieldOptionsSimplified[];
  imagePlaceHolder?: string;
  isProfileImage?: boolean;
  optionGroups?: optionsGroup[];
  disabled?: boolean;
  pattern?: string;
  hidden?: boolean;
  suggested?: boolean;
  reference?: string;
  formGroup?: boolean;
  fields?: fieldSimplified[];
}

export interface optionsGroup {
  title: string;
  disabled: false;
  fields: FieldOptionsSimplified[];
}

export enum FieldWidthEnumSimplified {
  FULLWIDTH = "FULLWIDTH",
  HALFWIDTHRIGHT = "HALFWIDTHRIGHT",
  HALFWIDTHLEFT = "HALFWIDTHLEFT",
}

export enum FieldTypeEnumSimplified {
  TEXT = "text",
  NUMBER = "number",
  TEXTAREA = "textarea",
  SELECT = "select",
  CHECKBOX = "checkbox",
  COLOR = "color",
  DATE = "date",
  EMAIL = "email",
  PASSWORD = "password",
  FILE = "file",
  RADIO = "radio",
  RANGE = "range",
  RESET = "reset",
  SEARCH = "search",
  TEL = "tel",
  URL = "url",
  SELECTGROUP = "SELECTGROUP",
  OTHERSELECT = "OTHERSELECT",
  COMMENT = "COMMENT",
  TIME = "TIME",
  COMMENT_INPUT = "COMMENT_INPUT",
  COUNTRY_CODE = "COUNTRY_CODE",
  DATE_RANGE = "DATE_RANGE",
  SLIDE = "SLIDE",
  AUTOCOMPLETE = "AUTOCOMPLETE",
}

export interface FieldOptionsSimplified {
  key: string;
  value: string | boolean | number;
}
