import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import Dexie from "dexie";
import { filter } from "rxjs";
import { PageServiceService } from "src/app/_services/page-service.service";
import { StorageService } from "src/app/_services/storage.service";

@Component({
  selector: "app-page-header",
  templateUrl: "./page-header.component.html",
  styleUrls: ["./page-header.component.scss"],
})
export class PageHeaderComponent {
  @Input("data") data: any;
  @ViewChild("propertyDiv") propertyDiv!: ElementRef;
  @ViewChild("mainContainer") mainContainer!: ElementRef;
  @ViewChild("favlinkContainer") favlinkContainerRef!: ElementRef;
  @ViewChild("scrollContainer") scrollContainer!: ElementRef;
  @ViewChild("rightScrollButton") rightScrollButton!: ElementRef;
  @ViewChild("leftScrollButton") leftScrollButton!: ElementRef;
  selectedTab: string | null = null;
  favLinks: any = [];
  isFavorite: boolean = false;
  changeDetect: boolean = false;
  pageHeaderWidth: number;
  inventoryId: string;
  isPropertyPage: boolean = false;

  constructor(
    private _storageService: StorageService,
    private router: Router,
    private pageService: PageServiceService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.checkIfPropertyPage();
      });
    this.checkIfPropertyPage();
  }

  get getAuthUser() {
    let userEmail = this._storageService.getCookie("authEmail");
    return userEmail.split("@")[0];
  }

  logout() {
    this._storageService.delete_cookie("authEmail");
    this._storageService.delete_cookie("PMS_GWT");
    this._storageService.delete_cookie("auth");
    this._storageService.delete_cookie("user_stamp");
    this.retainFavsInLocalStorge();
    window.location.href = window.origin;
    Dexie.delete("PMSDB");
  }

  ngAfterViewChecked() {
    if (this.data.propertyName && !this.changeDetect) {
      this.pageHeaderWidth =
        document.getElementsByClassName("property")[0].clientWidth;
      this.changeDetect = true;

      const containerWidth =
        this.mainContainer.nativeElement.parentElement.getBoundingClientRect()
          .width;
      const favlinkWidth = containerWidth - (this.pageHeaderWidth + 200);
      if (this.favlinkContainerRef) {
        this.favlinkContainerRef.nativeElement.style.maxWidth = `${favlinkWidth}px`;
      }
    }
  }

  ngAfterViewInit() {
    this.getInventoryId();
    this.getFavs();

    setTimeout(() => {
      this.checkIfFavorite();
      this.updateScrollButtonOpacity();
    }, 200);
  }

  getInventoryId() {
    this.pageService.getCurrentHotelInventoryId().subscribe((id) => {
      this.inventoryId = id;
    });
  }

  storePageTitle() {
    const pageTitle = this.data.pageTitle;
    let currentUrl = this.router.url.replace(/\/\d+.*$/, "");
    const favLink = {
      pagetitle: pageTitle,
      url: currentUrl,
    };

    let favLinks = JSON.parse(localStorage.getItem("favLinks") || "[]");
    if (!favLinks.some((fav: any) => fav.url === currentUrl)) {
      favLinks.push(favLink);
      localStorage.setItem("favLinks", JSON.stringify(favLinks));
    }
    this.isFavorite = true;
    this.getFavs();
    this.checkIfFavorite();
    this.selectTab(favLink);
    setTimeout(() => {
      this.updateScrollButtonOpacity();
    }, 600);
  }

  removeFromShorcut() {
    const currentUrl = this.router.url.replace(/\/\d+.*$/, "");
    let favorites = JSON.parse(localStorage.getItem("favLinks") || "[]");
    favorites = favorites.filter((fav: any) => fav.url !== currentUrl);
    localStorage.setItem("favLinks", JSON.stringify(favorites));
    this.isFavorite = false;
    this.getFavs();
    this.checkIfFavorite();
    if (favorites.length === 0) {
      localStorage.removeItem("selectedTab");
      this.selectedTab = null;
    }
    setTimeout(() => {
      this.updateScrollButtonOpacity();
    }, 600);
  }

  redirectOnFavLink(link: string) {
    let currentUrl = link;
    currentUrl = currentUrl + "/" + this.inventoryId;
    this.router.navigateByUrl(currentUrl);
    this.checkIfFavorite();
  }

  getFavs() {
    this.favLinks = JSON.parse(localStorage.getItem("favLinks"));
  }

  checkIfFavorite() {
    const currentUrl = this.router.url.replace(/\/\d+.*$/, "");
    const favorites = JSON.parse(localStorage.getItem("favLinks") || "[]");
    this.isFavorite = favorites.some((fav: any) => {
      return fav.url === currentUrl;
    });
  }

  scrollToLeft() {
    const container = this.scrollContainer.nativeElement;
    const containerWidth = container.clientWidth - 100;

    container.scrollBy({
      left: containerWidth,
      behavior: "smooth",
    });
    setTimeout(() => {
      this.updateScrollButtonOpacity();
    }, 600);
  }

  scrollToRight() {
    const container = this.scrollContainer.nativeElement;
    const containerWidth = container.clientWidth - 100;

    container.scrollBy({
      left: -containerWidth,
      behavior: "smooth",
    });
    setTimeout(() => {
      this.updateScrollButtonOpacity();
    }, 600);
  }

  updateScrollButtonOpacity() {
    if (
      this.scrollContainer &&
      this.leftScrollButton &&
      this.rightScrollButton
    ) {
      const container = this.scrollContainer.nativeElement;
      const leftButton = this.leftScrollButton.nativeElement;
      const rightButton = this.rightScrollButton.nativeElement;

      const buffer = 5;

      if (container.scrollLeft <= buffer) {
        leftButton.style.opacity = "0.5";
        leftButton.style.cursor = "default";
      } else {
        leftButton.style.opacity = "1";
        leftButton.style.cursor = "pointer";
      }

      if (
        container.scrollLeft + container.clientWidth >=
        container.scrollWidth - buffer
      ) {
        rightButton.style.opacity = "0.5";
        rightButton.style.cursor = "default";
      } else {
        rightButton.style.opacity = "1";
        rightButton.style.cursor = "pointer";
      }
    }
  }

  selectTab(fav: any) {
    this.selectedTab = fav.url;
    // localStorage.setItem("selectedTab", this.selectedTab);
  }

  isSelected(fav: any): boolean {
    return this.router.url.includes(fav.url);
  }

  checkIfPropertyPage() {
    const currentUrl = window.location.pathname;
    if (
      currentUrl.includes("/unit/properties") ||
      currentUrl.includes("/unit/action/reservation")
    ) {
      this.isPropertyPage = true;
      localStorage.removeItem("selectedTab");
      this.selectedTab = null;
    } else {
      this.isPropertyPage = false;
      this.checkIfFavorite();
    }
  }

  retainFavsInLocalStorge() {
    const allKeys = Object.keys(localStorage);
    allKeys.forEach((key) => {
      if (key !== "favLinks") {
        localStorage.removeItem(key);
      }
    });
  }
}
