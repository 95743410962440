import { Component } from "@angular/core";

@Component({
  selector: "app-full-page-layout",
  templateUrl: "./full-page-layout.component.html",
  styleUrls: ["./full-page-layout.component.scss"],
})
export class FullPageLayoutComponent {
  constructor() {}
  ngOnInit() {}
}
