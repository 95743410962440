import { Component, Inject, Injectable } from "@angular/core";
import { BookingReferenceDialogComponent } from "../_components/booking-reference-dialog/booking-reference-dialog.component";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DialogControlService {
  matDialogRef: MatDialogRef<BookingReferenceDialogComponent, any>;
  matDialogRefrenceDataUpdate = new BehaviorSubject({});
  matdialogRefsList: MatDialogRef<any>[] = [];
  dataList: any = [];
  constructor(private MatDialog: MatDialog) {}

  openDialog(
    data: any,
    modalOpen: boolean = true,
    multi: boolean = true,
    close: boolean = false
  ) {
    if (multi) {
      if (!close) {
        if (modalOpen) {
          this.dataList.push(data);
        }
      }
    }
    let lastIndex = this.dataList.length - 1;
    let matConfig = this.setMatDialogConfig(this.dataList[lastIndex]); //configuration
    if (modalOpen) {
      this.matDialogRef?.close(true);
      this.matDialogRef = this.MatDialog.open(
        BookingReferenceDialogComponent,
        matConfig
      );
    } else {
      this.dataList[lastIndex].loadChildren = data.loadChildren;
      this.matDialogRef.componentInstance.data = data;
      this.setMatDialogRefrenceData(data);
    }
    if (modalOpen) {
      this.matDialogRef.afterClosed().subscribe((item) => {
        if (item == undefined && multi) {
          this.dataList.pop(data);
          let lastIndex = this.dataList.length - 1;
          console.log(this.dataList);

          if (this.dataList.length) {
            this.openDialog(this.dataList[lastIndex], modalOpen, multi, true);
          } else {
            this.matDialogRef.close(false);
          }
        }
        this.setMatDialogRefrenceData(this.dataList[lastIndex]);
      });
    }
  }

  setMatDialogConfig(data: any) {
    let dialogConfig = new MatDialogConfig();
    (dialogConfig.width = "90%"),
      (dialogConfig.maxWidth = "1240px"),
      (dialogConfig.height = "90%"),
      (dialogConfig.maxHeight = "700px"),
      (dialogConfig.panelClass = "booking-reference-modal");
    dialogConfig.data = data;
    return dialogConfig;
  }
  setMatDialogRefrenceData(value: any) {
    return this.matDialogRefrenceDataUpdate.next(value);
  }

  getMatDialogRefrenceData() {
    return this.matDialogRefrenceDataUpdate.asObservable();
  }

  closeMatDialog() {
    this.matDialogRef.close();
  }
  closeAllMatDialog() {
    this.dataList = [];
    this.matDialogRef.close();
  }
  openImage(data: any) {
    let matConfig = this.setMatDialogConfig(data); //configuration

    this.MatDialog.open(DialogExpandImageComponent, matConfig);
  }
}

@Component({
  selector: "app-dialog-expand-image",
  template: `
    <div class="overlay-container">
      <img class="overlay-container__imageSize" src="{{ data.imageSrc }}" />
    </div>
  `,
  styles: [
    `
      .overlay-container {
        border: 2px solid #ffcc09;
        height: 100%;
        padding: 20px;
        border-radius: 24px;
        &__imageSize {
          border-radius: 18px;
          height: 650px;
          width: auto;
          margin: 0 auto;
        }
      }
    `,
  ],
})
export class DialogExpandImageComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogExpandImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeImage() {
    this.dialogRef.close();
  }
}
