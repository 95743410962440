import {
  FieldTypeEnumSimplified,
  FieldWidthEnumSimplified,
  FormDataSimplified,
} from "../_interfaces/formDataSimplified";

export const sidekickSetupForm: FormDataSimplified[] = [
  {
    sectionName: "",
    fields: [
      {
        name: "deviceName",
        type: FieldTypeEnumSimplified.SELECT,
        width: FieldWidthEnumSimplified.FULLWIDTH,
        label: "Device Name",
        options: [],
        value: "",
        required: true,
      },
      {
        name: "password",
        type: FieldTypeEnumSimplified.TEXT,
        width: FieldWidthEnumSimplified.FULLWIDTH,
        label: "Password",
        value: "",
        required: true,
      },
    ],
  },
];

export const roomExtraNotesInfo: FormDataSimplified[] = [
  {
    sectionName: "",
    fields: [
      {
        name: "expectedArrival",
        type: FieldTypeEnumSimplified.DATE,
        width: FieldWidthEnumSimplified.HALFWIDTHLEFT,
        label: "Arrival",
        value: "",
        required: false,
      },
      {
        name: "arrivalTime",
        type: FieldTypeEnumSimplified.TIME,
        width: FieldWidthEnumSimplified.HALFWIDTHRIGHT,
        label: "Time",
        value: "",
        required: false,
      },
      {
        name: "expectedDeparture",
        type: FieldTypeEnumSimplified.DATE,
        width: FieldWidthEnumSimplified.HALFWIDTHLEFT,
        label: "Departure",
        value: "",
        required: false,
      },
      {
        name: "departureTime",
        type: FieldTypeEnumSimplified.TIME,
        width: FieldWidthEnumSimplified.HALFWIDTHRIGHT,
        label: "Time",
        value: "",
        required: false,
      },

      {
        name: "person",
        type: FieldTypeEnumSimplified.NUMBER,
        width: FieldWidthEnumSimplified.FULLWIDTH,
        label: "Persons",
        value: "",
        required: false,
      },

      {
        name: "children",
        type: FieldTypeEnumSimplified.NUMBER,
        width: FieldWidthEnumSimplified.HALFWIDTHLEFT,
        label: "Children",
        value: "",
        required: false,
      },
      {
        name: "infants",
        type: FieldTypeEnumSimplified.NUMBER,
        width: FieldWidthEnumSimplified.HALFWIDTHRIGHT,
        label: "Infants",
        value: "",
        required: false,
      },
      {
        name: "notes",
        type: FieldTypeEnumSimplified.TEXTAREA,
        width: FieldWidthEnumSimplified.FULLWIDTH,
        label: "Notes",
        value: "",
        required: false,
      },
    ],
  },
];

export const conferenceRoomExtraInfo: FormDataSimplified[] = [
  {
    sectionName: "",
    fields: [
      {
        name: "bookingDate",
        type: FieldTypeEnumSimplified.DATE,
        width: FieldWidthEnumSimplified.HALFWIDTHLEFT,
        label: "Date",
        value: "",
        required: true,
      },
      {
        name: "bookingTime",
        type: FieldTypeEnumSimplified.TIME,
        width: FieldWidthEnumSimplified.HALFWIDTHRIGHT,
        label: "Time",
        value: "",
        required: true,
      },
      {
        name: "hours",
        type: FieldTypeEnumSimplified.NUMBER,
        width: FieldWidthEnumSimplified.HALFWIDTHLEFT,
        label: "Hours",
        value: "",
        required: true,
      },
      {
        name: "pax",
        type: FieldTypeEnumSimplified.NUMBER,
        width: FieldWidthEnumSimplified.HALFWIDTHRIGHT,
        label: "Pax",
        value: "",
        required: true,
      },
      {
        name: "notes",
        type: FieldTypeEnumSimplified.TEXT,
        width: FieldWidthEnumSimplified.FULLWIDTH,
        label: "Notes",
        value: "",
        required: false,
      },
    ],
  },
];

export const onlyNotesExtraInfo: FormDataSimplified[] = [
  {
    sectionName: "",
    fields: [
      {
        name: "notes",
        type: FieldTypeEnumSimplified.TEXT,
        width: FieldWidthEnumSimplified.FULLWIDTH,
        label: "Notes",
        value: "",
        required: false,
      },
    ],
  },
];

export const airportPickupDropExtraInfo: FormDataSimplified[] = [
  {
    sectionName: "",
    fields: [
      {
        name: "airline",
        type: FieldTypeEnumSimplified.TEXT,
        width: FieldWidthEnumSimplified.FULLWIDTH,
        label: "Airline",
        value: "",
        required: true,
      },
      {
        name: "flightNum",
        type: FieldTypeEnumSimplified.TEXT,
        width: FieldWidthEnumSimplified.FULLWIDTH,
        label: "Flight No.",
        value: "",
        required: true,
      },
      {
        name: "bookingDate",
        type: FieldTypeEnumSimplified.DATE,
        width: FieldWidthEnumSimplified.HALFWIDTHLEFT,
        label: "Date",
        value: "",
        required: true,
      },
      {
        name: "bookingTime",
        type: FieldTypeEnumSimplified.TIME,
        width: FieldWidthEnumSimplified.HALFWIDTHRIGHT,
        label: "Time",
        value: "",
        required: true,
      },
      {
        name: "notes",
        type: FieldTypeEnumSimplified.TEXT,
        width: FieldWidthEnumSimplified.FULLWIDTH,
        label: "Notes",
        value: "",
        required: true,
      },
    ],
  },
];

export const extraTimeChargeInfoNotes: FormDataSimplified[] = [
  {
    sectionName: "",
    fields: [
      {
        name: "bookingDate",
        type: FieldTypeEnumSimplified.DATE,
        width: FieldWidthEnumSimplified.HALFWIDTHLEFT,
        label: "Date",
        value: "",
        required: true,
      },
      {
        name: "bookingTime",
        type: FieldTypeEnumSimplified.TIME,
        width: FieldWidthEnumSimplified.HALFWIDTHRIGHT,
        label: "Time",
        value: "",
        required: true,
      },
      {
        name: "roomNo",
        type: FieldTypeEnumSimplified.TEXT,
        width: FieldWidthEnumSimplified.FULLWIDTH,
        label: "Room No.",
        value: "",
        required: true,
      },
      {
        name: "notes",
        type: FieldTypeEnumSimplified.TEXT,
        width: FieldWidthEnumSimplified.FULLWIDTH,
        label: "Notes",
        value: "",
        required: true,
      },
    ],
  },
];
