export enum Action {
  ACTIONS = "ACTIONS",
  PAYMENT = "PAYMENT",
  ASSIGN_ROOM = "ASSIGN_ROOM",
  SEARCH = "SEARCH",
  MOREACTIONS = "MOREACTIONS",
  PROFILE = "PROFILE",
  GUESTCHECKIN = "GUESTCHECKIN",
  SEARCHPROFILE = "SEARCHPROFILE",
  ADD_ON = "ADD_ON",
  NOSHOW = "NOSHOW",
  ROOM_CHECKOUT = "ROOM_CHECKOUT",
  WEB_CHECKIN = "WEB_CHECKIN",
  SEND_PAYMENT_LINK_KEY = "SEND_PAYMENT_LINK_KEY",
  UPGRADE_ROOM = "UPGRADE_ROOM",
  BILLS_INVOICE = "BILLS_INVOICE",
  MULTI_ASSIGN_ROOMS_WIDGET = "MULTI_ASSIGN_ROOMS_WIDGET",
  UN_ASSIGNED_STAYS_WIDGET = "UN_ASSIGNED_STAYS_WIDGET",
  MODIFY_STAY_DATE = "MODIFY_STAY_DATE",
  ROOM_SELECTION_CARD = "ROOM_SELECTION_CARD",
  RESERVATION = "RESERVATION",
}

export enum ActionModalEnum {
  webCheckIn = "web-checkin",
  noShow = "no-show",
  roomAssignment = "assign-room",
  upgrade = "upgrade-room",
  checkIn = "checkin",
  addOns = "add-on",
  payments = "payment",
  checkOut = "checkout",
  reservation = "reservation",
  billAndInvoices = "bills-and-invoices",
  selectRoom = "select-room",
}

export enum DialogTypeEnum {
  PROFILETYPE = "PROFILETYPE",
  BOOKINGTYPE = "BOOKINGTYPE",
}

export enum DialogLayoutEnum {
  FULLLAYOUT = "FULLLAYOUT",
  COLUMNLAYOUT = "COLUMNLAYOUT",
  LEGEND = "LEGEND",
}

// Profile Modal Display Actions

export enum formTypeActionEnum {
  VIEW = "VIEW",
  EDIT = "EDIT",
  UPDATE = "UPDATE",
  CREATE = "CREATE",
  CREATEANDCHECKIN = "CREATEANDCHECKIN",
  VIEWANDCHECKIN = "VIEWANDCHECKIN",
  EDITANDCHECKIN = "EDITANDCHECKIN",
  UPDATEANDCHECKIN = "UPDATECHECKIN",
  UPDATEADDCHECKIN = "UPDATEADDCHECKIN",
  UPDATEADDSWITCHCHECKIN = "UPDATEADDSWITCHCHECKIN",
}

export enum RegCardCountTypeEnum {
  INCREMENT = "INCREMENT",
  DECREMENT = "DECREMENT",
}
