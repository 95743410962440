import {
  Directive,
  ElementRef,
  HostListener,
  ComponentRef,
  Renderer2,
  SimpleChanges,
  Input,
  ViewContainerRef,
} from "@angular/core";
import { UpdateWidgetComponent } from "./_components/update-widget/update-widget.component";
import { EventShareService } from "./_services/event-share.service";

@Directive({
  selector: "[appRelativeClickPosition]",
})
export class RelativeClickPositionDirective {
  private componentRef: ComponentRef<UpdateWidgetComponent>;

  @Input() appRelativeClickPosition!: any;
  heightEle: number;

  constructor(
    private elementRef: ElementRef,
    private rootViewContainer: ViewContainerRef,
    private renderer: Renderer2,
    private eventShareService: EventShareService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes["appRelativeClickPosition"].currentValue) {
      return;
    }
    this.renderer.addClass(this.elementRef.nativeElement, "widget-click");
  }

  @HostListener("click", ["$event"])
  onClickInside(event: any): void {
    event.stopPropagation();
    this.componentRef?.destroy();
    this.removeDynamicComponent();
    if (event.target.classList.contains("widget-click")) {
      //select positioning of the dynamic component
      const clickedRect = event.target.getBoundingClientRect();
      const { scrollLeft, scrollTop }: any =
        document.documentElement || document.body.parentNode || document.body;
      const x = clickedRect.left + scrollLeft - 280;
      const y = clickedRect.top + scrollTop + 20;
      this.componentRef = this.rootViewContainer.createComponent(
        UpdateWidgetComponent
      );
      let wrapper = document.querySelectorAll(".cdk-global-overlay-wrapper");
      let lastWrapper = wrapper[wrapper.length - 1];
      if (lastWrapper) {
        lastWrapper
          .querySelector(".cdk-overlay-pane")
          .prepend(this.componentRef.location.nativeElement);
      } else {
        document.body.appendChild(this.componentRef.location.nativeElement);
      }
      this.renderer.addClass(
        this.componentRef.location.nativeElement,
        "my-dynamic-class"
      );
      let dynamicElement = document.querySelector(".my-dynamic-class");
      this.renderer.setStyle(dynamicElement, "position", "absolute");
      if (lastWrapper) {
        if (dynamicElement) {
          this.renderer.setStyle(dynamicElement, "z-index", "1001");
        }
      } else {
        this.renderer.removeStyle(dynamicElement, "z-index");
      }
      this.componentRef.instance.widgetInfo = this.appRelativeClickPosition;
      this.componentRef.instance.closeModal.subscribe((res) => {
        this.componentRef.destroy();
        this.removeDynamicComponent();
      });
      this.componentRef.changeDetectorRef.detectChanges();
      this.componentRef.instance.output.subscribe((res) => {
        let data = res;
        let componentRef = this.componentRef;
        this.eventShareService.setWidgetResponseData({ data, componentRef });
      });
      let posY: number = y;
      let posX: number = x;
      let leftPos: number =
        this.elementRef.nativeElement.getBoundingClientRect().left;
      if (!this.heightEle) {
        this.heightEle =
          document.querySelector<HTMLElement>(".my-dynamic-class").offsetHeight;
      }
      if (
        y + this.heightEle > window.screen.height + window.scrollY &&
        posY > this.heightEle
      ) {
        posY = y - this.heightEle;
      }
      if (leftPos < 339) {
        posX = x + leftPos;
      }
      this.componentRef.instance.positionRelativeTo(posX, posY);
    } else {
      if (this.componentRef) {
        this.componentRef.destroy();
        this.removeDynamicComponent();
      }
    }
  }

  removeDynamicComponent() {
    let div: HTMLCollectionOf<Element> =
      document.getElementsByClassName("my-dynamic-class");
    for (let i = 0; i < div.length; i++) {
      div[i].remove();
    }
  }
}
