import { OverlayContainer } from "@angular/cdk/overlay";
import { Component, OnChanges, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment";
import { Subscription } from "rxjs";
import { UniversalModalComponent } from "src/app/_components/universal-modal/universal-modal.component";
import { Action } from "src/app/_enums/ui-development/ui-development";
import { HeaderData } from "src/app/_interfaces/propertyDetail";
import { SideKickCheckConnectionRespUiPb } from "src/app/_interfaces/sidekickTransaction";
import { ApiService } from "src/app/_services/api.service";
import { AuthService } from "src/app/_services/auth.service";
import { DbCrudLayerService } from "src/app/_services/dbCrudLayer.service";
import { EventShareService } from "src/app/_services/event-share.service";
import { PageServiceService } from "src/app/_services/page-service.service";
import { StorageService } from "src/app/_services/storage.service";
import { ProfileTypeUi } from "src/app/com/staybloom/profile/uiproto/profileUiProto";
import {
  ListProfileIndexRequestUiPb,
  ListProfileIndexResponseUiPb,
} from "src/app/com/staybloom/profileIndex/uiproto/profileIndexQueryUiProto";
import { ListPlansResponseUiPb } from "src/app/com/staybloom/uiproto/plan/planIndexUiProto";
import { PlanUiPb } from "src/app/com/staybloom/uiproto/plan/planUiProto";
import { PMS_INDEX_DB_CONSTANT } from "src/app/constants/indexDBConstant";
import { WIDGET_NAMES_CONSTANT } from "src/app/constants/widgetNameConstant";
import { sidekickSetupForm } from "src/app/models/EditInfo";
import { InventoryStoreStoreService } from "src/app/stores/staybloom/inventoryStoreStore/inventoryStoreStore.service";
import { ProfileStoreService } from "src/app/stores/staybloom/profileStore/profile-store.service";
import { PropertyStoreService } from "src/app/stores/staybloom/propertyStore/propertyStore.service";

@Component({
  selector: "app-sidebar-layout",
  templateUrl: "./sidebar-layout.component.html",
  styleUrls: ["./sidebar-layout.component.scss"],
})
export class SidebarLayoutComponent implements OnInit, OnChanges {
  currentHotelInventoryId: string;
  isFirstTime: boolean = true;

  pageHeader: HeaderData = {
    // pageTitle: "Guest Movement",
    pageImage: "/assets/images/header_image.svg",
    propertyName: "",
    propertyImage: "",
  };

  roomNotesInfo: any;
  isDeviceLoaded: boolean = false;
  deviceId: string = "";
  isSidekickConnected: boolean = false;
  sidekickData: any = "";
  subscriptions: Subscription[] = [];

  constructor(
    private overlayContainer: OverlayContainer,
    private AuthService: AuthService,
    private pageService: PageServiceService,
    private inventoryStore: InventoryStoreStoreService,
    private MatDialog: MatDialog,
    private propertyStoreService: PropertyStoreService,
    private storageService: StorageService,
    private dbLayer: DbCrudLayerService,
    private profileStoreService: ProfileStoreService,
    private _eventShareService: EventShareService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    if (localStorage.getItem("sidekickDevice")) {
      this.isSidekickConnected = true;
    }

    this.AuthService.isAuthenticated();

    this.getPropertyHeader();
    this.getWidgetResponse();

    let deviceConnectionStatusSubscription: Subscription =
      this._eventShareService.getWidgetConnected().subscribe({
        next: (data: any) => {
          if (data?.isConnected == false) {
            this.isSidekickConnected = false;
          }
        },
        error: (err: any) => {},
      });

    this.subscriptions.push(deviceConnectionStatusSubscription);

    // this.getAndSetPageId();
  }

  ngOnChanges() {}

  ngAfterViewInit() {
    this.pageService.getCurrentHotelInventoryId().subscribe((data) => {
      this.currentHotelInventoryId = data;

      this.roomNotesInfo = {
        title: "SIDEKICK SETUP",
        description:
          "Connect your device to Front Desk Assistant by entering the details below.",
        form: sidekickSetupForm,
        widgetName: WIDGET_NAMES_CONSTANT.SIDEKICK_SETUP,
        widgetPlace: "MOVEMENT_SCREEN_BOOKING_CARD",
        reference: "",
      };

      this.getSidekickDevices();

      if (this.isFirstTime && data) {
        this.getUserAccessForProperty();
        this.getSellerDbData(this.currentHotelInventoryId);
      }
    });
  }

  getSidekickDevices() {
    this.apiService
      .getSidekickDevices({
        inventoryStoreId: this.currentHotelInventoryId.toString(),
      })
      .subscribe({
        next: (response: any) => {
          let deviceField = this.roomNotesInfo.form[0].fields.find(
            (field: any) => field.name === "deviceName"
          );
          deviceField.options = [];
          response?.sideKick.forEach((element: any) => {
            if (element.skdFunctionType == "FRONT_DESK_ASSISTANT") {
              deviceField.options.push({
                key: element?.skdName + " ( " + element?.id + " )",
                value: element?.id,
              });
            }
          });
          this.isDeviceLoaded = true;
        },
        error: (err: any) => {
          this.isDeviceLoaded = true;
          throw new Error("error: " + err, { cause: true });
        },
      });
  }

  getWidgetResponse() {
    this._eventShareService
      .getWidgetResponseData()
      .subscribe((widgetResponse: any) => {
        if (widgetResponse?.data?.data?.value) {
          localStorage.removeItem("sidekickDevice");
          let deviceData = widgetResponse?.data?.data?.value;
          let loginPayload = {
            id: deviceData?.deviceName,
            password: deviceData?.password,
          };
          let loginDeviceSubscription: Subscription = this.apiService
            .loginDevice(loginPayload)
            .subscribe({
              next: (response: any) => {
                this.sidekickData = response;
                this.deviceId = deviceData?.deviceName;
                this._eventShareService.setWidgetSuccessData({
                  isLoggedIn: true,
                  sideKickData: response,
                });
              },
              error: (err: any) => {
                this.deviceId = "";
                this._eventShareService.setWidgetSuccessData({
                  isLoggedIn: false,
                });
              },
            });

          let deviceConnectionSubscription: Subscription =
            this._eventShareService.getWidgetConnectionData().subscribe({
              next: (data: any) => {
                if (data && this.deviceId) {
                  this.checkWidgetConnectionResponse();
                } else {
                  this._eventShareService.setWidgetConnected(false);
                }
              },
              error: (err: any) => {
                this.deviceId = "";
              },
            });

          this.subscriptions.push(loginDeviceSubscription);
          this.subscriptions.push(deviceConnectionSubscription);
        }
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscriptionItem) => {
      subscriptionItem.unsubscribe();
    });
  }

  checkWidgetConnectionResponse() {
    let checkDeviceConnectionSubscription: Subscription = this.apiService
      .checkDeviceConnection(this.deviceId)
      .subscribe({
        next: (response: SideKickCheckConnectionRespUiPb) => {
          if (response.deviceReachable) {
            localStorage.setItem(
              "sidekickDevice",
              JSON.stringify(this.sidekickData)
            );
            this.isSidekickConnected = true;
            this._eventShareService.setWidgetConnected({
              isConnected: true,
            });
          } else {
            this.deviceId = "";
            this._eventShareService.setWidgetConnected({
              isConnected: false,
            });
          }
          checkDeviceConnectionSubscription.unsubscribe();
        },
        error: (err: any) => {
          this.deviceId = "";
          this._eventShareService.setWidgetConnected({
            isConnected: false,
          });
          checkDeviceConnectionSubscription.unsubscribe();
        },
      });
  }

  async getSellerDbData(inventoryId: string) {
    const data = await this.dbLayer.get(
      PMS_INDEX_DB_CONSTANT.INVENTORY_STORE_CONFIG.STORE_NAME
    );

    const currentProperty = data.find((data) => data.id === inventoryId);

    await this.getSellerDBDetail(currentProperty?.sellerDBId?.id);

    await this.getCRManagers(currentProperty?.sellerDBId?.id);

    await this.getNonIndividualProfiles(currentProperty?.sellerDBId?.id);

    await this.getCorporatePlanDetails(inventoryId);
  }

  async getSellerDBDetail(id: string) {
    return new Promise((resolve, reject) => {
      this.dbLayer
        .get(PMS_INDEX_DB_CONSTANT.SELLER_DB_STORE_INDEX_CONFIG.STORE_NAME)
        .then((data) => {
          const product: any = data[0];
          if (!product) {
            this.inventoryStore.SellerDBData(id).subscribe({
              next: (response: any) => {
                this.dbLayer.put(
                  PMS_INDEX_DB_CONSTANT.SELLER_DB_STORE_INDEX_CONFIG.STORE_NAME,
                  [response]
                );
                resolve(response); // Resolve the promise when the operation is complete
              },
              error: (error) => {
                reject(new Error("Error while fetching product detail")); // Reject the promise in case of an error
              },
            });
          } else {
            resolve(product); // Resolve the promise if the product is found in the indexDB
          }
        });
    });
  }

  async getCRManagers(id: string) {
    return new Promise((resolve, reject) => {
      this.dbLayer
        .get(PMS_INDEX_DB_CONSTANT.CR_MANAGER_STORE_INDEX_CONFIG.STORE_NAME)
        .then((data) => {
          const product: any = data;
          if (!product || (product && product?.length === 0)) {
            this.inventoryStore.getCRManagers(id).subscribe({
              next: (response: any) => {
                this.dbLayer.put(
                  PMS_INDEX_DB_CONSTANT.CR_MANAGER_STORE_INDEX_CONFIG
                    .STORE_NAME,
                  response.crManagers
                );
                resolve(response.crManagers); // Resolve the promise when the operation is complete
              },
              error: (error) => {
                reject(new Error("Error while fetching cr manager detail")); // Reject the promise in case of an error
              },
            });
          } else {
            resolve(product); // Resolve the promise if the product is found in the indexDB
          }
        });
    });
  }

  async getCorporatePlanDetails(id: string) {
    return new Promise((resolve, reject) => {
      this.dbLayer
        .get(PMS_INDEX_DB_CONSTANT.CORPORATE_PLAN_STORE_INDEX_CONFIG.STORE_NAME)
        .then((data) => {
          const product: PlanUiPb[] = data;
          if (!product || (product && product?.length === 0)) {
            this.inventoryStore.getPlanDetails(id).subscribe({
              next: (response: ListPlansResponseUiPb) => {
                this.dbLayer.put(
                  PMS_INDEX_DB_CONSTANT.CORPORATE_PLAN_STORE_INDEX_CONFIG
                    .STORE_NAME,
                  response.plans
                );
                resolve(response.plans); // Resolve the promise when the operation is complete
              },
              error: (error) => {
                reject(new Error("Error while fetching plan detail")); // Reject the promise in case of an error
              },
            });
          } else {
            resolve(product); // Resolve the promise if the product is found in the indexDB
          }
        });
    });
  }

  async getNonIndividualProfiles(sellerDBID: any) {
    return new Promise((resolve, reject) => {
      this.dbLayer
        .get(
          PMS_INDEX_DB_CONSTANT.NON_INDIVIDUALS_STORE_INDEX_CONFIG.STORE_NAME
        )
        .then((data) => {
          // console.log(data);
          if (!data || (data && data?.length === 0)) {
            const queryParams: ListProfileIndexRequestUiPb = {
              profileType: ProfileTypeUi.NON_INDIVIDUAL,
              publicProfile: true,
              sellerDBId: sellerDBID,
              doQueryValidation: false,
              updationTimeRange: {
                timeStart: {
                  formattedDate: moment()
                    .subtract(1, "years")
                    .format("YYYYMMDD"),
                },
                timeEnd: { formattedDate: moment().format("YYYYMMDD") },
              },
              isCorporateCodeSearch: true,
            };

            this.profileStoreService.getProfiles(queryParams).subscribe({
              next: (response: ListProfileIndexResponseUiPb) => {
                console.log(response);
                this.dbLayer.put(
                  PMS_INDEX_DB_CONSTANT.NON_INDIVIDUALS_STORE_INDEX_CONFIG
                    .STORE_NAME,
                  response.profile
                );
                resolve(response.profile); // Resolve the promise when the operation is complete
              },
              error: (error) => {
                reject(new Error("Error while fetching Customer Profiles")); // Reject the promise in case of an error
              },
            });
          } else {
            // console.log(data);

            resolve(data); // Resolve the promise if the product is found in the indexDB
          }
        });
    });
  }

  getUserAccessForProperty() {
    this.isFirstTime = false;
    const email = this.storageService.getCookie("authEmail");
    let data: any = {
      user: email,
      app: { appId: "PMS_GWT", topLevelEntityId: this.currentHotelInventoryId },
    };
    data = encodeURIComponent(JSON.stringify(data));
    this.propertyStoreService.getPropertyAccessForUser(data).subscribe({
      next: (response) => {
        sessionStorage.setItem("access", JSON.stringify(response));
      },
      error: (error) => {
        throw new Error("Error while fetching user access!", { cause: true });
      },
    });
  }

  getPropertyHeader() {
    this.inventoryStore.getInventoryStoreData().then((data) => {
      const inventory = data?.filter(
        (val) => val.id === this.currentHotelInventoryId
      )[0];

      // Setting the Property Data to filtered result and updating the header data object in order to create Page Header
      this.pageHeader.propertyName = inventory?.propertyRef?.fullName;
      this.pageHeader.propertyImage = inventory?.propertyRef?.imageUrl;
    });

    this.pageService.getPageHeader().subscribe((data) => {
      this.pageHeader.pageTitle = data;
    });
  }

  changeBackdropColor(): void {
    const overlayContainerElement = this.overlayContainer.getContainerElement();
    overlayContainerElement.classList.add("universal-modal-backdrop");
  }

  openGuestProfiles() {
    return (
      this.MatDialog.open(UniversalModalComponent, {
        width: "100%",
        maxWidth: "1240px",
        height: "80%",
        maxHeight: "700px",
        panelClass: "universal-modal",
        data: {
          loadChildren: Action.SEARCH,
        },
      }) && this.changeBackdropColor()
    );
  }
}
