import { Moment } from "moment";
import { GSTInfoUiPb } from "../com/staybloom/common/uiproto/gstInfoUiProto";

import { CurrencyUiEnum } from "../com/staybloom/common/uiproto/currencyUiProto";
import { CancellationPolicyEnum } from "../com/staybloom/price/uiproto/cancellationPolicyUiProto";
import {
  ModeOfPaymentUiEnum,
  PaymentStateUiEnum,
} from "../com/staybloom/reservation/uiproto/billingAccountUiProto";
import { BookingAccountStateUiPb } from "../com/staybloom/reservation/uiproto/bookingAccountUiProto";
import { DeliveryStatusUi } from "../com/staybloom/reservation/uiproto/deliveryUiProto";
import { ExtraInfoForPLUiPb } from "../com/staybloom/reservation/uiproto/extraInfoServiceUiProto";
import { BaseProductLineState } from "../com/staybloom/reservation/uiproto/productLineStateUiProto";
import { CRMUiPb } from "../com/staybloom/uiproto/crm/crmUiProto";
import {
  ExtraInfoUiType,
  ProductTypeUiPbEnum,
  RevenueHeadUiPbEnum,
} from "../com/staybloom/uiproto/product/productUiProto";
import { PropertyRefUiPb } from "../com/staybloom/uiproto/property/propertyUiProto";
import { ReservationTypeUiPb } from "./../com/staybloom/reservation/uiproto/bookingAccountConfigUiProto";
import { ProfileSimplified } from "./licenseSimplifiedVersion";

export interface BookingAccountUiSimplified {
  id?: string;
  reservationType?: ReservationTypeUiPb;
  payType?: PayTypeEnum;
  voucherNumber?: string;
  segmentTag?: string;
  masterGuestList?: ProfileSimplified[];
  primaryGuest?: ProfileSimplified;
  cancellationPolicy?: CancellationPolicyEnum;
  booker?: ProfileSimplified;
  bookersRepresentative?: ProfileSimplified;
  gstDetails?: GSTInfoUiPb;
  hotelInfo?: HotelInfoSimplified;
  bookingAccountState?: BookingAccountStateUiPb;
  payments?: PaymentTotalSimplified[];
  rooms?: RoomSimplified[];
  addonResId?: string;
  mainResId?: string;
  addons?: AddonsAndExpensesSimplified[];
  bookingStatus?: totalBookingStatusSimplified;
  addonsStatus?: addonStatusSimplifed;
  paymentStatus?: PaymentStatusSimplified;
  creditNoteRes?: string;
  CrManager?: CRMUiPb;
  mainReservationAddons?: AddonDetailUiSimplified[];
  mainReservationPayments?: PaymentSimplified[];
  invoiceComment?: string;
  primaryGuestIndex?: number;
  tagId?: string;
  propertyRef?: PropertyRefUiPb;
  sellerDBId?: string;
}

export interface HotelInfoSimplified {
  name?: string;
  standardCheckInTime?: Moment;
  standardCheckoutTime?: Moment;
  imageUrl?: string;
  inventoryStoreId?: string;
  propertyId?: string;
  sellerId?: string;
}

export interface PaymentSimplified {
  mode?: ModeOfPaymentUiEnum;
  state?: PaymentStateUiEnum;
  paymentTime?: Date;
  amount?: number;
  postedBy?: string;
  isInternationalPayment?: boolean;
  transactionId?: string;
  remarks?: string;
}

export interface RoomSimplified {
  productLinePath?: string;
  baseProductLineState?: BaseProductLineState;
  assignedRoomNo?: string;
  verifiedGuests?: ProfileSimplified[];
  checkinTimeAndDate?: Date;
  checkoutTimeAndDate?: Date;
  actualCheckinTimeAndDate?: Date;
  actualCheckoutTimeAndDate?: Date;
  roomTypeName?: string;
  roomTypeId?: string;
  productType?: ProductTypeUiPbEnum;
  inclusionPlanName?: string;
  inclusionPlanCode?: string;
  charge?: ChargeSimplified;
  extraInfo?: ExtraInfoForPLUiPb;
  blockedBy?: string;
  blockedAt?: Date;
  deliveryStatus?: DeliveryStatusUi;
  deliveryStartedBy?: string;
  guestCount?: number;
  productId?: string;
  baseProductLineId?: string;
}

export interface AddonsAndExpensesSimplified {
  name?: string;
  type?: ProductTypeUiPbEnum;
  extraInfo?: ExtraInfoForPLUiPb;
  charges?: ChargeSimplified;
  totalUnits?: number;
  totalTracked?: number;
}

export interface ChargeSimplified {
  tariff?: number;
  inclusion?: number;
  commission?: number;
  charge?: number;
  tax?: number;
  totalCharge?: number;
}

export interface PaymentTotalSimplified {
  id?: string;
  totalAmount?: number;
  totalPayment?: number;
  pendingAmount?: number;
}

export interface totalBookingStatusSimplified {
  totalCheckIns?: number;
  totalCheckedOut?: number;
  totalArrivals?: number;
  totalNoShows?: number;
  totalDelivered?: number;
  totalCancelled?: number;
}

export interface addonStatusSimplifed {
  totalTracked?: number;
  totalPending?: number;
}

export interface PaymentStatusSimplified {
  mainBillPending?: number;
  otherBillsPending?: number;
}

export enum PayTypeEnum {
  BILL_TO_COMPANY = "Bill to Company",
  PAY_AT_HOTEL = "Pay at Hotel",
  ADHOC_CREDIT = "Adhoc Credit",
  PARTIAL_PAYMENT = "Partial Payment",
  PAY_BY_COMPANY = "Pay by Company",
}

export interface AddonDetailUiSimplified {
  productName: string;
  productType: ProductTypeUiPbEnum;
  produtctCode: string;
  revenueHead: RevenueHeadUiPbEnum;
  currency: CurrencyUiEnum;
  units: UnitInterfaceUiSimplified;
  charge: ChargeSimplified;
  notes: ExtraInfoForPLUiPb;
  infoType: ExtraInfoUiType;
  productLinePath?: string;
  baseProductLineState: BaseProductLineState;
}

export interface UnitInterfaceUiSimplified {
  tracked?: number;
  total?: number;
  remaining?: number;
}
