import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { InventoryStoreStoreService } from "../stores/staybloom/inventoryStoreStore/inventoryStoreStore.service";

@Injectable({
  providedIn: "root",
})
export class PageServiceService {
  private currentHotelInventoryId = new BehaviorSubject<string>("");
  private pageHeader = new BehaviorSubject<any>("");
  private occupancyDataTable = new BehaviorSubject<any>("");
  private roomTypeDataTable = new BehaviorSubject<any>("");

  constructor(private inventoryStoreService: InventoryStoreStoreService) {}

  getCurrentHotelInventoryId() {
    return this.currentHotelInventoryId.asObservable();
  }

  setCurrentHotelInventoryId(value: string) {
    this.currentHotelInventoryId.next(value);
  }

  getPageHeader() {
    return this.pageHeader.asObservable();
  }

  setPageHeader(value: string) {
    this.pageHeader.next(value);
  }

  getOccupancyDataTable() {
    return this.occupancyDataTable.asObservable();
  }

  setOccupancyDataTable(obj: any) {
    this.occupancyDataTable.next(obj);
  }
  getRoomTypeDataTable() {
    return this.roomTypeDataTable.asObservable();
  }

  setRoomTypeDataTable(obj: any) {
    this.roomTypeDataTable.next(obj);
  }

  async setPropertyData(pageId: string) {
    let inventoryStoreID =
      await this.inventoryStoreService.getInventoryStoreData();
    // Filter From the requested data to match it with current page id
    const inventory = inventoryStoreID?.filter(
      (val: any) => val.id === pageId
    )[0];

    return inventory;
  }
}
