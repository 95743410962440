import { ListInventoryStoreResponseUiPb } from "./../com/staybloom/uiproto/inventoryStore/inventoryStoreUiProto";
import { AppConfig } from "./../_config/app.config";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PropertyUiPb } from "../com/staybloom/uiproto/property/propertyUiProto";
import { ListLicenseGroupResponseUiPb } from "../com/staybloom/licenseQuery/uiproto/licenseQueryUiProto";
import { ExtraInfoForBookingAccountUiPb } from "../com/staybloom/reservation/uiproto/extraInfoServiceUiProto";
import { map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpClient) {}

  //Getting all properties list
  getPropertiesList(value: string) {
    return this.http.get<ListInventoryStoreResponseUiPb>(
      AppConfig.API_URL + AppConfig.FRON_END_API.INVENTORY_STORES,
      {
        params: {
          query: `{ "user": "${value}" }`,
        },
        headers: { apiName: "unit detail" },
      }
    );
  }

  // Getting a Specific Property Details by Passing Inventory ID
  getPropertyData(id: string) {
    return this.http.get<PropertyUiPb>(
      AppConfig.API_URL + AppConfig.FRON_END_API.PROPERTIES + id,
      { headers: { apiName: "property information" } }
    );
  }

  // Getting Licenses Data
  getAllLicenses(obj: any) {
    return this.http.get<ListLicenseGroupResponseUiPb>(
      AppConfig.API_URL +
        AppConfig.FRON_END_API.SEARCH +
        AppConfig.FRON_END_API.LICENSE_GROUPS,
      {
        params: {
          query: JSON.stringify(obj),
        },
        headers: { apiName: "stay information" },
      }
    );
  }

  // Get All Rooms Cleaning Status
  getRoomCleaningStatus(obj: any) {
    return this.http.get(AppConfig.API_URL + AppConfig.FRON_END_API.ROOMS, {
      params: {
        query: JSON.stringify(obj),
      },
      headers: { apiName: "room information" },
    });
  }

  // Get Booking Balance by booking Id
  getLicenseBalance(obj: any) {
    return this.http.get(
      AppConfig.API_URL +
        AppConfig.FRON_END_API.SEARCH +
        AppConfig.FRON_END_API.BILLING_ACCOUNT_BILLS,
      {
        params: {
          query: JSON.stringify(obj),
        },
        headers: { apiName: "billing information" },
      }
    );
  }

  // Get Booking Balance by booking Id
  getBookingAccountData(id: string | undefined, skipError?: boolean) {
    return this.http.get(
      AppConfig.API_URL + AppConfig.FRON_END_API.BOOKING_ACCOUNTS + id,
      {
        headers: {
          apiName: "booking information",
          skipError: skipError ? "true" : "false",
        },
      }
    );
  }

  updateBookingAccount(data: any, id: string | undefined) {
    return this.http
      .put(
        AppConfig.API_URL + AppConfig.FRON_END_API.BOOKING_ACCOUNTS + id,
        data,
        { headers: { apiName: "booking information" } }
      )
      .toPromise();
  }

  getInventoryLinesData(query: any) {
    return this.http.get(
      AppConfig.API_URL +
        // "awd" +
        AppConfig.FRON_END_API.SEARCH +
        AppConfig.FRON_END_API.INVENTORY_LINES,
      {
        params: {
          query: JSON.stringify(query),
        },
        headers: { apiName: "inventory information" },
      }
    );
  }

  getLicenseFulfillment(id: string) {
    return this.http.get(
      AppConfig.API_URL + AppConfig.FRON_END_API.FULFILLMENT + id,
      {
        headers: { apiName: "fulfilment information" },
      }
    );
  }

  updateLicenseFulfillment(id: string, data: any) {
    const url = AppConfig.API_URL + AppConfig.FRON_END_API.FULFILLMENT + id;
    return this.http.put(url, data, {
      headers: { apiName: "fulfilment information" },
    });
  }

  getExtraInfoService(id: string) {
    return this.http.get(
      AppConfig.API_URL + AppConfig.FRON_END_API.EXTRA_INFO_SERVICE + id,
      { headers: { apiName: "info notes" } }
    );
  }

  updateExtraInfoService(id: string, data: ExtraInfoForBookingAccountUiPb) {
    const url =
      AppConfig.API_URL + AppConfig.FRON_END_API.EXTRA_INFO_SERVICE + id;
    return this.http.put(url, data, { headers: { apiName: "info notes" } });
  }
  //Get Occupancy Details
  getPropertyOccupancy(obj: any) {
    obj = JSON.stringify(obj);
    return this.http.get(
      // "https://qa-crs.bloomhotels.in/pmsServerSB/export/roomOccupancyAndRates/" +
      AppConfig.API_URL +
        AppConfig.FRON_END_API.EXPORT +
        AppConfig.FRON_END_API.ROOM_OCCUPANCY_AND_RATES +
        obj,
      { headers: { apiName: "occupancy and rates" } }
    );
  }

  printGrc(data: any) {
    return this.http.get(
      AppConfig.API_URL + AppConfig.FRON_END_API.PRINT_GUEST_REG,
      {
        params: {
          params: JSON.stringify(data),
        },
        headers: { apiName: "registration card" },
        responseType: "blob",
      }
    );
  }

  getQrDetails(data: any) {
    return this.http.post(
      AppConfig.API_URL + AppConfig.FRON_END_API.AUTHENTICATED_LINK,
      data,
      {
        headers: { apiName: "QR Code" },
      }
    );
  }

  moveAddOn(data: any) {
    return this.http.put(
      AppConfig.API_URL +
        AppConfig.FRON_END_API.MOVE_ADD_ON +
        "/" +
        data?.fromBookingAccountId?.id,
      data,
      {
        headers: {
          apiName: "Add-Ons",
          "content-type": "application/x-www-form-urlencoded",
        },
      }
    );
  }

  getRoomAvailability(data: any) {
    return this.http.get(AppConfig.API_URL + AppConfig.FRON_END_API.PRODUCTS, {
      params: {
        query: JSON.stringify(data),
      },
      headers: { apiName: "room category" },
    });
  }

  getBookingDetails(data: any) {
    return this.http.get(
      AppConfig.API_URL +
        AppConfig.FRON_END_API.BOOKINGS +
        JSON.stringify(data),
      {
        headers: { apiName: "bookings data" },
      }
    );
  }

  getLatestLicense(obj: any) {
    return this.http.get(
      AppConfig.API_URL + AppConfig.FRON_END_API.LICENSE_GROUPS,
      {
        params: {
          query: JSON.stringify(obj),
        },
        headers: { apiName: "license information" },
      }
    );
  }

  getReportsData(obj: any) {
    return this.http.get(
      AppConfig.API_URL +
        AppConfig.FRON_END_API.SEARCH +
        AppConfig.FRON_END_API.LICENSE_GROUPS,
      {
        params: {
          query: JSON.stringify(obj),
        },
        headers: { apiName: "report information" },
      }
    );
  }

  getTagsForSegment(sellerDBId: string) {
    return this.http.get(
      AppConfig.API_URL + AppConfig.FRON_END_API.SELLERDBIDSERVER + sellerDBId,
      {
        headers: { apiName: "sellerDB information" },
      }
    );
  }

  getCoverLetters(bookingIds: string) {
    const url =
      AppConfig.API_URL +
      AppConfig.FRON_END_API.COVER_LETTER +
      "/" +
      bookingIds;
    return this.http.get(url, {
      responseType: "arraybuffer",
      headers: {
        apiName: "cover letter",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  getReservationLetters(bookingIds: string) {
    const url =
      AppConfig.API_URL + AppConfig.FRON_END_API.RESERVATIONDETAIL + bookingIds;
    return this.http.get(url, {
      responseType: "arraybuffer",
      headers: {
        apiName: "cover letter",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  downloadInvoice(id: string) {
    const url =
      AppConfig.API_URL +
      AppConfig.FRON_END_API.INVOICE_REPORT +
      "?query=" +
      "/" +
      id;
    return this.http.get(url, {
      responseType: "arraybuffer",
      headers: {
        apiName: "invoice report",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  downloadProforma(id: string) {
    const url =
      AppConfig.API_URL + AppConfig.FRON_END_API.PROFORMA_INVOICE + id;
    return this.http.post(url, "", {
      responseType: "arraybuffer",
      headers: {
        apiName: "summary of invoices",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  downloadInvoices(id: string) {
    const url =
      AppConfig.API_URL + AppConfig.FRON_END_API.TAX_INVOICE + "?query=" + id;
    return this.http.get(url, {
      responseType: "arraybuffer",
      headers: {
        apiName: "tax invoice",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  getSidekickDevices(payload: any) {
    return this.http.get(
      AppConfig.API_URL + AppConfig.FRON_END_API.SIDEKICK_DEVICE,
      {
        params: {
          query: JSON.stringify(payload),
        },
        headers: { apiName: "sidekick devices" },
      }
    );
  }

  loginDevice(obj: any) {
    return this.http.post(
      AppConfig.API_URL + AppConfig.FRON_END_API.SIDEKICK_CONNECT,
      obj,
      { headers: { skipError: "true" } }
    );
  }

  checkDeviceConnection(id: any) {
    return this.http.post(
      AppConfig.API_URL +
        AppConfig.FRON_END_API.SIDEKICK_CHECK_CONNECT +
        "/" +
        id.toString(),
      "",
      { headers: { skipError: "true" } }
    );
  }

  changeSidekickState(obj: any) {
    return this.http.post(
      AppConfig.API_URL + AppConfig.FRON_END_API.SIDEKICK_STATE_CHANGE,
      obj,
      { headers: { skipError: "true" } }
    );
  }

  getRazorPayQRCode(data: any) {
    return this.http.post(
      AppConfig.API_URL + AppConfig.FRON_END_API.RAZORPAY_QR,
      data,
      {
        headers: { apiName: "QR Code" },
      }
    );
  }

  getRazorPayEDCPayment(data: any) {
    return this.http.post(
      AppConfig.API_URL + AppConfig.FRON_END_API.RAZORPAY_EDC,
      data,
      {
        headers: { apiName: "QR Code" },
      }
    );
  }

  fetchFile(url: string) {
    return this.http.get(url, { responseType: "blob" });
  }

  cancelRazorPayEDCPayment(data: any) {
    return this.http.post(
      AppConfig.API_URL + AppConfig.FRON_END_API.CANCEL_EDC_PAYMENT,
      data
    );
  }
}
