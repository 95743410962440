import { Directive, ElementRef, Renderer2, Input } from "@angular/core";

@Directive({
  selector: "[appClickRestrict]",
})
export class ClickRestrictDirective {
  private overlay: HTMLDivElement;

  @Input() appClickRestrict: boolean = true;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnChanges() {
    if (this.appClickRestrict) {
      this.addOverlayElement();
    } else {
      this.removeOverlayElement();
    }
  }

  private addOverlayElement() {
    if (!this.overlay) {
      // alert(1);
      this.overlay = this.renderer.createElement("div");
      this.renderer.addClass(this.overlay, "progress-wait");
      this.renderer.appendChild(this.elementRef.nativeElement, this.overlay);
    }
  }

  private removeOverlayElement() {
    if (this.overlay) {
      this.renderer.removeChild(this.elementRef.nativeElement, this.overlay);
      this.overlay = null;
    }
  }
}
