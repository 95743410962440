<ng-container *ngIf="formSections && isArray(formSections)">
  <form
    #dynamicform
    [formGroup]="form"
    class="custom_dynamic_form"
    (ngSubmit)="onSubmit()"
    *ngIf="formSections"
    #formRef="ngForm"
  >
    <div class="section" *ngFor="let section of formSections">
      <h2 class="section_title" *ngIf="section.sectionName">
        {{ section.sectionName }}
      </h2>
      <div
        *ngIf="section?.sectionFromGroup"
        class="fields"
        [formGroupName]="
          section.sectionFromGroup ? section.sectionFromGroup : ''
        "
      >
        <div
          *ngFor="let field of section?.fields"
          [ngClass]="{
            full_width: field?.width === FieldWidthEnumSimplified.FULLWIDTH,
            half_width_left:
              field?.width === FieldWidthEnumSimplified.HALFWIDTHLEFT,
            half_width_right:
              field?.width === FieldWidthEnumSimplified.HALFWIDTHRIGHT
          }"
        >
          <mat-form-field
            *ngIf="
              field?.type === FieldTypeEnumSimplified.TEXT ||
              field?.type === FieldTypeEnumSimplified.EMAIL ||
              field?.type === FieldTypeEnumSimplified.NUMBER ||
              field?.type === FieldTypeEnumSimplified.TEL ||
              field?.type === FieldTypeEnumSimplified.URL
            "
            [ngClass]="{
              custom_text_input_field: true,
              suggested: field.suggested
            }"
            [hidden]="field.hidden"
            [ngStyle]="{ display: field?.hidden ? 'none' : 'block' }"
            appearance="outline"
          >
            <mat-label>{{ field.label }}</mat-label>
            <input
              (focusout)="onFieldFocus(field)"
              matInput
              [type]="field?.type"
              [formControlName]="field.name"
              [required]="field.required"
            />
          </mat-form-field>

          <ng-container *ngIf="field?.type === FieldTypeEnumSimplified.SELECT">
            <mat-form-field
              appearance="outline"
              [ngClass]="{ suggested: field.suggested }"
              class="custom_text_input_field cutom_select_input_field"
            >
              <mat-label>{{ field.label }}</mat-label>
              <mat-select
                (opened)="closeModal(field, $event, false)"
                (closed)="closeModal(field, $event, true)"
                panelClass="custom_select_box"
                [formControlName]="field.name"
                (selectionChange)="onSelectionChange(field, $event)"
                [required]="field.required"
              >
                {{ field.value }}
                <mat-option
                  *ngFor="let opt of field.options"
                  [value]="opt.value"
                  >{{ opt.key.replace("_", " ") }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </ng-container>

          <ng-container
            *ngIf="field?.type === FieldTypeEnumSimplified.OTHERSELECT"
          >
            <mat-form-field
              appearance="outline"
              [ngClass]="{ suggested: field.suggested }"
              class="custom_text_input_field cutom_select_input_field"
            >
              <mat-label>{{ field.label }}</mat-label>
              <mat-select
                (opened)="closeModal(field, $event, false)"
                (closed)="closeModal(field, $event, true)"
                panelClass="custom_select_box"
                [formControlName]="field.name"
                #selectValue
                (valueChange)="onChangeHandler($event)"
              >
                <mat-option
                  *ngFor="let opt of field.options"
                  value="{{ opt.value }}"
                  >{{ opt.key }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </ng-container>

          <!-- Custom Group Select Box -->

          <ng-container
            *ngIf="field?.type === FieldTypeEnumSimplified.SELECTGROUP"
          >
            <mat-form-field
              [ngClass]="{ suggested: field.suggested }"
              appearance="outline"
              class="custom_text_input_field cutom_select_input_field"
            >
              <mat-label>{{ field.label }}</mat-label>
              <mat-select
                panelClass="custom_select_box"
                [formControlName]="field.name"
              >
                <mat-optgroup
                  *ngFor="let group of field.optionGroups"
                  [label]="group.title"
                  [disabled]="group.disabled"
                >
                  <mat-option
                    *ngFor="let item of group.fields"
                    [value]="item.value"
                    >{{ item.key }}</mat-option
                  >
                </mat-optgroup>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <!-- If type is textarea -->

          <ng-container
            *ngIf="field?.type === FieldTypeEnumSimplified.TEXTAREA"
          >
            <mat-form-field
              [ngClass]="{ suggested: field.suggested }"
              appearance="outline"
              class="custom_text_input_field custom_text_area_field"
            >
              <mat-label>{{ field.label }}</mat-label>
              <textarea
                matInput
                [type]="field?.type"
                [formControlName]="field.name"
              ></textarea>
            </mat-form-field>
          </ng-container>

          <ng-container
            *ngIf="
              field?.type === FieldTypeEnumSimplified.COMMENT &&
              isTextAreaAvailable
            "
          >
            <mat-form-field
              [ngClass]="{ suggested: field.suggested }"
              appearance="outline"
              class="custom_text_input_field custom_text_area_field"
            >
              <mat-label>{{ field.label }}</mat-label>
              <textarea
                matInput
                [type]="field?.type"
                [formControlName]="field.name"
              ></textarea>
            </mat-form-field>
          </ng-container>

          <!-- if type is datePicker -->

          <ng-container *ngIf="field?.type === FieldTypeEnumSimplified.DATE">
            <div>
              <mat-form-field
                [ngClass]="{ suggested: field.suggested }"
                appearance="outline"
                class="custom_text_input_field custom_date_picker"
              >
                <mat-label>{{ field.label }}</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  [formControlName]="field.name"
                  (dateChange)="closeModal(field, $event, true)"
                  [required]="field.required"
                />
                <mat-datepicker-toggle matIconSuffix [for]="picker">
                  <mat-icon matDatepickerToggleIcon>
                    <img
                      src="/assets/icons/calendar.svg"
                      class="datePicker_icon"
                    /> </mat-icon
                ></mat-datepicker-toggle>
                <mat-datepicker
                  #picker
                  (opened)="closeModal(field, $event, false)"
                  (closed)="closeModal(field, $event, true)"
                  panelClass="custom_date_picker_shadow"
                ></mat-datepicker>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container
            *ngIf="field?.type === FieldTypeEnumSimplified.DATE_RANGE"
          >
            <div class="daterangedyanmic">
              <mat-form-field class="custom__datepicker" appearance="outline">
                <mat-label>{{ field.label }}</mat-label>
                <mat-date-range-input
                  [formGroupName]="field.name"
                  [rangePicker]="picker"
                >
                  <input
                    matStartDate
                    matInput
                    placeholder="Start date"
                    [formControlName]="field.fields[0].name"
                  />
                  <input
                    matEndDate
                    matInput
                    placeholder="End date"
                    [formControlName]="field.fields[1].name"
                    (dateChange)="closeModal(field, $event, true)"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker">
                  <mat-icon matDatepickerToggleIcon>
                    <img src="/assets/icons/calendar.svg" />
                  </mat-icon>
                </mat-datepicker-toggle>
                <mat-date-range-picker
                  (closed)="
                    checkIfRangeSelected(field.fields[1].name, field.name)
                  "
                  #picker
                  panelClass="custom_date_picker_shadow"
                ></mat-date-range-picker>
              </mat-form-field>
            </div>
          </ng-container>

          <!-- if type is checkbox -->

          <ng-container *ngIf="field?.type === FieldTypeEnumSimplified.SLIDE">
            <div>
              <mat-slide-toggle
                color="primary"
                [formControlName]="field?.name"
                [checked]="field?.value"
                >{{ field?.label }}</mat-slide-toggle
              >
            </div>
          </ng-container>

          <ng-container *ngIf="field?.type === FieldTypeEnumSimplified.TIME">
            <mat-form-field
              [ngClass]="{ suggested: field.suggested }"
              appearance="outline"
              color="primary"
              class="custom_text_input_field toggle-example"
              ><mat-label>{{ field.label }}</mat-label>
              <input
                matInput
                [format]="24"
                [ngxTimepicker]="appendedToInput"
                [formControlName]="field.name"
                readonly
              />
              <ngx-material-timepicker-toggle
                [for]="appendedToInput"
              ></ngx-material-timepicker-toggle>

              <ngx-material-timepicker
                #appendedToInput
                [appendToInput]="true"
                [theme]="darkTheme"
                [disableAnimation]="true"
                (opened)="closeModal(field, $event, false, true)"
                (closed)="closeModal(field, $event, true, true)"
              ></ngx-material-timepicker>
            </mat-form-field>
          </ng-container>

          <ng-container
            *ngIf="field?.type === FieldTypeEnumSimplified.CHECKBOX"
          >
            <mat-checkbox
              (click)="onSelectionChange(field, $event)"
              class="custom_check_box"
              color="primary"
              [formControlName]="field.name"
              [checked]="field?.value"
              >{{ field.label }}</mat-checkbox
            >
          </ng-container>

          <!-- if type is file  -->
          <ng-container *ngIf="field?.type === FieldTypeEnumSimplified.FILE">
            <div
              title="upload"
              [ngClass]="{
                disabled: form.disabled,
                img_container: true,
                profile_img_container: field.isProfileImage,
                suggested: field.suggested,
                imgRequired: field.required
              }"
              (dragover)="onDragOver($event)"
              (dragleave)="onDragLeave($event)"
              (drop)="onDrop($event, field)"
            >
              <label [for]="field.name" class="upload_label">
                <img
                  (click)="downloadImage.click()"
                  class="upload-icon"
                  src="/assets/icons/profile_icons/UPload.svg"
                  alt=""
                />
                <img
                  class="upload-image"
                  [src]="!field?.value ? getData(field) : field.value"
                />
              </label>
              <input
                #downloadImage
                hidden
                [id]="field.name"
                (change)="onSelectionChange(field, $event)"
                [required]="field.required"
                type="file"
                accept="image/*"
              />
              <a
                [href]="getData(field)"
                [download]="getData(field)"
                *ngIf="field?.download"
                class="download-image"
                title="download"
              >
                <img
                  target="_self"
                  style="transform: rotate(180deg)"
                  class="upload-icon"
                  src="/assets/icons/profile_icons/UPload.svg"
                  alt=""
                />
              </a>
            </div>
          </ng-container>

          <ng-container
            *ngIf="field?.type === FieldTypeEnumSimplified.COUNTRY_CODE"
          >
            <mat-form-field
              id="countryCodeSelect"
              class="custom_mat_input_mobile cutom_select_input_field custom_text_input_field"
              appearance="outline"
            >
              <mat-label for="countryCodeSelect">Country Code</mat-label>
              <mat-select
                panelClass="custom_select_box country_select_box"
                #select
                [formControlName]="field.name"
                (selectionChange)="onSelectionChange(field, $event)"
                (opened)="focusOnSelect()"
              >
                <mat-select-trigger [class.changeColor]="field?.disabled">
                  {{ select.value }}
                </mat-select-trigger>
                <div class="search-box">
                  <img height="16" src="/assets/icons/select_box_search.svg" />
                  <input
                    id="searchInput"
                    class="search_text"
                    (keyup)="searchEventHandle($event)"
                    placeholder="Search ..."
                  />
                </div>
                <ng-container
                  *ngIf="COUNTRIES_LIST | filter : searchValue as result"
                >
                  <mat-option *ngFor="let item of result" [value]="item.code">
                    {{ item.country }} ({{ item.code }})
                  </mat-option>
                  <p class="no-data-text" *ngIf="result.length === 0" disabled>
                    No data found
                  </p>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <ng-container
            *ngIf="field?.type === FieldTypeEnumSimplified.AUTOCOMPLETE"
          >
            <mat-form-field class="example-full-width">
              <mat-label>{{ field.label }}</mat-label>
              <input
                type="text"
                matInput
                [formControlName]="field.name"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete
                autoActiveFirstOption
                #auto="matAutocomplete"
                [displayWith]="displayNameAutoComplete"
              >
                <!-- @for (option of field.options | async; track option) {
                <mat-option [value]="option.value">{{ option.key }}</mat-option>
                } -->
                <mat-option
                  *ngfor="let option of field.options | async; track: option"
                  [value]="option.value"
                >
                  {{ option.key }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </ng-container>
        </div>
      </div>
      <div *ngIf="!section?.sectionFromGroup" class="fields">
        <div
          *ngFor="let field of section?.fields"
          [ngClass]="{
            full_width: field?.width === FieldWidthEnumSimplified.FULLWIDTH,
            half_width_left:
              field?.width === FieldWidthEnumSimplified.HALFWIDTHLEFT,
            half_width_right:
              field?.width === FieldWidthEnumSimplified.HALFWIDTHRIGHT
          }"
        >
          <mat-form-field
            *ngIf="
              field?.type === FieldTypeEnumSimplified.TEXT ||
              field?.type === FieldTypeEnumSimplified.EMAIL ||
              field?.type === FieldTypeEnumSimplified.NUMBER ||
              field?.type === FieldTypeEnumSimplified.TEL ||
              field?.type === FieldTypeEnumSimplified.URL
            "
            [ngClass]="{
              custom_text_input_field: true,
              suggested: field.suggested
            }"
            appearance="outline"
            [hidden]="field.hidden"
            [ngStyle]="{ display: field?.hidden ? 'none' : 'block' }"
          >
            <mat-label>{{ field.label }}</mat-label>
            <input
              (focusout)="onFieldFocus(field)"
              matInput
              [type]="field?.type"
              [formControlName]="field.name"
              [value]="field.value"
              [required]="field.required"
            />
          </mat-form-field>

          <ng-container *ngIf="field?.type === FieldTypeEnumSimplified.SELECT">
            <mat-form-field
              appearance="outline"
              class="custom_text_input_field cutom_select_input_field"
            >
              <mat-label>{{ field.label }}</mat-label>
              <mat-select
                panelClass="custom_select_box"
                [formControlName]="field.name"
                (selectionChange)="onSelectionChange(field, $event)"
                (openedChange)="closeModal(field, $event, !$event)"
              >
                <mat-option
                  *ngFor="let opt of field.options"
                  value="{{ opt.value }}"
                  >{{ opt.key }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </ng-container>

          <ng-container
            *ngIf="field?.type === FieldTypeEnumSimplified.OTHERSELECT"
          >
            <mat-form-field
              appearance="outline"
              class="custom_text_input_field cutom_select_input_field"
            >
              <mat-label>{{ field.label }}</mat-label>
              <mat-select
                panelClass="custom_select_box"
                [formControlName]="field.name"
                #selectValue
                (valueChange)="onChangeHandler($event)"
                (openedChange)="closeModal(field, $event, !$event)"
                (selectionChange)="onSelectionChange(field, $event)"
              >
                <mat-option
                  *ngFor="let opt of field.options"
                  value="{{ opt.value }}"
                  >{{ opt.key }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </ng-container>

          <!-- Auto COmplete -->

          <ng-container
            *ngIf="field?.type === FieldTypeEnumSimplified.AUTOCOMPLETE"
          >
            <mat-form-field
              class="example-full-width"
              appearance="outline"
              class="custom_text_input_field cutom_select_input_field"
            >
              <mat-label>{{ field.label }}</mat-label>
              <input
                type="text"
                matInput
                #autocompleteInput
                [formControlName]="field.name"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete
                autoActiveFirstOption
                #auto="matAutocomplete"
                [displayWith]="displayNameAutoComplete"
                class="custom_select_box"
              >
                <mat-option
                  *ngFor="
                    let option of field.options
                      | filter : autocompleteInput?.value
                  "
                  [value]="option"
                >
                  {{ option.key }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </ng-container>

          <!-- Custom Group Select Box -->

          <ng-container
            *ngIf="field?.type === FieldTypeEnumSimplified.SELECTGROUP"
          >
            <mat-form-field
              appearance="outline"
              class="custom_text_input_field cutom_select_input_field"
            >
              <mat-label>{{ field.label }}</mat-label>
              <mat-select
                panelClass="custom_select_box"
                [formControlName]="field.name"
              >
                <mat-optgroup
                  *ngFor="let group of field.optionGroups"
                  [label]="group.title"
                  [disabled]="group.disabled"
                >
                  <mat-option
                    *ngFor="let item of group.fields"
                    [value]="item.value"
                    >{{ item.key }}</mat-option
                  >
                </mat-optgroup>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <!-- If type is textarea -->

          <ng-container
            *ngIf="field?.type === FieldTypeEnumSimplified.TEXTAREA"
          >
            <mat-form-field
              appearance="outline"
              class="custom_text_input_field custom_text_area_field"
            >
              <mat-label>{{ field.label }}</mat-label>
              <textarea
                matInput
                [type]="field?.type"
                [formControlName]="field.name"
              ></textarea>
            </mat-form-field>
          </ng-container>

          <ng-container *ngIf="isTextAreaAvailable">
            <mat-form-field
              *ngIf="field?.type === FieldTypeEnumSimplified.COMMENT"
              appearance="outline"
              class="custom_text_input_field custom_text_area_field"
            >
              <mat-label>{{ field.label }}</mat-label>
              <textarea
                matInput
                [type]="field?.type"
                [formControlName]="field.name"
              ></textarea>
            </mat-form-field>
            <mat-form-field
              *ngIf="field?.type === FieldTypeEnumSimplified.COMMENT_INPUT"
              [ngClass]="{
                custom_text_input_field: true,
                suggested: field.suggested
              }"
              appearance="outline"
            >
              <mat-label>{{ field.label }}</mat-label>
              <input
                (focusout)="onFieldFocus(field)"
                matInput
                [type]="field?.type"
                [formControlName]="field.name"
              />
            </mat-form-field>
          </ng-container>

          <!-- if type is datePicker -->

          <ng-container *ngIf="field?.type === FieldTypeEnumSimplified.DATE">
            <mat-form-field
              appearance="outline"
              class="custom_text_input_field custom_date_picker"
            >
              <mat-label>{{ field.label }}</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                [formControlName]="field.name"
                (dateChange)="closeModal(field, $event, true)"
                [required]="field.required"
              />
              <mat-datepicker-toggle matIconSuffix [for]="picker"
                ><mat-icon matDatepickerToggleIcon>
                  <img
                    src="/assets/icons/calendar.svg"
                    class="datePicker_icon"
                  /> </mat-icon
              ></mat-datepicker-toggle>
              <mat-datepicker
                #picker
                (opened)="closeModal(field, $event, false)"
                (closed)="closeModal(field, $event, true)"
                panelClass="custom_date_picker_shadow"
              ></mat-datepicker>
            </mat-form-field>
          </ng-container>

          <ng-container
            *ngIf="field?.type === FieldTypeEnumSimplified.DATE_RANGE"
          >
            <div class="daterangedyanmic">
              <mat-form-field class="custom__datepicker" appearance="outline">
                <mat-label>{{ field.label }}</mat-label>

                <mat-date-range-input
                  [formGroupName]="field.name"
                  [rangePicker]="picker"
                  readonly
                >
                  <input
                    matStartDate
                    matInput
                    [formControlName]="field.fields[0].name"
                    readonly
                  />
                  <input
                    matEndDate
                    matInput
                    [formControlName]="field.fields[1].name"
                    (dateChange)="closeModal(field, $event, true)"
                    readonly
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker">
                  <mat-icon matDatepickerToggleIcon>
                    <img src="/assets/icons/calendar.svg" />
                  </mat-icon>
                </mat-datepicker-toggle>
                <mat-date-range-picker
                  (closed)="
                    checkIfRangeSelected(field.fields[1].name, field.name)
                  "
                  #picker
                  panelClass="custom_date_picker_shadow"
                ></mat-date-range-picker>
              </mat-form-field>
            </div>
          </ng-container>

          <!-- if type is checkbox -->

          <ng-container *ngIf="field?.type === FieldTypeEnumSimplified.TIME">
            <mat-form-field
              appearance="outline"
              color="primary"
              class="custom_text_input_field toggle-example"
              ><mat-label>{{ field.label }}</mat-label>
              <input
                matInput
                [format]="24"
                [ngxTimepicker]="appendedToInput"
                [formControlName]="field.name"
                readonly
              />
              <ngx-material-timepicker-toggle
                [for]="appendedToInput"
              ></ngx-material-timepicker-toggle>

              <ngx-material-timepicker
                #appendedToInput
                [appendToInput]="true"
                [theme]="darkTheme"
                [disableAnimation]="true"
                (opened)="closeModal(field, $event, false, true)"
                (closed)="closeModal(field, $event, true, true)"
              ></ngx-material-timepicker>
            </mat-form-field>
          </ng-container>

          <ng-container
            *ngIf="field?.type === FieldTypeEnumSimplified.CHECKBOX"
          >
            <mat-checkbox
              (click)="onSelectionChange(field, $event)"
              class="custom_check_box"
              color="primary"
              [formControlName]="field.name"
              [checked]="field?.value"
              >{{ field.label }}</mat-checkbox
            >
          </ng-container>

          <ng-container *ngIf="field?.type === FieldTypeEnumSimplified.SLIDE">
            <div>
              <mat-slide-toggle
                color="primary"
                [formControlName]="field?.name"
                [checked]="field?.value"
                >{{ field?.label }}</mat-slide-toggle
              >
            </div>
          </ng-container>

          <!-- if type is file  -->

          <ng-container *ngIf="field?.type === FieldTypeEnumSimplified.FILE">
            <div
              [ngClass]="{
                img_container: true,
                profile_img_container: field.isProfileImage,
                imgRequired: field.required
              }"
              (dragover)="onDragOver($event)"
              (dragleave)="onDragLeave($event)"
              (drop)="onDrop($event, field)"
            >
              <img
                [ngClass]="{ image: true, profileImage: field.isProfileImage }"
                [src]="field.imagePlaceHolder"
                alt=""
              />
              <label [for]="field.name" class="upload_label">
                <img src="/assets/icons/profile_icons/UPload.svg" alt="" />
              </label>
              <input
                type="file"
                [id]="field.name"
                accept="image/*"
                hidden
                [required]="field.required"
              />
            </div>
          </ng-container>

          <ng-container
            *ngIf="field?.type === FieldTypeEnumSimplified.COUNTRY_CODE"
          >
            <mat-form-field
              id="countryCodeSelect"
              class="custom_mat_input_mobile cutom_select_input_field custom_text_input_field"
              appearance="outline"
            >
              <mat-label for="countryCodeSelect">Country Code</mat-label>
              <mat-select
                panelClass="custom_select_box country_select_box"
                #select
                [formControlName]="field.name"
                (selectionChange)="onSelectionChange(field, $event)"
                (opened)="focusOnSelect()"
              >
                <mat-select-trigger [class.changeColor]="field?.disabled">
                  {{ select.value }}
                </mat-select-trigger>
                <div class="search-box">
                  <img height="16" src="/assets/icons/select_box_search.svg" />
                  <input
                    id="searchInput"
                    class="search_text"
                    (keyup)="searchEventHandle($event)"
                    placeholder="Search ..."
                  />
                </div>
                <ng-container
                  *ngIf="COUNTRIES_LIST | filter : searchValue as result"
                >
                  <mat-option *ngFor="let item of result" [value]="item.code">
                    {{ item.country }} ({{ item.code }})
                  </mat-option>
                  <p class="no-data-text" *ngIf="result.length === 0" disabled>
                    No data found
                  </p>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </div>
      </div>
    </div>
    <div [ngClass]="{ hide_button: !showButtons }">
      <button class="btn btn-danger-outline btn-lg">Cancel</button>
      <button class="btn btn-primary btn-lg" type="submit">Submit</button>
    </div>
  </form>
</ng-container>
