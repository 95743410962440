import { Injectable, ErrorHandler } from "@angular/core";
import { SnackbarService } from "../_services/snackbar.service";
import { LoaderService } from "../_components/loader/loader.service";

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(
    private snackBar: SnackbarService,
    private loader: LoaderService
  ) {}

  handleError(error: any) {
    if (error?.message?.includes("<show error>")) {
      this.snackBar.openErrorSnackBar("Sorry, some error occurred!", "manual");
    }
    this.loader.showLoader(false);
    this.loader.showItemLoader(false);
    console.log(error);
    return error;
  }
}
