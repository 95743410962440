import { PriceUiPb } from "./../../common/uiproto/valueUiProto";
import { TimeRangeUiPb, TimeUiPb } from "./../../common/uiproto/timeUiProto";
import { CurrencyUiEnum } from "../../common/uiproto/currencyUiProto";
import { InventoryStoreReferenceUiPb } from "../inventoryStore/inventoryStoreUiProto";
import { StateUiEnum } from "../../common/uiproto/stateUiProto";
export interface ProductUiPb {
  id?: string;
  type?: ProductTypeUiPbEnum;
  code?: string;
  name?: string;
  //optional string checkin ?:5;
  checkin?: TimeUiPb;
  //optional string checkout ?:6;
  checkout?: TimeUiPb;
  minDays?: number;
  maxDays?: number;
  maxPersons?: number; //A room will accommodate these many guests aged 8 years and above without any additional charge for basic room
  maxAdults?: number; //Maximum guests allowed in the room aged 8 years and above, extra charge will be applied for guests above maxPersons and up to maxAdults
  maxChildren?: number; //Refer to sheet - https://docs.google.com/spreadsheets/d/1XWgS7C0ZYZ7KqObwxUj3v9T3F1NDQYoHtVI-q2HeQNY
  amenitiesEnum?: ProductAmenitiesUiPbEnum[];
  price?: PriceUiPb;
  priceModel?: PricingModelUiEnum;
  prebookable?: boolean;
  taxModel?: TaxModelUiEnum;
  cancelModel?: CancelModelUiEnum;
  infoType?: ExtraInfoUiType;
  cancelTaxModel?: TaxModelUiEnum;
  productGroupId?: string;
  bookingDateRange?: ProductBookingDateRangeUiPb[];
  inventoryStore?: InventoryStoreReferenceUiPb;
  seasonCalendarProductGroupId?: string;
  inventoryBlockInfo?: InventoryBlockConfigUiPb;
  //	optional com.staybloom.sellerDBIdStore.uiproto.SellerDBIdUiPb sellerDBId=27;
  priceConfig?: PriceConfigUiPb;
  desc?: string;
  bookingWebsiteDescUrl?: string;
  listIndex?: number;
  allocationRules?: AllocationRulesUiPb;
  deActivated?: boolean;
  revenueHead?: RevenueHeadUiPbEnum;
  flatOffPayNow?: FlatOffUiPb; //deprecated
  flatOffPayAtHotel?: FlatOffUiPb; //deprecated
  placeOfSupply?: StateUiEnum;
  glCode?: string;
  autoTrackTime?: TimeUiPb;
  autoTrackDays?: number;
  saCode?: string;
  customTaxModel?: CustomTaxModelUiPb;
  roomInclusionPlan?: RoomInclusionPlanUiPb[];
  isNoBusinessLogicValidation?: boolean;
}

export interface InventoryBlockConfigUiPb {
  inventoryResourceType?: InventoryResourceEnum;
  supplierInfo?: ProductSupplierInfo[]; // to be repeated.
}

export interface ProductBookingDateRangeUiPb {
  published?: boolean;
  dateRange?: TimeRangeUiPb;
}

// TODO: The following two have very similar names
export enum ProductTypeUiPbEnum {
  ROOM = "ROOM",
  ADD_ON = "ADD_ON",
  EXPENSE = "EXPENSE",

  //INCLUSION is NOT being stored in database, part of ROOM product type,
  //created to distinguish RevenueBookedUiPb records in RevenueFilterAndResponseCreator,
  // where ROOM revenue is being separated from INCLUSION revenue for reporting :
  INCLUSION = "INCLUSION",
}

export enum ProductTypeEnum {
  SAME_AS_BASE = 1,
  EVERY_DAY = 2,
}

export enum ProductAmenitiesUiPbEnum {
  AC = 1,
  LED_TV = 2,
  GEYSER = 3,
  QUEENS_BED = 4,
  POOL_FACING = 5,
}

export enum RevenueHeadUiPbEnum {
  ROOM_REVENUE = 1,
  FOOD_AND_BEVERAGE_REVENUE = 2,
  LAUNDRY_REVENUE = 3,
  TRANSPORT_REVENUE = 4,
  CONFERENCE_REVENUE = 5,
  OTHER_REVENUE = 6,
  NON_REVENUE = 7,
  BREAKFAST_REVENUE = 8,
  SPA_REVENUE = 9,
  RESTAURANT_FOOD_REVENUE = 10,
  RESTAURANT_BEVERAGE_REVENUE = 11,
  RESTAURANT_LIQUOR_REVENUE = 12,
}

//message ListProductsRequestUiPb {
//  optional com.staybloom.serverCommonShared.uiproto.PaginationRequestUiPb requestSummary = 1;
//  repeated ProductTypeUiPbEnum type = 2;
//}

export enum InventoryResourceEnum {
  INFINITE = 1,
  FINITE = 2,
}

export enum SupplierInfoEnum {
  SINGLE_SELF = 1,
  SINGLE_LINKED = 2,
  PACKAGE = 3,
}

export interface ProductSupplierInfo {
  productType?: SupplierInfoEnum;
  //	optional string supplierId ?: 2;
  productId?: string;
  inventoryStoreId?: string;
}

//message ListProductsRequestUiPb {
//  optional com.staybloom.serverCommonShared.uiproto.PaginationRequestUiPb requestSummary = 1;
//  repeated ProductTypeUiPbEnum type = 2;
//}

//message ListProductsResponseUiPb {
//  required com.staybloom.serverCommonShared.uiproto.SearchResultSummaryUiPb searchSummary = 1;
//  repeated ProductUiPb products = 2;
//}

export interface SellableProductsUiPb {
  productType?: ProductTypeEnum;
  baseProduct?: string; // multireferencepb?
  timeRange?: TimeRangeUiPb;
}
export interface MapRoomsViewUiPb {
  roomId?: string;
  roomName?: string;
  roomCode?: string;
  productType?: string;
  productId?: string;
}

export enum PricingModelUiEnum {
  ANY_PRICE_MODEL = 0,
  SINGLE_PRICE_MODEL = 1, //Used in addons
  MULTI_PRICE_MODEL = 2, //Used in rooms
  OVERRIDE_PRICE_MODEL = 3, //Used in expenses
  SAME_AS_LINKED_PRODUCT_PRICE_MODEL = 4,
}

export enum TaxModelUiEnum {
  ANY_TAX_MODEL = 0,
  ROOM_TAX_MODEL = 1,
  F_B_TAX_MODEL = 2,
  MOD_TAX_MODEL = 3,
  VAT_TAX_MODEL = 4,
  NO_TAX_MODEL = 5,
  CANCEL_ANY_TAX_MODEL = 6,
  CANCEL_ROOM_TAX_MODEL = 7,
  CANCEL_F_B_TAX_MODEL = 8,
  CANCEL_MOD_TAX_MODEL = 9,
  CANCEL_VAT_TAX_MODEL = 10,
  CANCEL_NO_TAX_MODEL = 11,
  F_B_2_TAX_MODEL = 12,
  CANCEL_F_B_2_TAX_MODEL = 13,

  CONF_TAX_MODEL = 30,
  CANCEL_CONF_TAX_MODEL = 31,

  //delhi addon tax structures
  DL_F_B_TAX_MODEL = 14,
  CANCEL_DL_F_B_TAX_MODEL = 15,
  DL_MOD_TAX_MODEL = 16,
  CANCEL_DL_MOD_TAX_MODEL = 17,
  DL_CONF_TAX_MODEL = 34,

  //haryana addon tax structures
  HR_F_B_TAX_MODEL = 18,
  CANCEL_HR_F_B_TAX_MODEL = 19,
  HR_MOD_TAX_MODEL = 20,
  CANCEL_HR_MOD_TAX_MODEL = 21,
  HR_CONF_TAX_MODEL = 32,

  //karnataka addon tax structures
  KR_F_B_TAX_MODEL = 22,
  CANCEL_KR_F_B_TAX_MODEL = 23,
  KR_MOD_TAX_MODEL = 24,
  CANCEL_KR_MOD_TAX_MODEL = 25,
  KR_CONF_TAX_MODEL = 35,

  //Goa addon tax structures
  GA_F_B_TAX_MODEL = 26,
  CANCEL_GA_F_B_TAX_MODEL = 27,
  GA_MOD_TAX_MODEL = 28,
  CANCEL_GA_MOD_TAX_MODEL = 29,
  GA_CONF_TAX_MODEL = 33,

  POS_TAX_MODEL = 36,
  CANCEL_POS_TAX_MODEL = 37,

  GST_05_00_MODEL_CS = 71,
  CANCEL_GST_05_00_MODEL_CS = 72,
  GST_05_00_MODEL_I = 73,
  CANCEL_GST_05_00_MODEL_I = 74,
  GST_12_00_MODEL_CS = 75,
  CANCEL_GST_12_00_MODEL_CS = 76,
  GST_12_00_MODEL_I = 77,
  CANCEL_GST_12_00_MODEL_I = 78,
  GST_18_00_MODEL_CS = 79,
  CANCEL_GST_18_00_MODEL_CS = 80,
  GST_18_00_MODEL_I = 81,
  CANCEL_GST_18_00_MODEL_I = 82,
  GST_28_00_MODEL_CS = 83,
  CANCEL_GST_28_00_MODEL_CS = 84,
  GST_28_00_MODEL_I = 85,
  CANCEL_GST_28_00_MODEL_I = 86,

  ROOM_GST_MODEL = 101,
  CANCEL_ROOM_GST_MODEL = "CANCEL_ROOM_GST_MODEL",
  F_B_GST_MODEL = 103,
  CANCEL_F_B_GST_MODEL = 104,
  LAUNDRY_GST_MODEL = 105,
  CANCEL_LAUNDRY_GST_MODEL = 106,
  CONFERENCE_GST_MODEL = 107,
  CANCEL_CONFERENCE_GST_MODEL = 108,
  EXTRA_BED_GST_MODEL = 109,
  CANCEL_EXTRA_BED_GST_MODEL = 110,
  TRANSPORT_GST_MODEL = 111,
  CANCEL_TRANSPORT_GST_MODEL = 112,
  F_B_GST_18_MODEL = 113,
  CANCEL_F_B_GST_18_MODEL = 114,

  ROOM_MISC_GST_05_MODEL = 115,
  CANCEL_ROOM_MISC_GST_05_MODEL = 116,
  ROOM_MISC_GST_12_MODEL = 117,
  CANCEL_ROOM_MISC_GST_12_MODEL = 118,
  ROOM_MISC_GST_18_MODEL = 119,
  CANCEL_ROOM_MISC_GST_18_MODEL = 120,
  ROOM_MISC_GST_28_MODEL = 121,
  CANCEL_ROOM_MISC_GST_28_MODEL = 122,

  //refer to taxV2Comment (search in all files to understand all linkage)
  CUSTOM_TAX_MODEL = 999, //use this for V2 of taxes, allows completely custom tax configuration on any product
}

//refer to taxV2Comment (search in all files to understand all linkage)
//ensure that all these enums exist in charges.uiproto -> TaxTypeUiEnum
//To use this V2 of taxes, TaxModelUiEnum above (in product.proto) should be set to CUSTOM_TAX_MODEL
//Have built a junit test to verify this at build time
export enum ProductTaxTypeUiEnum {
  PRODUCT_SERVICE_CHARGE = 900,
  PRODUCT_CGST = 901,
  PRODUCT_SGST = 902,
  PRODUCT_IGST = 903,
  PRODUCT_CESS = 904,
}

export enum TaxAppliedOnUiEnum {
  OTHER_PRICE = 1, //same as otherPrice = RACK
  BASE_PRICE = 2, //same as basePrice = BAR
  SELL_PRICE = 3, //same as priceExcludingTax = (basePrice - discounts)
}

export interface TaxSlabLimitUiPb {
  absolute?: boolean; //apply Math.abs() before checking the limit
  lowerLimit?: number; //greater than or equal to
  upperLimit?: number; //less than, because we do not want to miss out any decimal digits like one CGST slab is set to 0-999.99, next slab at 1000-75000, what happens when sell price is 99.999 ?
}

export enum TaxCalculationTypeUiEnum {
  MAXIMUM = 1,
  MINIMUM = 2,
  PERCENTAGE_FLOAT = 3,
  FIXED_AMOUNT = 4,
}

export interface TaxCalculationUiPb {
  taxCalculationType?: TaxCalculationTypeUiEnum;
  percentageFloat?: number;
  fixedAmount?: number;
}

export interface TaxToBeAppliedUiPb {
  productTaxType?: ProductTaxTypeUiEnum;
  appliedOn?: TaxAppliedOnUiEnum;

  //cascadeOn - Precondition check if these tax types exist in TaxesToBeAppliedUiPb before this element
  //cascadeOn - before checking the limit and calculation, add the casecadeOn tax computation to the OTHER/BASE/SELL _PRICE TaxAppliedOn(Enum) accordingly
  cascadeOn?: ProductTaxTypeUiEnum[];

  slabLimit?: TaxSlabLimitUiPb;
  calculation?: TaxCalculationUiPb;
  shortCode?: string; //give same short code to different taxes to club into one column in invoice and reports
  description?: string; //description for display purpose only
  glCode?: string;
}

export interface CustomTaxModelUiPb {
  name?: string;
  tax?: TaxToBeAppliedUiPb[];
}

export enum CancelModelUiEnum {
  ANY_CANCEL_MODEL = 0,
  ROOM_SINGLE_LICENSE_STAY_DATE_CANCEL_MODEL = 2,
  ADDON_ZERO_CANCEL_MODEL = 3,
  SAME_AS_LINKED_PRODUCT_CANCEL_MODEL = 4,
}

export interface ProductReferenceUiPb {
  productName?: string;
  productId?: string;
  productType?: ProductTypeUiPbEnum;
  infoType?: ExtraInfoUiType;
  productGroupId?: string;
  inventoryStore?: InventoryStoreReferenceUiPb;
  currency?: CurrencyUiEnum;
  listIndex?: number;
  productCode?: string;
  revenueHead?: RevenueHeadUiPbEnum;
  glCode?: string;
}

export interface RoomInclusionPlanUiPb {
  inclusionPlanName?: string;
  inclusionPlanCode?: string;
  //repeated InclusionUiPb inclusionsOnFirstStayDate ?: 3; TODO: leaving for V2 due to time constraint, modification and cancellation charges will be complex to handle
  inclusionsOnAllStayDates?: InclusionUiPb[];
  //repeated InclusionUiPb inclusionsOnLastStayDate ?: 5; TODO: leaving for V2 due to time constraint, modification and cancellation charges will be complex to handle
}

export interface InclusionUiPb {
  inclusionName?: string;
  revenueHead?: RevenueHeadUiPbEnum;
  glCode?: string;
  inclusionCharge?: PriceUiPb;
  inclusionChargeUnit?: InclusionUnitChargeRuleUiEnum;
}

export enum InclusionUnitChargeRuleUiEnum {
  PER_NIGHT = 1,
  PER_GUEST_PER_NIGHT = 2,
  PER_ADDITIONAL_GUEST_PER_NIGHT = 3,
}

export enum ExtraInfoUiType {
  NOTES = "NOTES",
  AIRPORT_PICKDROP = "AIRPORT_PICKDROP",
  CONFERENCE_ROOM = "CONFERENCE_ROOM",
  POS_BILL = "POS_BILL",
  EXTRA_TIME_CHARGE = "EXTRA_TIME_CHARGE",
  ROOM_INFO = "ROOM_INFO",
}

export interface ExtraInfoNotesUiPb {
  notes?: string;
  externalNote?: string;
}

export interface ExtraInfoForAirportPickDropUiPb {
  time?: TimeUiPb;
  flightNo?: string;
  airline?: string;
  notes?: string;
}

export interface ExtraInfoForConferenceRoomUiPb {
  time?: TimeUiPb;
  pax?: number;
  hours?: number;
  notes?: string;
}

export interface ExtraInfoForPOSBillUiPb {
  billId?: string;
  pointOfSale?: string;
  roomCode?: string;
  guestId?: string;
  guestName?: string;
  postedBy?: string;
  cancelledBy?: string;
  notes?: string;
}

export interface ExtraInfoForExtraTimeChargeUiPb {
  time?: TimeUiPb;
  room?: string;
  notes?: string;
}

export interface ExtraInfoForRoomInfoUiPb {
  notes?: string;
  externalNote?: string;
  expectedInTime?: TimeUiPb; //FormattedDateTime
  expectedOutTime?: TimeUiPb; //FormattedDateTime
  persons?: number; //everyone in the room, who is being charged for
  children?: number; //children who are not being chrged for, disjoint from persons
  infants?: number; //children basically under the age of 3, subset of children
}

export interface PriceConfigUiPb {
  mappedProductPriceConfig?: MappedProductPriceConfigUiPb;
  costPriceConfig?: CostPriceConfigUiPb;
  commissionConfig?: CommissionConfigUiPb;
  currency?: CurrencyUiEnum;
  giftConfig?: GiftConfigUiPb;
}

export interface MappedProductPriceConfigUiPb {
  mappedProductIdForPrice?: string;
}

export interface CostPriceConfigUiPb {
  costPriceModel?: CostPriceModelUiEnum;
  sellingPricePercentage?: number;
}

export enum CostPriceModelUiEnum {
  ANY_COST_PRICE_MODEL = 0,
  FIXED_PERCENTAGE_FROM_SELLING_PRICE = 1,
  MULTI_PRICE_MODEL_FOR_COST = 2,
}

export interface CommissionConfigUiPb {
  commissionConfigEnum?: CommissionConfigUiEnum;
  netRevenuePrecentage?: number;
}

export enum CommissionConfigUiEnum {
  ANY_COMMISSION_MODEL = 0,
  PERCENT_OF_NET_REVENUE_MODEL = 1,
}

export interface GiftConfigUiPb {
  giftConfigEnum?: GiftConfigUiEnum;
  commissionPrecentage?: number;
}

export enum GiftConfigUiEnum {
  ANY_GIFT_MODEL = 0,
  PERCENT_OF_COMMISSION_MODEL = 1,
}

//Rules to share allocation with channel manager
export interface AllocationRulesUiPb {
  autoSyncOn?: boolean;
  maximum?: number;
  reserved?: number;
}

//Rules for Flat Off
export interface FlatOffUiPb {
  active?: boolean;
  flatOffByStayDate?: FlatOffByStayDateUiPb[];
}

export interface FlatOffByStayDateUiPb {
  percentage?: number;
  dateRange?: TimeRangeUiPb;
}
