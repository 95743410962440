import { Charge } from "./../../price/uiproto/chargesUiProto";
import { BillInfoUiPb } from "./reservationReferenceUiProto";
import { PriceUiPb } from "./../../common/uiproto/valueUiProto";
import { TagUiPb } from "./../../sellerDbIdStore/uiproto/sellerDBIdEntityUiProto";
import { PlanRefUiPb } from "./../../uiproto/plan/planUiProto";
import { ProfilesForGiftAccountUiPb } from "./giftUiProto";
import {
  CustomerReferenceUiPb,
  UserInfoUiPb,
  BookersRepresentativeUiPb,
} from "./../../customerShared/uiproto/customerUiProto";
import { TimeRangeUiPb, TimeUiPb } from "../../common/uiproto/timeUiProto";
import {
  AccountTypeUiPb,
  BillingPolicyUiPb,
  BookingAccountClosurePolicyUiPb,
  FinalTaxInvoiceGenerationPolicyUiPb,
  PricingPolicyUiPb,
  ReservationTypeUiPb,
} from "./bookingAccountConfigUiProto";
import { GuestListInfoUiPb } from "./guestListInfoUiProto";
import { CRMUiPb } from "../../uiproto/crm/crmUiProto";
import { CancellationPolicyEnum } from "../../price/uiproto/cancellationPolicyUiProto";
import {
  BillAccountClosurePolicyUiPb,
  BillGenerationPolicyUiPb,
  BillingAccountTypeUiPb,
} from "./billingAccountUiProto";
import { InventoryStoreReferenceUiPb } from "../../uiproto/inventoryStore/inventoryStoreUiProto";
import { TrackerUiPb } from "../../common/uiproto/trackerUiProto";
import { GSTInfoUiPb } from "../../common/uiproto/gstInfoUiProto";
import { BaseProductLineState } from "./productLineStateUiProto";
import {
  ProductReferenceUiPb,
  RoomInclusionPlanUiPb,
} from "../../uiproto/product/productUiProto";
import { ExtraInfoForPLsUiPb } from "./extraInfoServiceUiProto";
import { CurrentProductLinesUsageUiPb } from "./productLineUiProto";
import { LicenseInfoForPLsUiPb } from "./licenseInfoUiProto";
import { NotificationUiPb } from "./reservationNotificationUiProto";

export enum BookingOrderState {
  NEW_ORDER = "NEW_ORDER",
  PENDING_SUBMIT = "PENDING_SUBMIT",
  PENDING_CONFIRMATION_PAYMENT = "PENDING_CONFIRMATION_PAYMENT",
  CONFIRMED = "CONFIRMED",
  CANCELLED_ORDER = "CANCELLED_ORDER",
  PROCESSING_NEW_CONFIRMED = "PROCESSING_NEW_CONFIRMED",
  PROCESSING_PENDING_SUBMITTED = "PROCESSING_PENDING_SUBMITTED",
  PROCESSING_CONFIRMATION_PAYMENT = "PROCESSING_CONFIRMATION_PAYMENT",
  PROCESSING_TENTATIVE_BLOCKED_ORDER = "PROCESSING_TENTATIVE_BLOCKED_ORDER",
  PROCESSING_TENTATIVE_BLOCKED_ORDER_CANCEL = "PROCESSING_TENTATIVE_BLOCKED_ORDER_CANCEL",
}

export enum PendingOrderBlockTypeEnum {
  NO_BLOCK_ON_PENDING_CONFIRMATION = "NO_BLOCK_ON_PENDING_CONFIRMATION",
  BLOCK_ON_PENDING_CONFIRMATION = "BLOCK_ON_PENDING_CONFIRMATION",
}

export interface PendingOrderBlockPolicyUiPb {
  blockTypeEnum?: PendingOrderBlockTypeEnum;
  activeTill?: TimeUiPb;
  expired?: boolean;
  tentativeReleaseDate?: TimeUiPb;
}

export interface NewBookingAccountInfoUiPb {
  //  optional com.staybloom.profile.uiproto.ProfileReferenceUiPb buyerAccountId = 1;
  buyerAccountId?: CustomerReferenceUiPb;
  buyerUserOwner?: UserInfoUiPb;
  accountType?: AccountTypeUiPb;
  externalBillingAccount?: string;
  newBillingAccountInfo?: NewBillingAccountInfoUiPb;
  pricingPolicy?: PricingPolicyUiPb;
  billingPolicy?: BillingPolicyUiPb;
  taxInvoiceGenerationPolicy?: FinalTaxInvoiceGenerationPolicyUiPb;
  closure?: BookingAccountClosurePolicyUiPb;
  guestListInfo?: GuestListInfoUiPb;
  profilesForGA?: ProfilesForGiftAccountUiPb;
  planRef?: PlanRefUiPb;
  tag?: TagUiPb;
  crManager?: CRMUiPb;
  label?: string;
  cancellationPolicyEnum?: CancellationPolicyEnum;
  bookersRepresentative?: BookersRepresentativeUiPb;
}

export interface NewBillingAccountInfoUiPb {
  billingAccountType?: BillingAccountTypeUiPb;
  billGenPolicy?: BillGenerationPolicyUiPb;
  closurePolicy?: BillAccountClosurePolicyUiPb;
}

export interface BookingOrderUiPb {
  orderId?: string;
  bookingAccountId?: string;
  newBookingAccountInfo?: NewBookingAccountInfoUiPb;
  bookingOrderState?: BookingOrderState;
  pendingOrderBlockPolicy?: PendingOrderBlockPolicyUiPb;
  orderConfirmationTime?: TimeUiPb;
  orderAmount?: PriceUiPb;
  products?: ProductsDiffRequestUiPb;
  cancelReservation?: boolean;
  sendConfirmationEmail?: boolean;
  isDryRun?: boolean;
  isNoBusinessLogicValidation?: boolean;
  transactionId?: string;
  reservationType?: ReservationTypeUiPb;
  inventoryStore?: InventoryStoreReferenceUiPb;
  notification?: NotificationUiPb;
  comDetail?: ComDetailUiPb;
  orderConfirmationBy?: string;
  additionalOrderInfo?: AdditionalOrderInfoUiPb;
  tracker?: TrackerUiPb[];
  mainRes?: string;
  mainResExternalInvoiceId?: string;
  invoiceComment?: string;
  gstBeneficiaryOtherThanBooker?: GSTInfoUiPb;
}

export interface ComDetailUiPb {
  OTACom?: boolean;
  staybloomCom?: boolean;
  payType?: PayTypeUiPb;
  voucherNumber?: string;
}

export enum PayTypeUiPb {
  PREPAID = "PREPAID",
  POSTPAID = "POSTPAID", //Pay at hotel
  BILL_TO_COMPANY = "BILL_TO_COMPANY",
  PART_PAYMENT = "PART_PAYMENT",
  ADHOC_CREDIT = "ADHOC_CREDIT", //Same as pay at hotel, just a different tag for corporates
  CREDIT_NOTE = "CREDIT_NOTE", //For reversals/allowances - negative entry
  PAY_BY_COMPANY = "PAY_BY_COMPANY",
}

export interface EmailListUiPb {
  emailInfo?: EmailInfoUiPb[];
}

export interface EmailInfoUiPb {
  firstName?: string;
  lastName?: string;
  email?: string;
}

export interface ProductsDiffRequestUiPb {
  changedBaseProductLines?: BaseProductLineDiffRequestUiPb[];
}

export enum ProductLinesChangeType {
  PRODUCT_LINES_CHANGE_UNMODIFIED = "PRODUCT_LINES_CHANGE_UNMODIFIED",
  PRODUCT_LINES_CHANGE_CANCELLED = "PRODUCT_LINES_CHANGE_CANCELLED",
  PRODUCT_LINES_CHANGE_MODIFIED = "PRODUCT_LINES_CHANGE_MODIFIED",
  PRODUCT_LINES_CHANGE_NEW = "PRODUCT_LINES_CHANGE_NEW",
}

export interface BaseProductLineDiffRequestUiPb {
  changeType?: ProductLinesChangeType;
  plsBPLIndex?: number;
  plsCount?: number;
  plsIndex?: number[];
  baseProductLineState?: BaseProductLineState;
  productReference?: ProductReferenceUiPb;
  timeRange?: TimeRangeUiPb;
  count?: number;
  licensesDiff?: LicenseInfoDiffForPLsUiPb;
  extraInfos?: ExtraInfoForPLsUiPb;
  currentUsages?: CurrentProductLinesUsageUiPb;
  roomInclusionPlan?: RoomInclusionPlanUiPb;
}

export interface LicenseInfoDiffForPLsUiPb {
  licenseChanges?: LicenseChangesForProductLinesUiPb;
  licenseInfos?: LicenseInfoForPLsUiPb;
  chargeInfoDiffs?: ChargeInfoDiffForPLsUiPb;
}

export enum LicenseChangeType {
  UNMODIFIED = "UNMODIFIED",
  NEW = "NEW",
  CANCELLED = "CANCELLED",
  REUSED = "REUSED",
}

export interface LicenseChangesForProductLinesUiPb {
  commonProductLineLicenseChange?: LicenseChangesForProductLineUiPb;
  individualProductLineLicenseChangef?: LicenseChangesForProductLineUiPb[];
}

export interface LicenseChangesForProductLineUiPb {
  commonChangeType?: LicenseChangeType;
  individualChangeType?: LicenseChangeType[];
}

export interface ChargeInfoDiffForPLsUiPb {
  commonProductLineDiff?: ChargeInfoDiffForPLUiPb;
  individualProductLineDiff?: ChargeInfoDiffForPLUiPb[];
}

export interface ChargeInfoDiffForPLUiPb {
  commonDiff?: ChargeInfoDiffUiPb;
  individualDiff?: ChargeInfoDiffUiPb[];
}

export enum ChargeChangeType {
  CHARGE_CHANGE_NEW = "CHARGE_CHANGE_NEW",
  CHARGE_CHANGE_MODIFIED = "CHARGE_CHANGE_MODIFIED",
  CHARGE_CHANGE_UNMODIFIED = "CHARGE_CHANGE_UNMODIFIED",
}

export interface ChargeInfoDiffUiPb {
  chargeChangeType?: ChargeChangeType;
  oldChargeIndex?: boolean;
  oldCharge?: Charge;
  newCharge?: Charge;
}

export interface OrderBillInfoUiPb {
  billIndexId?: boolean;
  billInfo?: BillInfoUiPb;
  orderAmount?: PriceUiPb;
}

export interface AdditionalOrderInfoUiPb {
  couponCode?: string;
}
