import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { AppConfig } from "src/app/_config/app.config";
import {
  Action,
  DialogLayoutEnum,
  DialogTypeEnum,
  formTypeActionEnum,
} from "src/app/_enums/ui-development/ui-development";
import { LicenseSimplified } from "src/app/_interfaces/licenseSimplifiedVersion";
import { ProfileApiService } from "src/app/_services/apis/profile-api.service";
import { DialogControlService } from "src/app/_services/dialog-control.service";
import { ProfileUiPb } from "src/app/com/staybloom/profile/uiproto/profileUiProto";
import { ListProfileIndexRequestUiPb } from "src/app/com/staybloom/profileIndex/uiproto/profileIndexQueryUiProto";

@Injectable({
  providedIn: "root",
})
export class ProfileStoreService {
  profileData: ProfileUiPb | undefined;
  constructor(
    private http: HttpClient,
    private profileApiService: ProfileApiService,
    private dialogControlService: DialogControlService
  ) {}

  async getPropertyData(id: string) {
    this.profileData = await new Promise((resolve, reject) => {
      this.profileApiService.getUserProfile(id).subscribe({
        next: (response) => {
          resolve(response);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
    return this.profileData;
  }
  getUserProfileBySpecificKeyFilter(queryParam: any) {
    return this.http
      .get<ProfileUiPb[]>(
        AppConfig.API_URL +
          AppConfig.FRON_END_API.SEARCH +
          AppConfig.FRON_END_API.PROFILE,
        {
          params: {
            query: JSON.stringify(queryParam),
          },
          headers: { apiName: "profile" },
        }
      )
      .pipe(map((ele: any) => ele?.profile));
  }
  createProfileForGuest(profileDetail: ProfileUiPb) {
    return this.http.post<ProfileUiPb>(
      AppConfig.API_URL + AppConfig.FRON_END_API.PROFILE,
      profileDetail,
      { headers: { apiName: "profile" } }
    );
  }
  updateProfileForGuest(profileDetail: ProfileUiPb) {
    console.log(profileDetail);
    return this.http
      .put<ProfileUiPb>(
        AppConfig.API_URL + AppConfig.FRON_END_API.PROFILE + profileDetail.id,
        profileDetail,
        { headers: { apiName: "profile" } }
      )
      .toPromise();
  }

  getUserProfile(id: string) {
    return this.http.get<ProfileUiPb>(
      AppConfig.API_URL + AppConfig.FRON_END_API.PROFILE + id,
      { headers: { apiName: "profile" } }
    );
  }

  async openGuestProfileDialog(id: string, license: LicenseSimplified) {
    const profile = await this.getPropertyData(id);
    let data: any = {
      type: DialogTypeEnum.PROFILETYPE,
      action: formTypeActionEnum.EDIT,
      loadChildren: Action.PROFILE,
      layout: DialogLayoutEnum.COLUMNLAYOUT,
      profileUiPb: profile,
      license: license,
    };
    return this.dialogControlService.openDialog(data);
  }

  getProfiles(queryParam: ListProfileIndexRequestUiPb) {
    return this.http.get(
      AppConfig.API_URL +
        AppConfig.FRON_END_API.SEARCH +
        AppConfig.FRON_END_API.PROFILE_WITHOUT_BACKSLASH,
      {
        params: {
          query: JSON.stringify(queryParam),
        },
        headers: { apiName: "profile" },
      }
    );
  }
}
