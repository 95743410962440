import { Injectable } from "@angular/core";
import { CountryUiEnum } from "../com/staybloom/uiproto/address/countryUiProto";
import { COUNTRIES_LIST } from "../_utils/countries";

@Injectable({
  providedIn: "root",
})
export class ObjectUtilsService {
  removeEmptyKeys(obj: any): any {
    if (!obj || typeof obj !== "object") {
      return obj;
    }

    // Recursively remove empty keys and values
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
          delete obj[key];
        } else if (typeof obj[key] === "object") {
          obj[key] = this.removeEmptyKeys(obj[key]);
          if (Object.keys(obj[key]).length === 0) {
            delete obj[key];
          }
        }
      }
    }
    return obj;
  }

  deepClone(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  mergeObjects(...objects: any[]): any {
    return Object.assign({}, ...objects);
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  getObjectValues(obj: any): any[] {
    return Object.values(obj);
  }

  isEmptyObject(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }

  flattenObject(obj: any): any {
    const result: any = {};

    function recurse(currentObj: any, currentKey: string = "") {
      for (const key in currentObj) {
        const value = currentObj[key];
        const newKey = currentKey ? `${currentKey}.${key}` : key;

        if (typeof value === "object" && value !== null) {
          recurse(value, newKey);
        } else {
          result[newKey] = value;
        }
      }
    }

    recurse(obj);

    return result;
  }

  pickKeys(obj: any, keys: string[]): any {
    const result: any = {};

    keys.forEach((key) => {
      if (obj.hasOwnProperty(key)) {
        result[key] = obj[key];
      }
    });

    return result;
  }

  omitKeys(obj: any, keys: string[]): any {
    const result: any = {};

    for (const key in obj) {
      if (!keys.includes(key)) {
        result[key] = obj[key];
      }
    }

    return result;
  }

  hasKey(obj: any, key: string): boolean {
    return obj.hasOwnProperty(key);
  }

  convertCountryPhoneList() {
    let result: any = [];
    let countryPhoneList = COUNTRIES_LIST;
    countryPhoneList.forEach((ele) => {
      let string = `${ele.country} (${ele.code})`;
      result.push({ key: string, value: ele.code });
    });
    return result;
  }

  convertEnumForList(CountryUiEnum: CountryUiEnum) {
    let result: Array<any> = [];
    console.log(CountryUiEnum);
    if (CountryUiEnum) {
      Object.entries(CountryUiEnum).forEach(([key, value]) =>
        result.push({ key: key, value: value })
      );
    }
    return result;
  }
}
