import { TimeUiPb } from "./../../common/uiproto/timeUiProto";
import { GSTInfoUiPb } from "./../../common/uiproto/gstInfoUiProto";
import { S3ObjectUiPb } from "../../s3Object/uiproto/s3ObjectUiProto";
import { IdVerificationStatusUiEnum } from "../../profile/uiproto/verificationUiProto";

export enum AuthNSystemUiPbEnum {
  SUPERROOMZ = 1,
  GOOGLE = 2,
}

export enum STATE {
  ACTIVE = 1,
  SUSPENDED = 2,
}

export interface AuthNUserUiPb {
  idSystem: AuthNSystemUiPbEnum;
  id?: string;
  email: string[];
  state?: STATE;
  isSuperOwner?: boolean;
  name?: UserNameUiPb;
  badHackIsBloomRoomsUser?: boolean;
}

export interface AuthNResponseUiPb {
  authKey?: string;
  maxAge?: number;
  acceptOnlySecure?: boolean;
}

export interface UserInfoUiPb {
  name?: UserNameUiPb;
  staybloomUser?: UserReferenceUiPb;
}

export interface UserNameUiPb {
  initial?: Initial;
  firstName?: string;
  middleName?: string;
  lastName?: string;
}

export interface UserReferenceUiPb {
  staybloomId?: string;
  name?: UserNameUiPb;
  primaryEmail?: string;
}

export interface BookersRepresentativeUiPb {
  firstName?: string;
  lastName?: string;
  phoneNo?: string;
  email?: string;
}

export interface CustomerReferenceUiPb {
  id?: string;
  customerUser?: UserReferenceUiPb;
  companyCode?: string;
  companyName?: string;
  customerType?: CustomerTypeEnum;
  sellerDbId?: string;
  gstInfo?: GSTInfoUiPb;
  address?: string;

  //Temporarily using to hold customer relationship manager ID
  //being used to pass from booker selection widget
  //and inject into booking order at new reservation screen
  tempCrmId?: string;
}

export enum CustomerTypeEnum {
  USER = "USER",
  COMPANY = "COMPANY",
  GROUP = "GROUP",
}

export enum Initial {
  MR = 1,
  MRS = 2,
  MISS = 3,
  DR = 4,
}

// used by usage.uiproto
export enum PhysicalIdentityTypeUiEnum {
  PASSPORT = "PASSPORT",
  DRIVING_LICENSE = "DRIVING_LICENSE",
  OTHER = "OTHER",
  AADHAAR = "AADHAAR",
  VOTER_ID = "VOTER_ID",
}

export enum DigitalIdentityTypeUiEnum {
  EMAIL = 1,
  MOBILE = 2,
}

export interface IdentityTypeUiPb {
  physicalIdType?: PhysicalIdentityTypeUiEnum;
  digitalIdType?: DigitalIdentityTypeUiEnum;
}

export interface IdentityValueUiPb {
  id?: string;
  // email Ids from authN should be unique.
  unique?: boolean;
  verified?: boolean;
  validFrom?: TimeUiPb;
  validTill?: TimeUiPb;
  issuingAuthority?: string;
  placeOfIssue?: string;
  front?: S3ObjectUiPb; // optional S3ObjectUiPb
  back?: S3ObjectUiPb; // optional S3ObjectUiPb
  isIdFrontPresent?: boolean; // optional boolean
  isIdBackPresent?: boolean; // optional boolean
  idVerificationStatus?: IdVerificationStatusUiEnum;
}

// export enum IdVerificationStatusFromProfileUiEnum {
//   NOT_VERIFIED = "NOT_VERIFIED",
//   VERIFIED_BY_API = "VERIFIED_BY_API",
//   VERIFIED_BY_HUMAN = "VERIFIED_BY_HUMAN",
// }

export interface IdentityUiPb {
  idType?: IdentityTypeUiPb;
  ids?: IdentityValueUiPb[];
}

export interface IdentityVerificationUiPb {
  verifiedIds?: IdentityUiPb[];
}

/**************************************************************************************************************
  
  message ListUsersRequestUiPb {
    optional com.staybloom.serverCommonShared.uiproto.PaginationRequestUiPb paginationInfo = 1;
  }
  
  message ListUsersResponseUiPb {
    required com.staybloom.serverCommonShared.uiproto.SearchResultSummaryUiPb searchSummary = 1;
    repeated AuthNUserUiPb users = 2;
  }
  
  message CustomerAccountUiPb {
    optional string id = 1;
    optional UserReferenceUiPb superUser = 3;
    optional string companyCode = 4;
    optional string companyName = 5;
    optional CustomerTypeEnum customerType = 6;
    optional string sellerDbId = 7;
  }
  
  message ListCustomerAccountsRequestUiPb {
      optional com.staybloom.serverCommonShared.uiproto.PaginationRequestUiPb paginationInfo = 1;
  }
  
  message ListCustomerAccountsResponseUiPb {
      required com.staybloom.serverCommonShared.uiproto.SearchResultSummaryUiPb searchSummary = 1;
        repeated CustomerAccountUiPb customerAccounts = 2;
  }
  
  ****/
