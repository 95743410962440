import { Injectable } from "@angular/core";
import { PropertyUiPb } from "src/app/com/staybloom/uiproto/property/propertyUiProto";
import { ApiService } from "src/app/_services/api.service";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "src/app/_config/app.config";
import { map } from "rxjs";
import { ListAccessResponseUiPb } from "src/app/com/staybloom/accessControl/uiproto/accessControlIndexUiProto";

@Injectable({
  providedIn: "root",
})
export class PropertyStoreService {
  propertyData: PropertyUiPb | undefined;

  constructor(private apiService: ApiService, private http: HttpClient) {}

  async getPropertyData(id: string) {
    this.propertyData = await new Promise((resolve, reject) => {
      this.apiService.getPropertyData(id).subscribe({
        next: (response) => {
          resolve(response);
        },
        error: (error) => {
          reject(error);
        },
      });
    });

    return this.propertyData;
  }

  getSingleRoomCleaningStatus(id: string) {
    return this.http.get(
      AppConfig.API_URL + AppConfig.FRON_END_API.ROOMS + id,
      {
        headers: { apiName: "room information" },
      }
    );
  }

  getRoomCleaningStatus(id: string, obj: any) {
    return this.http.get(AppConfig.API_URL + AppConfig.FRON_END_API.ROOMS, {
      params: {
        query: JSON.stringify(obj),
      },
      headers: { apiName: "room information" },
    });
  }

  updateRoomCleaningStatus(id: string, data: any) {
    return this.http.put(
      AppConfig.API_URL + AppConfig.FRON_END_API.ROOMS + id,
      data,
      { headers: { apiName: "room information" } }
    );
  }

  getPropertyAccessForUser(data: any) {
    return this.http.get(
      AppConfig.API_URL + AppConfig.FRON_END_API.ACCESS_EGC + data
    );
  }
}
