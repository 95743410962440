import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { DynamicFormLandingComponent } from "../dynamic-form-landing/dynamic-form-landing.component";
import { Router } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";
import { EventShareService } from "src/app/_services/event-share.service";
import { ApiService } from "src/app/_services/api.service";
import {
  SideKickInterfaceTypeUiEnum,
  SideKickStateChangeReqUiPb,
} from "src/app/_interfaces/sidekickTransaction";

@Component({
  selector: "app-update-widget",
  templateUrl: "./update-widget.component.html",
  styleUrls: ["./update-widget.component.scss"],
})
export class UpdateWidgetComponent {
  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private _eventShareService: EventShareService,
    private apiService: ApiService
  ) {}

  @ViewChild("container", { read: ViewContainerRef })
  containerRef: ViewContainerRef;

  contentTpl: any;

  widgetInfo: {
    title: string;
    description: string;
    form: any;
    formValues: any;
    widgetName?: string;
  };

  progress: boolean = false;
  isClosed: boolean = true;
  isTimer: boolean = false;
  eventCounter: number = 1;
  routerSubscribe: Subscription;
  valueChangeSubscribe: Subscription;
  enableButton: boolean = false;
  showProgress: boolean = false;
  showFailure: boolean = false;
  showSuccess: boolean = false;
  showDisconnect: boolean = false;
  isLoggedIn: boolean = false;
  loginSuccessSubscribe: Subscription;
  connectionSuccessSubscribe: Subscription;
  SideKickInterfaceTypeUiEnum = SideKickInterfaceTypeUiEnum;
  buttonEnabled: boolean = true;
  testErrorMessage: string = "";
  testErrorEnum: SideKickInterfaceTypeUiEnum;
  testConnection: boolean = false;
  sidekickTempData: any = "";

  ngOnInit() {
    if (localStorage.getItem("sidekickDevice")) {
      this.showDisconnect = true;
    }
    this.routerSubscribe = this.router.events.subscribe((value) => {
      this.cancel(true);
    });
    this.handler = function (event: any): void {
      this.cancel(event);
    };
    this.valueChangeSubscribe = this.dynamicForm?.fieldValueChanged.subscribe(
      () => {
        this.enableButton = true;
      }
    );
    this.loginSuccessSubscribe = this._eventShareService
      .getWidgetSuccessData()
      .subscribe((data: any) => {
        this.sidekickTempData = data.sideKickData;
        if (!this.showDisconnect) {
          if (data.isLoggedIn) {
            this.isLoggedIn = true;
            this._eventShareService.setWidgetConnectionData(true);
          } else if (
            data &&
            !data.isLoggedIn &&
            data.hasOwnProperty("isLoggedIn")
          ) {
            this.testErrorMessage =
              "Sidekick device failed to connect. Please check the password.";
            this.showProgress = false;
            this.showFailure = true;
            this.showSuccess = false;
            this.isLoggedIn = false;
          }
        }
      });
    this.connectionSuccessSubscribe = this._eventShareService
      .getWidgetConnected()
      .subscribe((data: any) => {
        if (!this.showDisconnect) {
          if (data.isConnected) {
            this.showProgress = false;
            this.showFailure = false;
            this.showSuccess = true;
            setTimeout(() => {
              this.showDisconnect = true;
            }, 3000);
          } else if (data && !data.isConnected) {
            this.testErrorMessage =
              "Sidekick device unreachable. Please ensure sidekick device is open and logged in.";
            this.showProgress = false;
            this.showFailure = true;
            this.showSuccess = false;
            this.isLoggedIn = false;
            this.testConnection = true;
          }
        }
      });
  }

  ngOnDestroy() {
    this.routerSubscribe?.unsubscribe();
    this.valueChangeSubscribe?.unsubscribe();
    this.loginSuccessSubscribe?.unsubscribe();
    this.connectionSuccessSubscribe?.unsubscribe();
    this._eventShareService.widgetConnected = new BehaviorSubject("");
    this._eventShareService.widgetSuccessData = new BehaviorSubject("");
  }

  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() output: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(DynamicFormLandingComponent, { static: true })
  dynamicForm: DynamicFormLandingComponent;

  cancel(event: any) {
    if (this.isClosed) {
      this.eventCounter = 1;
      this.resetForm();
      this.closeModal.emit(event);
    }
  }

  positionRelativeTo(x: number, y: number): void {
    // Set the position of the component relative to the provided coordinates (x, y)
    this.elementRef.nativeElement.style.left = `${x}px`;
    this.elementRef.nativeElement.style.top = `${y}px`;
  }

  submitConfirm() {
    let data: any;
    if (this.dynamicForm && this.widgetInfo?.form) {
      data = this.dynamicForm.onSubmit();
      data = data;
    } else {
      data = true;
    }
    if (data?.status === "VALID") {
      if (this.widgetInfo.widgetName == "SIDEKICK_SETUP") {
        this.showProgress = true;
        this.output.emit({ response: true, data: data });
        // setTimeout(() => {
        //   this.showFailure = true;
        //   this.showProgress = false;
        // }, 1000);
      } else {
        this.progress = true;
        this.output.emit({ response: true, data: data });
      }
    }
  }

  @HostListener("click")
  clicked() {
    event.stopPropagation();
  }
  @HostListener("document:click")
  handler(event: any): void {}

  isClose(event: Array<any>) {
    this.isClosed = event[2];
    this.isTimer = event[3];
    if (this.isClosed) {
      this.handler = function (data: any): void {
        if (this.eventCounter > 1) {
          this.cancel(data);
        }
        this.eventCounter += 1;
      };
    } else {
      this.eventCounter = 1;
      this.handler = function (): void {};
    }
  }

  resetForm() {
    this.widgetInfo.form?.[0]?.fields?.forEach((element: any) => {
      element.value = "";
    });
  }

  retrySidekickConnect() {
    this.resetForm();
    this.showFailure = false;
    this.showSuccess = false;
    this.showProgress = false;
  }

  disconnectDevice() {
    localStorage.removeItem("sidekickDevice");
    this._eventShareService.setWidgetConnected({
      isConnected: false,
    });
    this.showDisconnect = false;
    this.showProgress = false;
    this.showFailure = false;
    this.showSuccess = false;
  }

  pushSidekickScreen(screenName?: SideKickInterfaceTypeUiEnum) {
    if (!screenName) {
      screenName = this.testErrorEnum;
    }
    this.buttonEnabled = false;
    let sidekickData =
      JSON.parse(localStorage.getItem("sidekickDevice")) ||
      this.sidekickTempData;
    let payload: SideKickStateChangeReqUiPb = {
      sideKickEvent: [
        {
          password: sidekickData.credentials.password,
          sideKickTransaction: {
            sideKickDeviceId: sidekickData.id,
            sideKickInterfaceTypeEnum: screenName,
          },
        },
      ],
    };
    let propertyData = JSON.parse(
      sessionStorage.getItem("propertyDetailsData")
    );

    if (screenName == SideKickInterfaceTypeUiEnum.BOOKING_DEFAULT) {
      payload.sideKickEvent[0].sideKickTransaction.bookingTransactionData = {
        allowPayAtHotel: propertyData?.bookingWebsitePAH,
        bookingURL:
          propertyData?.bookingWebsiteBookUrl +
          "?checkIn=D-0&checkOut=D-1" +
          (propertyData?.bookingWebsitePAH ? "&pt=a" : "") +
          "&utm_campaign=walk-in",
      };
    }
    this.apiService.changeSidekickState(payload).subscribe({
      next: (response: any) => {
        if (response?.sideKickEvent?.[0]?.errorMessage) {
          this.testErrorMessage = response?.sideKickEvent?.[0]?.errorMessage;
          this.testErrorEnum = screenName;
          this.showFailure = true;
          this.showSuccess = false;
          this.showDisconnect = false;
          this.testConnection = true;
        } else {
          this.testConnection = false;
          this.showFailure = false;
          this.showSuccess = false;
          this.showDisconnect = true;
          let sidekickData = localStorage.getItem("sidekickDevice");
          if (this.sidekickTempData && !sidekickData)
            localStorage.setItem(
              "sidekickDevice",
              JSON.stringify(this.sidekickTempData)
            );
        }
        this.buttonEnabled = true;
      },
      error: (err: any) => {
        this.showFailure = true;
        this.showDisconnect = false;
        this.buttonEnabled = true;
        this.testConnection = true;
      },
    });
  }
}
