import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { UnitLevelLayoutComponent } from "./_layouts/unit-level-layout/unit-level-layout.component";
import { UNIT_LEVEL_LAYOUT_ROUTES } from "./_routes/unit-level-layout-routes";

const routes: Routes = [
  // Full Page Layouts
  {
    path: "",
    redirectTo: "sign-in",
    pathMatch: "full",
  },
  // Property Level Routes
  {
    path: "unit",
    component: UnitLevelLayoutComponent,
    children: UNIT_LEVEL_LAYOUT_ROUTES,
  },
  {
    path: "sign-in",
    loadChildren: () =>
      import("./_pages/login/login.module").then((m) => m.LoginModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabledBlocking",
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
