import { ListInventorySearchResponseUiPb } from "./../../../com/staybloom/inventorySearch/uiproto/inventorySearchUiProto";
import { InventoryStoreUiPb } from "src/app/com/staybloom/uiproto/inventoryStore/inventoryStoreUiProto";
import { Injectable } from "@angular/core";
import { PMS_INDEX_DB_CONSTANT } from "src/app/constants/indexDBConstant";
import { ApiService } from "src/app/_services/api.service";
import { DbCrudLayerService } from "src/app/_services/dbCrudLayer.service";
import { InventoryLineIndexUiPb } from "src/app/com/staybloom/inventoryIndex/uiproto/inventoryIndexUiProto";
import { StorageService } from "src/app/_services/storage.service";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "src/app/_config/app.config";
import { ListPlansResponseUiPb } from "src/app/com/staybloom/uiproto/plan/planIndexUiProto";

@Injectable({
  providedIn: "root",
})
export class InventoryStoreStoreService {
  constructor(
    private dbLayer: DbCrudLayerService,
    private apiService: ApiService,
    private storageService: StorageService,
    private router: Router,
    private http: HttpClient
  ) {}

  inventoryStoreData: InventoryStoreUiPb[] | undefined;
  inventoryLinesData: InventoryLineIndexUiPb[];

  async getInventoryStoreData() {
    // Checking Auth & Getting Email in return

    try {
      const authEmail = this.storageService.getCookie("authEmail");
      if (true) {
        // Call to Index DB for data through IndexDB Crud Layer
        const data = await this.dbLayer.get(
          PMS_INDEX_DB_CONSTANT.INVENTORY_STORE_CONFIG.STORE_NAME
        );
        if (data && data.length > 0) {
          this.inventoryStoreData = data;
        } else {
          this.inventoryStoreData = await new Promise((resolve, reject) => {
            this.apiService.getPropertiesList(authEmail).subscribe({
              next: (response) => {
                this.dbLayer.add(
                  PMS_INDEX_DB_CONSTANT.INVENTORY_STORE_CONFIG.STORE_NAME,
                  response.inventoryStore
                );
                resolve(response.inventoryStore);
              },
              error: (error) => {
                reject(new Error(error.message));
              },
            });
          });
        }

        return this.inventoryStoreData;
      }
      return null;
    } catch (error) {
      // console.log(error);
    }
  }

  async getInventoryLines(data: any) {
    this.inventoryLinesData = await new Promise((resolve, reject) => {
      this.apiService.getInventoryLinesData(data).subscribe({
        next: (response: ListInventorySearchResponseUiPb) =>
          resolve(
            response?.inventory?.sort(
              (a: InventoryLineIndexUiPb, b: InventoryLineIndexUiPb) => {
                return (
                  Number(
                    a.entityReference.displayName.split(" ")[
                      a.entityReference.displayName.split(" ").length - 1
                    ]
                  ) -
                  Number(
                    b.entityReference.displayName.split(" ")[
                      b.entityReference.displayName.split(" ").length - 1
                    ]
                  )
                );
              }
            )
          ),
        error: (error) => {
          reject(new Error(error.message));
        },
      });
    });
    return this.inventoryLinesData;
  }

  async validateInventoryStore(id: string) {
    if (id) {
      const data = await this.getInventoryStoreData();
      const inventory = data?.filter((val) => val.id === id)[0];
      if (!inventory) {
        return this.router.navigate(["/unit/properties"]);
      } else {
        return inventory;
      }
    }
  }

  SellerDBData(id: string) {
    const url =
      AppConfig.API_URL + AppConfig.FRON_END_API.SELLER_DB_SERVER_ID + id;
    return this.http.get(url);
  }

  getCRManagers(id: string) {
    const url = AppConfig.API_URL + AppConfig.FRON_END_API.CRMPROFILE;
    return this.http.get(url, {
      params: {
        query: `{ "sellerDBId": "${id}" }`,
      },
      headers: { apiName: "cr managers" },
    });
  }

  getPlanDetails(id: string) {
    const url = AppConfig.API_URL + AppConfig.FRON_END_API.PLANS;
    return this.http.get<ListPlansResponseUiPb>(url, {
      params: {
        query: `{"inventoryStoreId": "${id}"}`,
      },
      headers: { apiName: "plans" },
    });
  }
}
