<ng-container *ngIf="errorMessage?.length || message?.length">
  <ng-container *ngFor="let item of errorMessage; let i = index">
    <div class="error-container" *ngIf="item.message != ''">
      <div class="toast-message-error no-gap">
        <div class="snack-container">
          <div>
            <img src="/assets/icons/toaster/error.svg" />
          </div>
          <div>
            <span class="labelToast">{{ item.message }}</span>
          </div>
          <div (click)="errorMessage.splice(i, 1)" class="pointerCursor">
            <img src="/assets/icons/toaster/x-circle.svg" />
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngFor="let item of message; let i = index">
    <div class="error-container" *ngIf="item[0] != ''">
      <div
        class="toast-message-api"
        [ngClass]="{
          'gap-top': errorMessage.length != 0,
          'no-gap': errorMessage.length == 0
        }"
      >
        <div class="snack-container">
          <div>
            <img [src]="item[1]" />
          </div>
          <div>
            <span class="labelToast">{{ item[0] }}</span>
          </div>
          <div (click)="message.splice(i, 1)" class="pointerCursor">
            <img src="/assets/icons/toaster/x-circle.svg" />
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
