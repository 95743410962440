import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

@Directive({
  selector: "[appProgressButton]",
})
export class ProgressButtonDirective implements OnChanges {
  @Input("appProgressButton") isLoading: boolean;
  @Input() buttonText: string;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.updateButtonText();
    }
  }

  private updateButtonText() {
    const button = this.elementRef.nativeElement as HTMLButtonElement;
    if (this.isLoading) {
      button.innerText = "Progress...";
    } else {
      button.innerText = this.buttonText;
    }
  }
}
