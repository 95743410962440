import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "src/app/_config/app.config";
import { ProfileUiPb } from "src/app/com/staybloom/profile/uiproto/profileUiProto";

@Injectable({
  providedIn: "root",
})
export class ProfileApiService {
  constructor(private http: HttpClient) {}

  //Getting all properties list
  getUserProfile(id: string) {
    return this.http.get<ProfileUiPb>(
      AppConfig.API_URL + AppConfig.FRON_END_API.PROFILE + id,
      {
        headers: { apiName: "profile" },
      }
    );
  }
}
