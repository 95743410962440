import { environment } from "../../environments/environment";

export const AppConfig = Object.freeze({
  API_URL: environment.apiURL,

  FRON_END_API: {
    PMS_SERVER: "pmsServerSB/",
    LOGIN: "login",
    INVENTORY_STORES: "inventoryStores/",
    PROPERTIES: "properties/",
    SEARCH: "search/",
    LICENSE_GROUPS: "licenseGroups/",
    ROOMS: "rooms/",
    BILLING_ACCOUNT_BILLS: "billingAccountBills/",
    BOOKING_ACCOUNTS: "bookingAccounts/",
    FULFILLMENT: "fulfillment/",
    EXTRA_INFO_SERVICE: "extraInfoService/",
    INVENTORY_LINES: "inventoryLines",
    EXPORT: "export/",
    ROOM_OCCUPANCY_AND_RATES: "roomOccupancyAndRates/",
    PROFILE: "profile/",
    USAGE_SERVICE: "usageService/",
    ENCASED_GUEST_LEDGER_REPORT: "encasedGuestLedgerReport/",
    PRINT_RECEIPT: "printReceipt",
    BOOKING_ACCOUNT_STATE: "bookingAccountState/",
    PAYMENT_SERVICE: "paymentService/",
    PRODUCTS: "products/",
    PRICE_FUNCTION: "priceFunction/",
    PMS_ORDER: "pmsOrder/",
    UPLOAD_FILES_PROFILES: "uploadFiles?filePath=profiles/",

    INHOUSE_GUEST_SEARCH: "inHouseGuestSearch/",
    GENERATE_WEB_CHECKIN_LINK: "generateWebCheckInLink/",
    RETURN_DIFF: "returnDiff/",
    GENERATE_ONLINE_PAYMENT_LINK: "generateOnlinePaymentLink/",
    TAX_INVOICE: "taxInvoice/",
    CANCELLATION_POLICY: "cancellationPolicy",
    ACCESS_EGC: "access/egc/",

    PRINT_GUEST_REG: "printGuestRegCard/",
    AUTHENTICATED_LINK: "blootifi/sendAuthenticatedOnRampLink",
    MOVE_ADD_ON: "usageMoveService",
    EMAILINVOICE: "emailInvoice/",
    BOOKINGS: "bookings/",
    BOOKING_ORDER: "bookingOrder/",

    SOA: "soa/",
    PROFORMA_INVOICE: "proformaInvoice/exp/",
    EMAIL_SOA: "emailSoa/exp/",
    SELLERDBIDSERVER: "sellerDBIdServer/",
    SELLER_DB_SERVER_ID: "sellerDBIdServer/",
    CRMPROFILE: "crmProfile",
    PLANS: "plans",
    RESERVATIONDETAIL: "reservationDetail/",
    RESERVATIONDETAILSEARCH: "reservationDetailSearch",
    ACCOUNTINGREPORT: "accountingReports/",
    PROFILE_WITHOUT_BACKSLASH: "profile",
    INVOICES: "invoices",
    AR: "ar",
    CMORDER: "cmOrder",
    BILLING_ACCOUNT_GUEST_LEDGER_REPORTS: "billingAccountGuestLedgerReports",
    COVER_LETTER: "coverLetter",
    INVOICE_REPORT: "invoiceReport",
    SIDEKICK_DEVICE: "sideKickDevice",
    SIDEKICK_CONNECT: "sideKickDeviceLogin",
    SIDEKICK_CHECK_CONNECT: "websocket/sideKickCheckConnection",
    SIDEKICK_STATE_CHANGE: "websocket/sideKickStateChange",
    RAZORPAY_QR: "razorpay/upiQR",
    BOOKING_STATUS: "website/websiteBookingStatus",
    RAZORPAY_EDC: "razorpay/edcMachinePayment",
    CANCEL_EDC_PAYMENT: "razorpay/edcMachineCancelPayment",
  },
});
