import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  isBusy: boolean = true;
  isLoading: boolean = true;
  constructor() {}
  ngAfterViewChecked() {}
  public showLoader(status: boolean) {
    if (status) {
      this.isBusy = status;
    } else {
      this.isBusy = status;
    }
  }

  public showItemLoader(status: boolean) {
    if (status) {
      this.isLoading = status;
    } else {
      this.isLoading = status;
    }
  }
}
