import { PriceUiPb } from "./../../common/uiproto/valueUiProto";
import { CurrencyUiEnum } from "../../common/uiproto/currencyUiProto";
import { SellerDBIdReferenceUiPb } from "./../../sellerDbIdStore/uiproto/sellerDBIdEntityUiProto";
import { BillsUiPb } from "./billUiProto";
import { TimeUiPb } from "../../common/uiproto/timeUiProto";
import { FloatUiPb } from "../../common/uiproto/floatUiProto";
export enum BillingAccountScopeUiPb {
  RESERVATION = "RESERVATION",
  LONG_TERM = "LONG_TERM",
  TRANSFER = "TRANSFER",
}

export enum BillingAccountTypeUiPb {
  PREPAID_BEFORE_RESERVE = "PREPAID_BEFORE_RESERVE",
  PREPAID_BEFORE_USE = "PREPAID_BEFORE_USE",
  CREDIT_LIMIT = "CREDIT_LIMIT",
}

export enum BillGenerationPolicyUiPb {
  SEPARATE_FOR_RESERVATIONS = "SEPARATE_FOR_RESERVATIONS",
  COMBINED_MONTHLY = "COMBINED_MONTHLY",
}

export enum BillAccountClosurePolicyUiPb {
  CLOSURE_NOT_REQUIRED = "CLOSURE_NOT_REQUIRED", // In case of prepaid or reservation scope.
  MANUAL_CLOSURE = "MANUAL_CLOSURE",
}

export enum BillingAccountStateUiPb {
  BILLING_ACCOUNT_ACTIVE = "BILLING_ACCOUNT_ACTIVE",
  BILLING_ACCOUNT_SUSPENDED = "BILLING_ACCOUNT_SUSPENDED", // no-more bills and unbilled amount an.
  BILLING_ACCOUNT_CLOSED = "BILLING_ACCOUNT_CLOSED",
}

export interface BillingAccountReferenceUiPb {
  billingAccountId?: string;
  billingAccountScope?: BillingAccountScopeUiPb;
  billingAccountType?: BillingAccountTypeUiPb;
  // add fields for balance and customer reference
  sellerDBId?: SellerDBIdReferenceUiPb;
  currency?: CurrencyUiEnum;
}

export interface BillingAccountInfoUiPb {
  billingAccountScope?: BillingAccountScopeUiPb;
  billingAccountType?: BillingAccountTypeUiPb;
  billGenPolicy?: BillGenerationPolicyUiPb;
  // Add for credit limit and MONTHLY_BILL date and currency.
  balanceIncludingAdvPayment?: PriceUiPb; // Unbilled amount not included
  bills?: BillsUiPb;
  payments?: PaymentsUiPb;
  unbilled?: BillsUiPb;
  closurePolicy?: BillAccountClosurePolicyUiPb;
  accountState?: BillingAccountStateUiPb;
  balanceAgainstRevenueRealization?: PriceUiPb;
  currency?: CurrencyUiEnum;
  //  optional com.staybloom.sellerDBIdStore.uiproto.SellerDBIdReferenceUiPb sellerDBId?: 14;
}

export interface PaymentsUiPb {
  payment?: PaymentUiPb[];
  paymentIdCounter?: number; // increment and use when adding payment
  isNoBusinessLogicValidation?: boolean;
}

export interface PaymentUiPb {
  modeOfPayment?: ModeOfPaymentUiEnum;
  transactionId?: string;
  amount?: PriceUiPb;
  paymentTime?: TimeUiPb;
  id?: number;
  paymentState?: PaymentStateUiEnum;
  inventoryStoreId?: string;
  exchangeRate?: FloatUiPb;
  bookingId?: string;
  buyerId?: string;
  timesForPayment?: TimesForPaymentUiPb;
  isInternationalPayment?: boolean;
  invoiceInfo?: InvoiceInfoForPaymentUiPb;
  remarks?: string;
}

export enum ModeOfPaymentUiEnum {
  ANYMODE = "ANYMODE",
  CASH = "CASH",
  NET_BANKING = "NET_BANKING",
  CREDIT_CARD = "CREDIT_CARD",
  ONLINE = "ONLINE",
  IVR = "IVR",
  ONLINE_REFUND = "ONLINE_REFUND",
  CHEQUE_OR_DD = "CHEQUE_OR_DD",
  ETRANSFER = "ETRANSFER",
  MOTO_CREDIT_CARD = "MOTO_CREDIT_CARD",
  TRANSFER_FROM = "TRANSFER_FROM",
  TRANSFER_TO = "TRANSFER_TO",
  LOYALTY_POINTS = "LOYALTY_POINTS",
  AMEX_CARD = "AMEX_CARD",
  BTC_RECEIPT = "BTC_RECEIPT",
  COMMISSION = "COMMISSION",
  TDS = "TDS",
  "UPI" = "UPI",
}

export enum PaymentStateUiEnum {
  ACTIVE = "ACTIVE",
  VOID = "VOID",
  DORMANT = "DORMANT",
}

export interface TimesForPaymentUiPb {
  postedBy?: string;
  postedTime?: TimeUiPb;
  edit?: TimesForPaymentEditUiPb[];
  voidedBy?: string;
  voidedTime?: TimeUiPb;
}

export interface TimesForPaymentEditUiPb {
  editBy?: string;
  editTime?: TimeUiPb;
}

export interface InvoiceInfoForPaymentUiPb {
  invoiceId?: string;
  taxableValue?: PriceUiPb;
  taxAmount?: PriceUiPb;
  invoiceAmount?: PriceUiPb;
}
