import { AsyncPipe, CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatIconModule } from "@angular/material/icon";
import { MAT_SELECT_CONFIG } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import {
  NgxMaterialTimepickerModule,
  NgxMaterialTimepickerTheme,
} from "ngx-material-timepicker";
import { Subscription } from "rxjs";
import { formTypeActionEnum } from "src/app/_enums/ui-development/ui-development";
import { FormDataSimplified } from "src/app/_interfaces/formDataSimplified";
import { PipeModule } from "src/app/_pipes/pipe.module";
import { MaterialFormModule } from "src/app/_shared/material/material-form.module";
import { COUNTRIES_LIST } from "src/app/_utils/countries";
import { CountrySearchComponent } from "../country-search/country-search.component";
import {
  FieldTypeEnumSimplified,
  FieldWidthEnumSimplified,
} from "./../../_interfaces/formDataSimplified";
import { invalid } from "moment";

@Component({
  selector: "app-dynamic-form-landing",
  templateUrl: "./dynamic-form-landing.component.html",
  styleUrls: ["./dynamic-form-landing.component.scss"],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: "custom_select_box_overlay" },
    },
  ],
  imports: [
    CommonModule,
    MaterialFormModule,
    NgxMaterialTimepickerModule,
    MatIconModule,
    CountrySearchComponent,
    PipeModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    AsyncPipe,
    PipeModule,
  ],
})
export class DynamicFormLandingComponent implements OnChanges {
  @ViewChild("formRef")
  formRef: FormGroupDirective;
  firstInvalidControl: any;
  @Input() sectionClass: string;

  @ViewChild("dynamicform") dynamicform: any;
  @Input() formSections: FormDataSimplified[];
  @Input() data: any;
  @Input() formType: string;
  @Input("showButtons") showButtons: boolean;
  formTypeActionEnum = formTypeActionEnum;
  form: FormGroup;
  FieldTypeEnumSimplified = FieldTypeEnumSimplified;
  FieldWidthEnumSimplified = FieldWidthEnumSimplified;
  @Output() dynamicFormEvent = new EventEmitter<any>();
  @Output() closeCalenderEvent = new EventEmitter<any>();
  @Input("valuesToPatch") valuesToPatch: any;

  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: "#fff",
      buttonColor: "#6b6b6b",
    },
    dial: {
      dialBackgroundColor: "#ffcc09",
    },
    clockFace: {
      clockFaceBackgroundColor: "#fafafa",
      clockHandColor: "#ffcc09",
      clockFaceTimeInactiveColor: "#6b6b6b",
    },
  };
  @Output() fieldFocus = new EventEmitter<any>();
  @Output() selectionChange = new EventEmitter<any>();
  searchValue: string;
  COUNTRIES_LIST = COUNTRIES_LIST;
  @Output() fieldValueChanged = new EventEmitter<any>();
  valueChangeSubscribe: Subscription;

  constructor(private formBuilder: FormBuilder) {}

  ngOnChanges() {
    // resetting unsaved images on form load
    this.formReset();
    this.intializeFormFields();
  }

  ngOnDestroy() {
    this.valueChangeSubscribe?.unsubscribe();
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
  }

  onDrop(event: any, field: any) {
    console.log(field);
    event.preventDefault();
    event.stopPropagation();
    console.log(event);
    this.fieldValueChanged.emit(true);
    if (event.dataTransfer.files.length > 0) {
      this.selectionImageByDragAndDrop(field, event);
    }
  }

  formReset(shouldResetAll: boolean = false) {
    if (this.formSections) {
      this.formSections?.forEach((element) => {
        if (element?.fields) {
          element.fields.forEach((fieldData) => {
            if (shouldResetAll) {
              fieldData.value = "";
            } else {
              if (
                fieldData.type !== FieldTypeEnumSimplified.SELECT &&
                fieldData.type !== FieldTypeEnumSimplified.CHECKBOX
              ) {
                fieldData.value = "";
              }
            }
          });
        }
      });
      this.form = this.formBuilder.group({});
    }
  }

  patchFormValues() {
    if (this.valuesToPatch && Object.keys(this.valuesToPatch).length) {
      for (const section of this.formSections) {
        if (section.sectionFromGroup) {
          const sectionFormGroup = this.form.get(
            section.sectionFromGroup
          ) as FormGroup;
          if (sectionFormGroup) {
            sectionFormGroup.patchValue(
              this.valuesToPatch[section?.sectionFromGroup]
            );
          }
        } else {
          for (const field of section.fields) {
            const control = this.form.get(field?.name);
            if (
              control &&
              Object.keys(this.valuesToPatch)?.includes(field?.name)
            ) {
              const patchedValue = this.valuesToPatch[field?.name];
              control.patchValue(patchedValue);
            }
          }
        }
      }
    } else {
      // this.form.reset();
    }

    this.valueChangeSubscribe = this.form?.valueChanges.subscribe(() => {
      this.fieldValueChanged.emit(true);
    });
  }

  isArray(variable: any): boolean {
    return Array.isArray(variable);
  }

  intializeFormFields() {
    if (this.isArray(this.formSections)) {
      for (const section of this.formSections) {
        for (const field of section.fields) {
          let validators: any[] = [];

          if (field?.required) {
            validators.push(Validators.required);
          }

          if (field?.pattern) {
            validators.push(Validators.pattern(field.pattern));
          }

          const controlOptions = {
            value: field?.value,
            disabled:
              this.formType === formTypeActionEnum.EDITANDCHECKIN ||
              this.formType === formTypeActionEnum.EDIT ||
              field?.disabled,
          };
          if (section?.sectionFromGroup) {
            // Create a new FormGroup for the section
            const sectionFormGroup = this.formBuilder.group({});
            // Add the section's fields to the sectionFormGroup
            for (const field of section?.fields) {
              sectionFormGroup.addControl(
                field?.name,
                this.formBuilder.control(
                  field?.value,
                  field.required ? [Validators.required] : []
                )
              );
            }
            if (
              this.formType === formTypeActionEnum.EDITANDCHECKIN ||
              this.formType === formTypeActionEnum.EDIT ||
              section.disabled
            ) {
              sectionFormGroup.disable(); // Add the disabled attribute to the FormGroup
            }
            // Add the sectionFormGroup to the main form
            this.form.addControl(section.sectionFromGroup, sectionFormGroup);
          } else {
            // Add a regular form control to the main form
            if (field?.formGroup) {
              const formGroup = this.formBuilder.group({});
              field.fields.forEach((ele) => {
                formGroup.addControl(
                  ele?.name,
                  this.formBuilder.control(
                    ele?.value,
                    ele.required ? [Validators.required] : []
                  )
                );
              });
              this.form.addControl(field.name, formGroup);
            } else {
              this.form.addControl(
                field?.name,
                this.formBuilder.control(
                  {
                    value: controlOptions.value,
                    disabled: controlOptions?.disabled,
                  },
                  validators
                )
              );
            }
          }
        }
      }
    }

    this.patchFormValues();
  }

  isTextAreaAvailable: boolean = false;

  onChangeHandler(event: any) {
    if (event.toUpperCase() === "OTHER") {
      this.isTextAreaAvailable = true;
    } else {
      this.isTextAreaAvailable = false;
    }
  }

  onSubmit() {
    this.dynamicFormEvent.emit(this.form);
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      setTimeout(() => {
        this.scrollToFirstError();
      }, 0);
    }
    return this.form;
  }

  onFieldFocus(field: any) {
    field.value = this.form.value[field.name];
    this.fieldFocus.emit(field);
  }

  onSelectionChange(field: any, $event: any) {
    if (field.type == "file") {
      console.log(field);
      this.selectionImage(field, $event);
    } else {
      if (field.type == FieldTypeEnumSimplified.COUNTRY_CODE) {
        this.form?.get(field.name)?.setErrors(null);
        this.form?.get(field.name)?.clearValidators();
        this.form?.get(field.name)?.updateValueAndValidity();
      }
      this.selectionChange.emit({ field, $event });
    }
  }

  selectionImage(field: any, $event: any) {
    if ($event.target.files && $event.target.files[0]) {
      const maxSizeKB = 500;
      if ($event.target.files[0].size > maxSizeKB * 1024) {
        throw new Error("File size exceeds 500 KB limit.", { cause: true }); // Throw an error if file size is too large
      } else {
        this.selectionChange.emit({ field, $event });
      }

      var reader = new FileReader();
      reader.readAsDataURL($event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        let control = this.findFormControl(field.name);
        control.patchValue(event.target.result);
        field.value = event.target.result;
      };
    }
  }

  selectionImageByDragAndDrop(field: any, $event: any) {
    if ($event.dataTransfer.files && $event.dataTransfer.files[0]) {
      const maxSizeKB = 500;
      if ($event.dataTransfer.files[0].size > maxSizeKB * 1024) {
        throw new Error("File size exceeds 500 KB limit.", { cause: true }); // Throw an error if file size is too large
      } else {
        this.selectionChange.emit({ field, $event, type: "dragAndDrop" });
      }

      var reader = new FileReader();
      reader.readAsDataURL($event.dataTransfer.files[0]); // read file as data url
      reader.onload = (event) => {
        let control = this.findFormControl(field.name);
        control.patchValue(event.target.result);
        field.value = event.target.result;
      };
    }
  }

  scrollToFirstError() {
    const firstInvalidControl = document.querySelector("input.ng-invalid");
    if (firstInvalidControl) {
      const parentWithScrollbar =
        this.findParentWithScrollbar(firstInvalidControl);

      if (parentWithScrollbar) {
        const firstInvalidControlRect =
          firstInvalidControl.getBoundingClientRect();
        const parentRect = parentWithScrollbar.getBoundingClientRect();
        const offsetTop =
          firstInvalidControlRect.top -
          parentRect.top +
          parentWithScrollbar.scrollTop;

        parentWithScrollbar.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    }
  }

  findParentWithScrollbar(element: any) {
    while (element.parentElement) {
      const style = getComputedStyle(element.parentElement);
      if (style.overflowY === "auto" || style.overflowY === "scroll") {
        return element.parentElement;
      }
      element = element.parentElement;
    }
    return null;
  }
  status() {
    return this.form;
  }
  findFormControl(
    fieldName: string,
    obj: any = this.form.value
  ): FormControl | null {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (key === fieldName) {
          return new FormControl(value);
        } else if (typeof value === "object" && value !== null) {
          const foundControl = this.findFormControl(fieldName, value);
          if (foundControl) {
            return foundControl;
          }
        }
      }
    }
    return null;
  }

  getData(field: any) {
    if (this.findFormControl(field.name)?.value) {
      return this.findFormControl(field.name)?.value;
    } else {
      return field.imagePlaceHolder;
    }
  }

  closeModal(field: any, event: any, value: boolean, isTime: boolean = false) {
    this.closeCalenderEvent.emit([field, event, value, isTime]);
  }

  searchEventHandle(event: Event) {
    this.searchValue = (event.target as HTMLInputElement).value;
  }

  focusOnSelect() {
    document.getElementById("searchInput").focus();
  }
  displayNameAutoComplete(data: any) {
    return data?.key;
  }

  checkIfRangeSelected(fieldName: string, parentField: string) {
    if (!this.form?.get(parentField)?.get(fieldName)?.value) {
      this.form
        ?.get(parentField)
        ?.get(fieldName)
        ?.setErrors({ required: true });
    } else {
      this.form?.get(parentField)?.get(fieldName)?.setErrors(null);
      this.form?.get(parentField)?.get(fieldName)?.clearValidators();
      this.form?.get(parentField)?.get(fieldName)?.updateValueAndValidity();
    }
  }
}
