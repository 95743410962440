import { Component, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { PageServiceService } from "src/app/_services/page-service.service";
import { Location, PlatformLocation } from "@angular/common";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  currentHotelInventoryId: string;
  inventoryListForSelectedHotels: string[];
  calenderViewActivatedForInv: boolean;
  currentUrl: string = "";

  constructor(
    private renderer: Renderer2,
    private pageService: PageServiceService,
    private location: PlatformLocation,
    private router: Router
  ) {}

  ngAfterViewInit() {
    this.router.events.subscribe((route: any) => {
      if (route.url) {
        this.currentUrl = route.url;
      }
    });
  }

  public FoNavigationMenu = [
    {
      path: "/unit/dashboard/movement",
      icon: "/assets/icons/Dashboard_icon.svg",
      label: "Dash",
      isOpen: true,
      children: [
        {
          label: "Overview",
          path: "/unit/dashboard/overview",
          disable: true,
        },
        {
          label: "Movement",
          path: `/unit/dashboard/movement`,
        },
        {
          label: "Calendar View",
          path: "/unit/dashboard/calender-view",
          disable: false,
        },
        {
          label: "Upcoming",
          path: "",
          disable: true,
        },
        {
          label: "Room List",
          disable: true,
          path: "",
        },
        {
          label: "Occupancy",
          path: "/unit/dashboard/occupancy",
        },
        {
          label: "Flash Report",
          path: "",
          disable: true,
        },
        {
          label: "Performance",
          path: "",
          disable: true,
        },
      ],
    },
    {
      path: "/unit/book",
      icon: "/assets/icons/New_Reservation_icon.svg",
      isOpen: false,
      label: "Book",
      children: [
        {
          label: "Confirmed",
          path: "",
          disable: true,
        },
        {
          label: "Tentative",
          path: "",
          disable: true,
        },
        {
          label: "House Use",
          path: "",
          disable: true,
        },
        {
          label: "Complimentary",
          path: "",
          disable: true,
        },
        {
          label: "Out of Order",
          path: "",
          disable: true,
        },
      ],
    },
    {
      path: "/unit/search/room-booking",
      icon: "/assets/icons/Search_icon.svg",
      label: "Search",
      isOpen: false,
      children: [
        {
          label: "Stay Search",
          path: "/unit/search/room-booking",
          disable: false,
        },
        {
          label: "Reservations",
          path: "/unit/search/reservation-search",
          disable: false,
        },
        {
          label: "Guest Ledger",
          path: "/unit/search/guest-ledger-search",
          disable: false,
        },
        {
          label: "POS Bills",
          path: "/unit/search/cafe-bills-search",
          disable: false,
        },
        {
          label: "Add-on Bills",
          path: "/unit/search/addon-bills-search",
          disable: false,
        },

        {
          label: "Payments",
          path: "/unit/search/payment-search",
          disable: false,
        },

        {
          label: "Invoice",
          path: "/unit/search/invoice-search",
          disable: false,
        },

        {
          label: "OTA Vouchers",
          path: "/unit/search/ota-vouchers-search",
          disable: false,
        },

        {
          label: "Out of Order",
          disable: false,
          path: "/unit/search/out-of-order-search",
        },

        {
          label: "Tentative",
          path: "/unit/search/tentative-search",
          disable: false,
        },

        {
          label: "Allocation",
          path: "",
          disable: true,
        },
      ],
    },
    {
      path: "/unit/action/assign-rooms",
      icon: "/assets/icons/Assign_Rooms_icon.svg",
      label: "Action",
      isOpen: false,
      children: [
        {
          label: "Assign Rooms",
          path: "/unit/action/assign-rooms",
        },
        {
          label: "Bulk Room Edit",
          path: "",
          disable: true,
        },
        {
          label: "Bulk Payment",
          path: "/unit/action/bulk-payment",
        },
        {
          label: "Bulk Proforma",
          path: "/unit/action/bulk-proforma",
        },
        {
          label: "Cover Letter",
          path: "/unit/action/cover-letter",
        },
        // {
        //   label: "Reservation",
        //   path: "/unit/action/reservation",
        // },
      ],
    },
    {
      path: "/unit/profile",
      icon: "/assets/icons/Profiles_icon.svg",
      label: "Profile",
      isOpen: false,
      children: [
        {
          label: "Individual",
          path: "",
          disable: true,
        },
        {
          label: "Establishment",
          path: "",
          disable: true,
        },
        {
          label: "CR Manager",
          path: "",
          disable: true,
        },
      ],
    },
    {
      path: "/unit/reports",
      icon: "/assets/icons/Reports_icon.svg",
      label: "Reports",
      isOpen: true,
      children: [
        {
          label: "Room / Add-on",
          path: "",
          disable: true,
        },
        {
          label: "Guest Knowledge",
          path: "",
          disable: true,
        },
        {
          label: "Housekeeping",
          path: "",
          disable: true,
        },
        {
          label: "Guest Ledger",
          path: "",
          disable: true,
        },
        {
          label: "Flash",
          path: "",
          disable: true,
        },
        {
          label: "Performance",
          path: "",
          disable: true,
        },
        {
          label: "Accounting",
          path: "",
          disable: true,
        },
        {
          label: "Revenue",
          path: "",
          disable: true,
        },
        {
          label: "Operations",
          path: "/unit/reports/operations",
          disable: false,
        },
      ],
    },
    {
      path: "/unit/sheets",
      icon: "/assets/icons/file-text.svg",
      label: "Sheets",
      isOpen: false,
      children: [],
    },
    {
      path: "/unit/iot",
      icon: "/assets/icons/Vendors_Wifi.svg",
      label: "IoT",
      isOpen: false,
      children: [
        {
          label: "Wi-Fi",
          path: "",
          disable: true,
        },
        {
          label: "Locks",
          path: "",
          disable: true,
        },
      ],
    },
    {
      path: "/unit/configure",
      icon: "/assets/icons/Configure.svg",
      label: "Configure",
      isOpen: false,
      children: [
        {
          label: "Access",
          path: "",
          disable: true,
        },
        {
          label: "Property",
          path: "",
          disable: true,
        },
        {
          label: "Calender",
          path: "",
          disable: true,
        },
        {
          label: "Room Type",
          path: "",
          disable: true,
        },
        {
          label: "Rooms",
          path: "",
          disable: true,
        },
        {
          label: "Add-ons",
          path: "",
          disable: true,
        },
        {
          label: "Expenses",
          path: "",
          disable: true,
        },
        {
          label: "Rates",
          path: "",
          disable: true,
        },
        {
          label: "Promotion",
          path: "",
          disable: true,
        },
        {
          label: "Billing Account",
          path: "",
          disable: true,
        },
        {
          label: "Rate Plan",
          path: "",
          disable: true,
        },
        {
          label: "Allocation",
          path: "",
          disable: true,
        },
      ],
    },
  ];

  ngOnInit() {
    this.pageService.getCurrentHotelInventoryId().subscribe((value) => {
      this.currentHotelInventoryId = value;
      let inventoryListForSelectedHotels = [
        "1248331",
        "3601",
        "1556871",
        "1536801",
        "2056485",
      ];
      this.calenderViewActivatedForInv =
        inventoryListForSelectedHotels.includes(this.currentHotelInventoryId);
      if (
        this.location.hostname == "qa-system" ||
        this.location.hostname == "localhost"
      ) {
        this.calenderViewActivatedForInv = true;
      }
    });
  }

  mouseenter(nav_element: any) {
    this.renderer.addClass(nav_element, "active");
    const parentElement = nav_element.parentElement.parentElement;
    const siblingElements = parentElement.children;
    for (let i = 0; i < siblingElements.length; i++) {
      const siblingElement = siblingElements[i];
      if (siblingElement.children[0].classList.contains("active")) {
        this.renderer.removeClass(siblingElement.children[0], "active");
      }
    }
    this.renderer.addClass(nav_element, "active");
  }

  calenderViewCondition() {}

  mouseleave() {
    const parentElement: any = document.querySelector(".top_level_menu");
    const siblingElements = parentElement.children;
    for (let i = 0; i < siblingElements.length; i++) {
      const siblingElement = siblingElements[i];
      setTimeout(() => {
        this.renderer.removeClass(siblingElement.children[0], "active");
      }, 300);
    }
  }
  showDropDown(label: String) {
    this.FoNavigationMenu.filter((route) => {
      if (route.label === label) {
        route.isOpen = true;
      } else {
        route.isOpen = false;
      }
    });
  }
}
