import { EventTimingsUiPb } from "./../../common/uiproto/timeUiProto";

export interface ProductLineDeliveryUiPb {
  deliveryStatus?: DeliveryStatusUi;
  deliveryTime?: DeliveryTimingsUiPb;
  deliveryIndex?: DeliveredLicenseIndexUiPb;
  deliveryStartBy?: string; //Record who made the check-in happen
  deliveryEndBy?: string; //Record who made the check-out happen
  reWriteDeliveryTimes?: boolean;
}

export enum DeliveryStatusUi {
  DELIVERY_NOT_REQUESTED = "DELIVERY_NOT_REQUESTED",
  DELIVERY_STARTED = "DELIVERY_STARTED",
  DELIVERY_STOPPED = "DELIVERY_STOPPED",
  DELIVERED = "DELIVERED",
  DELIVERY_EXPIRED = "DELIVERY_EXPIRED",
}

export interface DeliveryTimingsUiPb {
  startTime?: EventTimingsUiPb;
  endTime?: EventTimingsUiPb;
}

export interface DeliveredLicenseIndexUiPb {
  firstLicense?: number; // Index of the first license consumed in a product line
  lastLicense?: number; // Index of the first license consumed in a product line
}
