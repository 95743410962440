export const WIDGET_NAMES_CONSTANT = {
  INVOICE_COMMENT: "INVOICE_COMMENT_WIDGET",
  GST_INFORMATION: "GST_INFORMATION_WIDGET",
  ROOM_EXTRA_NOTES: "ROOM_EXTRA_NOTES_INFO",
  BOOKING_DETAIL_UPDATE: "BOOKING_DETAIL_UPDATE_WIDGET",
  CONFERENCE_ROOM_EXTRA_INFO: "CONFERENCE_ROOM_EXTRA_INFO",
  ONLY_NOTES_EXTRA_INFO: "ONLY_NOTES_EXTRA_INFO",
  AIRPORT_PICKUP_DROP_INFO_WIDGET: "AIRPORT_PICKUP_DROP_INFO_WIDGET",
  EXTRA_TIME_CHARGE_WIDGET: "EXTRA_TIME_CHARGE_WIDGET",
  BOOKER_PROFILE_SWITCH: "BOOKER_PROFILE_SWITCH",
  SIDEKICK_SETUP: "SIDEKICK_SETUP",
};
