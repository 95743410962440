import { GSTInfoUiPb } from "../../common/uiproto/gstInfoUiProto";
import { TimeUiPb } from "../../common/uiproto/timeUiProto";
import { PriceUiPb } from "../../common/uiproto/valueUiProto";
import { CustomerReferenceUiPb } from "../../customerShared/uiproto/customerUiProto";
import { BillingAccountScopeUiPb } from "../../reservation/uiproto/billingAccountUiProto";
import {
  BookingAccountStateUiPb,
  BookingAccountUiPb,
  ProductsUiPb,
} from "../../reservation/uiproto/bookingAccountUiProto";
import { PayTypeUiPb } from "../../reservation/uiproto/bookingServiceUiProto";
import { TagUiPb } from "../../sellerDbIdStore/uiproto/sellerDBIdEntityUiProto";
import { BillingReportsRequestUiPb } from "./billingReportsUiProto";

export interface EncasedGuestLedgerRequestUiPb {
  billingReportsRequest?: BillingReportsRequestUiPb;
  filterType?: EncasedBillingReportFilterType;
  downloadCsv?: boolean;
  sellerDbId?: string;
}

export enum EncasedBillingReportFilterType {
  IN_DP_RECEIVABLE = "IN_DP_RECEIVABLE",
  OUT_DP_RECEIVABLE = "OUT_DP_RECEIVABLE",
  OUT_DP_PAYABLE = "OUT_DP_PAYABLE",
  OUT_DP_RECEIVABLE_TODAY = "OUT_DP_RECEIVABLE_TODAY", //T-0 out dp receivabl,
  OUT_NOT_INVOICED = "OUT_NOT_INVOICED",
  OUT_BTC_RECEIVABLE_TODAY = "OUT_BTC_RECEIVABLE_TODAY",
  ALL = "ALL",
}

export interface EncasedGuestLedgerResponseUiPb {
  encasedBill?: EncasedBillingReportUiPb[];
}

export interface EncasedGuestLedgerMasterChildListUiPb {
  master?: EncasedBillingReportUiPb;
  children?: EncasedBillingReportUiPb[];
  filtersMatchedForThisBill?: EncasedBillingReportFilterType[];
}

export interface EncasedBillingReportUiPb {
  rowType?: EncasedBillingReportRowType;
  bookingId?: string;
  companyName?: string;
  customerName?: string;
  billingAccountId?: string;
  accountScope?: BillingAccountScopeUiPb;
  invoiceGenerated?: boolean;
  payType?: PayTypeUiPb;
  earliestCheckInDate?: TimeUiPb;
  latestCheckOutDate?: TimeUiPb;
  rooms?: string[];
  encasedStatus?: string[];
  guests?: string[];
  manager?: string;
  invoiceId?: string;
  invoiceValue?: PriceUiPb;
  paid?: PriceUiPb;
  totalOutstanding?: PriceUiPb;
  propertyCode?: string;
  inventoryStoreId?: string;
  propertyName?: string;
  tag?: TagUiPb;
  products?: ProductsUiPb;
  buyerAccountId?: CustomerReferenceUiPb;
  state?: BookingAccountStateUiPb;
  gstBeneficiaryOtherThanBooker?: GSTInfoUiPb;
  invoiceComment?: string;
}

//message EncasedReservationDetailListUiPb {
//    repeated EncasedReservationDetailUiPb encasedReservation = 1;
//}

export interface EncasedReservationDetailUiPb {
  masterReservationDetail?: BookingAccountUiPb;
  addonBillReservationDetails?: BookingAccountUiPb[];
  filtersMatchedForThisBill?: EncasedBillingReportFilterType[];
}

export enum EncasedBillingReportRowType {
  MASTER_BILL = "MASTER_BILL",
  ADD_ON_BILL = "ADD_ON_BILL",
}

export interface EncasedGuestLedgerBookingListUiPb {
  addonBillReservationDetails?: BookingAccountUiPb[];
}
