import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "src/app/_config/app.config";
import { ApiService } from "src/app/_services/api.service";
import { TaxInvoiceEmailRequestUiPb } from "src/app/com/staybloom/invoice/uiproto/taxInvoiceEmailRequestUiProto";
import { BookingAccountUiPb } from "src/app/com/staybloom/reservation/uiproto/bookingAccountUiProto";
import { ExtraInfoForBookingAccountUiPb } from "src/app/com/staybloom/reservation/uiproto/extraInfoServiceUiProto";
import { ReturnForBookingAccountDiffUiPb } from "src/app/com/staybloom/reservation/uiproto/returnUiProto";

@Injectable({
  providedIn: "root",
})
export class BookingAccountStoreService {
  constructor(private apiService: ApiService, private _http: HttpClient) {}

  bookingAccount: BookingAccountUiPb | undefined;

  async getBookingAccountDetail(id: string | undefined) {
    this.bookingAccount = await new Promise((resolve, reject) => {
      this.apiService.getBookingAccountData(id).subscribe({
        next: (response) => {
          resolve(response);
        },
        error: (error) => {
          // this.snack.openSnackBar("Error while fetching booking details...");
          reject(error);
        },
      });
    });

    return this.bookingAccount;
  }

  getBookingAccountState(id: any) {
    const url =
      AppConfig.API_URL + AppConfig.FRON_END_API.BOOKING_ACCOUNT_STATE + id;
    return this._http.get(url, { headers: { apiName: "booking state" } });
  }

  updateBookingAccountState(data: any) {
    const url =
      AppConfig.API_URL +
      AppConfig.FRON_END_API.BOOKING_ACCOUNT_STATE +
      data.id;
    return this._http.put(url, data, { headers: { apiName: "booking state" } });
  }

  getBookingAccountData(id: string) {
    const url =
      AppConfig.API_URL + AppConfig.FRON_END_API.BOOKING_ACCOUNTS + id;
    return this._http.get(url, { headers: { apiName: "booking information" } });
  }

  UpdateBookingAccountData(data: BookingAccountUiPb) {
    const url =
      AppConfig.API_URL + AppConfig.FRON_END_API.BOOKING_ACCOUNTS + data.id;
    return this._http.put(url, data, {
      headers: { apiName: "booking information" },
    });
  }

  getExtraInfoService(id: string) {
    const url =
      AppConfig.API_URL + AppConfig.FRON_END_API.EXTRA_INFO_SERVICE + id;
    return this._http.get(url, { headers: { apiName: "info notes" } });
  }

  UpdateExtraInfoService(id: string, data: ExtraInfoForBookingAccountUiPb) {
    const url =
      AppConfig.API_URL + AppConfig.FRON_END_API.EXTRA_INFO_SERVICE + id;
    return this._http.put(url, data, { headers: { apiName: "info notes" } });
  }

  getReturnDifference(id: string) {
    const url = AppConfig.API_URL + AppConfig.FRON_END_API.RETURN_DIFF + id;
    return this._http.get(url, { headers: { apiName: "inventory state" } });
  }

  updateReturnDifference(id: string, data: ReturnForBookingAccountDiffUiPb) {
    const url = AppConfig.API_URL + AppConfig.FRON_END_API.RETURN_DIFF + id;
    return this._http.put(url, data, {
      headers: { apiName: "inventory state" },
    });
  }

  // Email Invoice

  emailBookingInvoice(data: TaxInvoiceEmailRequestUiPb) {
    const url =
      AppConfig.API_URL +
      AppConfig.FRON_END_API.EMAILINVOICE +
      encodeURIComponent(JSON.stringify(data));
    return this._http.get(url, { headers: { apiName: "Email Invoice" } });
  }

  getBookingAccRef(id: string) {
    const url = AppConfig.API_URL + AppConfig.FRON_END_API.SOA + id;
    return this._http.get(url, { headers: { apiName: "booking reference" } });
  }

  downloadInvoicePdf(id: string, selectedBills: any) {
    const url =
      AppConfig.API_URL + AppConfig.FRON_END_API.PROFORMA_INVOICE + id;
    return this._http.post(url, selectedBills, {
      responseType: "arraybuffer",
      headers: {
        apiName: "summary of invoices",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  downloadBillStatement(id: string, selectedBills: any) {
    const url = AppConfig.API_URL + AppConfig.FRON_END_API.SOA + "exp/" + id;
    return this._http.post(url, selectedBills, {
      responseType: "arraybuffer",
      headers: {
        apiName: "bill statement",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  emailBillStatement(id: string, selectedBills: any) {
    const url = AppConfig.API_URL + AppConfig.FRON_END_API.EMAIL_SOA + id;
    return this._http.post(url, selectedBills, {
      headers: {
        apiName: "email bill statement",
        "Content-Type": "application/x-www-form-urlencoded",
        skipError: "true",
      },
    });
  }

  downloadBillStatementWithPayment(id: string, selectedBills: any) {
    const url = AppConfig.API_URL + AppConfig.FRON_END_API.SOA + "exp/" + id;
    return this._http.get(url, {
      responseType: "arraybuffer",
      headers: {
        apiName: "bill statement",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
}
