import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
// import { NavigationEnd, Router } from "@angular/router";
import { UpdateMessage } from "src/app/_services/snackbar.service";

@Component({
  selector: "app-toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"],
})
export class ToastComponent {
  errorMessage: Array<{ message: string; type: string; time: number }> = [];
  message: Array<Array<string>> = [];
  constructor(private messageService: UpdateMessage, private router: Router) {
    // creating error toast messages
    this.messageService.currentError.subscribe(
      (errorMsg: { message: string; type: string }) => {
        if (errorMsg && errorMsg.message != "") {
          this.errorMessage.push({ ...errorMsg, time: Date.now() });
        }
      }
    );

    // creating api toast messages
    this.messageService.currentMessage.subscribe((message) => {
      if (message[0] != "") {
        this.message.push(message);
      }
    });

    // closing  api toast messages
    this.messageService.currentClose.subscribe((message) => {
      this.message = this.message.filter(
        (item: Array<string>) => item[0] !== message
      );
    });
    // closing all toast in route change
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.errorMessage = [];
        this.message = [];
      }
    });
  }
  ngOnInit() {
    this.removeErrorMessages();
  }

  removeErrorMessages() {
    // to remove error messages after 3 seconds
    setTimeout(() => {
      var time = Date.now();
      this.errorMessage = this.errorMessage.filter(function (item) {
        return time < item.time + 3000 || item.type == "http";
        // error mesages from http requests should not be removed
      });
    }, 500);
  }
}
