import { Injectable } from "@angular/core";
import { LoaderService } from "../_components/loader/loader.service";
import { StorageService } from "./storage.service";
import moment from "moment";
import Dexie from "dexie";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private storageService: StorageService,
    private loaderService: LoaderService
  ) {}
  isAuthenticated() {
    this.loaderService.showLoader(true);
    // This is the actual code snippet
    const authEmail = this.storageService.getCookie("authEmail");
    const auth = this.storageService.getCookie("auth");

    if (!auth && !authEmail) {
      let origin = window.location.origin;
      let url: any =
        origin +
        `/login/login?realm=${encodeURIComponent(
          origin
        )}&loginUri=/login/login&redirectUri=%2F`;
      document.location.href = url;
    } else {
      this.loaderService.showLoader(false);
    }
  }

  validateLoginUser() {
    const user = this.storageService.getCookie("user_stamp");
    const authEmail = this.storageService.getCookie("authEmail");

    if (!user) {
      // { email: authEmail, time: moment() } strore this in user_stamp cookie but encode first it

      this.storageService.setCookie(
        "user_stamp",
        JSON.stringify({ email: authEmail, time: moment() }),
        1
      );
    } else {
      const userObj = JSON.parse(user);
      console.log(moment().diff(moment(userObj?.time), "minutes"));
      if (moment().diff(moment(userObj?.time), "minutes") > 1440) {
        this.storageService.delete_cookie("authEmail");
        this.storageService.delete_cookie("PMS_GWT");
        this.storageService.delete_cookie("auth");
        this.storageService.delete_cookie("user_stamp");
        Dexie.delete("PMSDB");
      }
    }
  }
}
