import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpContextToken,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SnackbarService } from "../_services/snackbar.service";
import { map, catchError, throwError } from "rxjs";
import { LoaderService } from "../_components/loader/loader.service";

@Injectable()
export class ToastInterceptor implements HttpInterceptor {
  delay: boolean = true;
  toastNumber: number = 0;
  CACHE_IT = new HttpContextToken<boolean>(() => false);
  requestTime: number;
  responseTime: number;
  closeInterval: any;
  constructor(
    private snackBar: SnackbarService,
    private loader: LoaderService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let action: string = "";
    let imgSource: string = "/assets/icons/toaster/fetch_data.svg";
    if (req.method === "GET") {
      action = "Fetching ";
      imgSource = "/assets/icons/toaster/fetch_data.svg";
    } else if (req.method === "PUT") {
      action = "Updating ";
      imgSource = "/assets/icons/toaster/update_data.svg";
    } else if (req.method === "POST") {
      action = "Generating ";
      imgSource = "/assets/icons/toaster/insert_data.svg";
    } else if (req.method === "DELETE") {
      action = "Deleting ";
    }
    let nameAPI = req?.headers?.get("apiName");
    let message: string = action + `${nameAPI ? nameAPI : req.url}`;
    this.requestTime = Date.now();
    this.snackBar.openSnackBar(message, this.delay, imgSource);
    this.delay = false;
    return next.handle(req).pipe(
      map((res) => {
        if (res instanceof HttpResponse) {
          this.snackBar.closeSnackBar(message);
        }
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMsg = "";
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error - ${error.error.message}`;
        } else {
          errorMsg = `Error - ${error.status}`;
        }
        this.snackBar.closeSnackBar(message);
        let errMessage = errorMsg + " in " + `${nameAPI ? nameAPI : req.url}`;
        let skipError = req?.headers?.get("skipError");
        if (
          errMessage != "Error - 400 in profile" &&
          (!skipError || skipError == "false")
        ) {
          this.snackBar.openErrorSnackBar(errMessage, "http");
        }
        this.loader.showLoader(false);
        this.loader.showItemLoader(false);
        return throwError(errorMsg);
      })
    );
  }
}
