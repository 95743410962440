import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ProfileSimplified } from "../_interfaces/licenseSimplifiedVersion";
import { AccessUiPb } from "../com/staybloom/accessControl/uiproto/accessControlUiProto";
import { BookingAccountUiPb } from "../com/staybloom/reservation/uiproto/bookingAccountUiProto";
import { UsageForBookingAccountUiPb } from "../com/staybloom/reservation/uiproto/usageServiceUiProto";
import { EventActionEnum } from "../_enums/eventActionEnum";

@Injectable({
  providedIn: "root",
})
export class EventShareService {
  currentSelectedDate = new BehaviorSubject("");
  currentOccupancyDate = "";
  selectedGuestProfile = new BehaviorSubject<ProfileSimplified>(null);
  paymentUpdateStatus = new BehaviorSubject(false);
  widgetResponseData = new BehaviorSubject("");
  usageProductService = new BehaviorSubject(null);
  bookingAccount = new BehaviorSubject<BookingAccountUiPb>({});
  dynamicFormSelectionValue = new BehaviorSubject<any>("");
  guestCheckIn = new BehaviorSubject<any>(false);
  newBookingAccountUiPB = new BehaviorSubject<any>({});
  movementSelectedDate = "";
  messageSource = new BehaviorSubject<boolean>(false);
  refreshData = new BehaviorSubject<{
    shouldRefresh: boolean;
    type: EventActionEnum;
  }>({ shouldRefresh: false, type: EventActionEnum.NULL });

  userPropertyAccess = new BehaviorSubject<AccessUiPb>({});
  widgetSuccessData = new BehaviorSubject("");
  widgetConnectionData = new BehaviorSubject("");
  widgetConnected = new BehaviorSubject("");
  paymentStatus = new BehaviorSubject("");
  paymentEDCStatus = new BehaviorSubject("");
  refreshStatus = new BehaviorSubject("");
  refreshEDCStatus = new BehaviorSubject("");
  connectionStatus = new BehaviorSubject("");
  EDCCancelStatus = new BehaviorSubject("");

  constructor() {}

  //Date update
  setDate(value: string) {
    return this.currentSelectedDate.next(value);
  }

  getDate() {
    return this.currentSelectedDate.asObservable();
  }

  setCurrentOccupancyDate(value: string) {
    return (this.currentOccupancyDate = value);
  }

  getCurrentOccupancyDate() {
    return this.currentOccupancyDate;
  }

  //guest profile update
  setGuestProfile(profile: ProfileSimplified) {
    return this.selectedGuestProfile.next(profile);
  }
  getGuestProfile() {
    return this.selectedGuestProfile.asObservable();
  }

  // Share payment status to Update UI
  setPaymentUpdateStatus(value: boolean) {
    return this.paymentUpdateStatus.next(value);
  }

  getPaymentUpdateStatus() {
    return this.paymentUpdateStatus.asObservable();
  }

  // Share Invoice Comment Data

  setWidgetResponseData(data: any) {
    return this.widgetResponseData.next(data);
  }

  getWidgetResponseData() {
    return this.widgetResponseData.asObservable();
  }

  setUsageProductService(data: UsageForBookingAccountUiPb | boolean) {
    return this.usageProductService.next(data);
  }

  getUsageProductService() {
    return this.usageProductService.asObservable();
  }

  setBookingAccount(data: BookingAccountUiPb) {
    return this.bookingAccount.next(data);
  }

  getBookingAccount() {
    return this.bookingAccount.asObservable();
  }

  setSelectionChange(data: any) {
    return this.dynamicFormSelectionValue.next(data);
  }

  getSelectionChange() {
    return this.dynamicFormSelectionValue.asObservable();
  }

  setCheckIn(data: boolean) {
    return this.guestCheckIn.next(data);
  }
  getCheckIn() {
    return this.guestCheckIn.asObservable();
  }
  setUserPropertyAccess(data: AccessUiPb) {
    return this.userPropertyAccess.next(data);
  }
  getUserPropertyAccess() {
    return this.userPropertyAccess.asObservable();
  }

  setnewBookingAccountUiPb(data: any) {
    return this.newBookingAccountUiPB.next(data);
  }
  getnewBookingAccountUiPb() {
    return this.newBookingAccountUiPB.asObservable();
  }

  setMovementSelectedDate(date: string) {
    this.movementSelectedDate = date;
  }

  getMovementSeletedDate() {
    return this.movementSelectedDate;
  }

  refreshMovement(shouldRefresh: boolean) {
    return this.messageSource.next(shouldRefresh);
  }

  setRefreshData(shouldRefresh: boolean, type: EventActionEnum) {
    return this.refreshData.next({ shouldRefresh, type });
  }

  getRefreshData() {
    return this.refreshData.asObservable();
  }

  resetRefreshData() {
    return (this.refreshData = new BehaviorSubject<{
      shouldRefresh: boolean;
      type: EventActionEnum;
    }>({ shouldRefresh: false, type: EventActionEnum.NULL }));
  }

  setWidgetSuccessData(data: any) {
    return this.widgetSuccessData.next(data);
  }

  getWidgetSuccessData() {
    return this.widgetSuccessData.asObservable();
  }

  setWidgetConnectionData(data: any) {
    return this.widgetConnectionData.next(data);
  }

  getWidgetConnectionData() {
    return this.widgetConnectionData.asObservable();
  }

  setWidgetConnected(data: any) {
    return this.widgetConnected.next(data);
  }

  getWidgetConnected() {
    return this.widgetConnected.asObservable();
  }

  setWidgetPaymentStatus(data: any) {
    return this.paymentStatus.next(data);
  }

  getWidgetPaymentStatus() {
    return this.paymentStatus.asObservable();
  }

  setRefreshPaymentStatus(data: any) {
    return this.refreshStatus.next(data);
  }

  getRefreshPaymentStatus() {
    return this.refreshStatus.asObservable();
  }

  setConnectionPaymentStatus(data: any) {
    return this.connectionStatus.next(data);
  }

  getConnectionPaymentStatus() {
    return this.connectionStatus.asObservable();
  }

  setRefreshEDCPaymentStatus(data: any) {
    return this.refreshEDCStatus.next(data);
  }

  getRefreshEDCPaymentStatus() {
    return this.refreshEDCStatus.asObservable();
  }

  setEDCPaymentStatus(data: any) {
    return this.paymentEDCStatus.next(data);
  }

  getEDCPaymentStatus() {
    return this.paymentEDCStatus.asObservable();
  }

  setEDCCancelPayment(data: any) {
    return this.EDCCancelStatus.next(data);
  }

  getEDCCancelPayment() {
    return this.EDCCancelStatus.asObservable();
  }
}
