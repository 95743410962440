import { Routes } from "@angular/router";
import { FullPageLayoutComponent } from "../_layouts/unit-level-layout/full-page-layout/full-page-layout.component";
import { SidebarLayoutComponent } from "../_layouts/unit-level-layout/sidebar-layout/sidebar-layout.component";

export const UNIT_LEVEL_LAYOUT_ROUTES: Routes = [
  // Keeping a default Page till we add a unit level page
  {
    path: "",
    component: FullPageLayoutComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "properties",
      },
      {
        path: "properties",
        loadChildren: () =>
          import("../_pages/properties/properties.module").then(
            (m) => m.PropertiesModule
          ),
      },
    ],
  },
  {
    path: "",
    component: SidebarLayoutComponent,
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("../_pages/frontdesk/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "action",
        loadChildren: () =>
          import("../_pages/frontdesk/action/action.module").then(
            (m) => m.ActionModule
          ),
      },

      {
        path: "search",
        loadChildren: () =>
          import("../_pages/frontdesk/search/search.module").then(
            (m) => m.SearchModule
          ),
      },
      {
        path: "reports",
        loadChildren: () =>
          import("../_pages/frontdesk/reports/reports.module").then(
            (m) => m.ReportsModule
          ),
      },
    ],
  },
];
