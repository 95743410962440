import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ModuleMap } from "src/app/constants/moduleMapConstant";

@Component({
  selector: "app-universal-modal",
  templateUrl: "./universal-modal.component.html",
  styleUrls: ["./universal-modal.component.scss"],
})
export class UniversalModalComponent {
  lazyModuleConfig: any;
  constructor(
    public dialogRef: MatDialogRef<UniversalModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.loadModule();
  }

  loadModule() {
    this.lazyModuleConfig = ModuleMap[this.data.loadChildren];
  }
}
