import { GenderUiPbEnum } from "../com/staybloom/common/uiproto/genderUiProto";
import { TimeUiPb } from "../com/staybloom/common/uiproto/timeUiProto";
import { PhysicalIdentityTypeUiEnum } from "../com/staybloom/customerShared/uiproto/customerUiProto";
import { CancellationPolicyEnum } from "../com/staybloom/price/uiproto/cancellationPolicyUiProto";
import {
  BookingLicenseSnapshotUiPb,
  SnapshotAsBookerUiPb,
  SnapshotAsFoodieUiPb,
  SnapshotAsGuestUiPb,
} from "../com/staybloom/profile/uiproto/enrichmentUiProto";
import {
  IndividualProfileAttributesUiPb,
  ProfileUiPb,
} from "../com/staybloom/profile/uiproto/profileUiProto";
import { IdVerificationStatusUiEnum } from "../com/staybloom/profile/uiproto/verificationUiProto";
import { BillingAccountScopeUiPb } from "../com/staybloom/reservation/uiproto/billingAccountUiProto";
import { BookingAccountStateUiPb } from "../com/staybloom/reservation/uiproto/bookingAccountUiProto";
import { PayTypeUiPb } from "../com/staybloom/reservation/uiproto/bookingServiceUiProto";
import { DeliveryStatusUi } from "../com/staybloom/reservation/uiproto/deliveryUiProto";
import { ExtraInfoForPLUiPb } from "../com/staybloom/reservation/uiproto/extraInfoServiceUiProto";
import { BaseProductLineState } from "../com/staybloom/reservation/uiproto/productLineStateUiProto";
import {
  CheckInType,
  VerifiedUserStateUi,
} from "../com/staybloom/reservation/uiproto/usageUiProto";
import { S3ObjectUiPb } from "../com/staybloom/s3Object/uiproto/s3ObjectUiProto";
import { AddressUiPb } from "../com/staybloom/uiproto/address/addressUiProto";
import { CountryUiEnum } from "../com/staybloom/uiproto/address/countryUiProto";
import { HotelInfoSimplified } from "./bookingAccountSimplified";

export interface LicenseSimplified {
  licenseId?: string;
  billingScope?: BillingAccountScopeUiPb;
  productLinePath?: string;
  sellerDBId?: string;
  bookingId?: string;
  deliveryStatus?: DeliveryStatusUi;
  roomNo?: string;
  roomType?: string;
  multiRoomAssignment?: boolean;
  sourceTag?: string;
  booker?: ProfileSimplified;
  primaryGuest?: ProfileSimplified;
  checkInTimeAndDate?: Date;
  checkOutTimeAndDate?: Date;
  actualCheckInTimeAndDate?: Date;
  actualCheckOutTimeAndDate?: Date;
  roomInclusionPlanCode?: string;
  roomInclusionPlanName?: string;
  bookingPaymentType?: PayTypeUiPb;
  isLongTerm?: boolean;
  guestList?: ProfileSimplified[];
  guestCount?: number;
  reservationInfoNotes?: string;
  crManager?: CrManagerSimplified;
  cancellationPolicy?: CancellationPolicyEnum;
  roomId?: string;
  hotelInfo?: HotelInfoSimplified;
  extraInfo?: ExtraInfoForPLUiPb;
  notificationType?: string;
  isSoloFemaleTraveler?: boolean;
  loyaltyFilter?: string;
  soloFemaleTravelerFilter?: string;
  simplifiedPayType?: PayTypeSimplified;
  checkInVerified?: boolean;
  baseProductLineState?: BaseProductLineState;
}

export interface ProfileSimplified {
  id?: string;
  profileType?: any;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  mobile?: string;
  countryCode?: string;
  mobileNumber?: string;
  companyName?: string;
  nationality?: CountryUiEnum;
  age?: any;
  gender?: GenderUiPbEnum;
  profileImage?: string;
  stayCount?: number;
  lastStay?: BookingLicenseSnapshotUiPb;
  profilePicture?: string;
  checkInType?: CheckInType;
  deliveryStatus?: string;
  userState?: VerifiedUserStateUi;
  staybloomId?: string;
  grc?: string;
  formC?: string;
  subscriptionStatus?: SubscriptionStatusSimplied;
  identityDetails?: IdentityDetailSimplified;
  addressDetails?: AddressUiPb;
  attributes?: IndividualProfileAttributesUiPb;
  notes?: NotesSimplified;
  suspend?: boolean;
  snapshotAsBooker?: SnapshotAsBookerUiPb;
  snapshotAsGuest?: SnapshotAsGuestUiPb;
  snapshotAsFoodie?: SnapshotAsFoodieUiPb;
  profileUiPbRef?: ProfileUiPb;
  primaryGuestIndex?: number;
  profileCompletionScore?: string;
  verifiedGuestCheckedInTime?: number;
  primaryGuest?: boolean;
  idProofVerifiedBy?: string;
  idVerificationTime?: number;
  verifiedGuestCheckOutTime?: number;
  originalCheckInType?: string;
  checkInVerificationTime?: TimeUiPb;
  checkInVerified?: boolean;
}

export interface ProfileDetailSimplified {}
export interface NotesSimplified {
  freeText?: string;
  housekeepingFreeText?: string;
}

export interface SubscriptionStatusSimplied {
  emailSubscription?: boolean;
  loyaltyProgramSignup?: boolean; //enables eligiblity for Loyalty promotion
  bookersProgramSignup?: boolean;
  ownersProgramSignup?: boolean; //enables eligiblity for Owners Priviledge Card promotion
  emailBounced?: boolean;
}

export interface IdentityDetailSimplified {
  idType?: PhysicalIdentityTypeUiEnum;
  verificationId?: string;
  providingAuthority?: string;
  placeOfIssue?: string;
  nationality?: CountryUiEnum;
  dateOfIssue?: string | Date;
  validUntil?: string | Date;
  idProofFront?: string | S3ObjectUiPb;
  idProofBack?: string | S3ObjectUiPb;
  idVerificationStatus?: IdVerificationStatusUiEnum;
}

export interface CrManagerSimplified {
  id?: string;
  name?: string;
  email?: string;
  mobile?: string;
}

export interface InvoicePaymentSimiplified {
  bookingId: string;
  finalPrice: number;
  totalPayment: number;
  balanceAmount: number;
  accountType: string;
  accountStatus?: BookingAccountStateUiPb;
  addons?: AddonQuickViewSimplified[];
}

export interface AddonQuickViewSimplified {
  name: string;
  tracked: boolean;
  charge: number;
  units: number;
}

export interface MultiAssignRoomLicenseSimplified {
  bookingId: { sourceTag: string; bookingId: string; productLinePath: string };
  bookerGuest: {
    bookerName: string;
    primaryGuestName: string;
    primaryGuestStayCount: number;
  };
  roomType: {
    inclusionPlan: string;
    productName: string;
    originalRoomNoAssigned: string;
    originalRoomIdAssigned: string;
  };
  stayDuration: { checkinDate: string; checkoutDate: string };
  remarks: string;
  roomNo: string;
}

export enum PayTypeSimplified {
  PAY_AT_HOTEL = "Pay at Hotel",
  BTC = "BTC",
  PARTIAL_PAYMENT = "Partial Payment",
  ADHOC_CREDIT = "Adhoc Credit",
  CREDIT_NOTE = "Credit Note",
  PAY_BY_COMPANY = "Pay by Company",
}
