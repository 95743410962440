import { Injectable } from "@angular/core";
import moment from "moment";
import {
  AddonDetailUiSimplified,
  AddonsAndExpensesSimplified,
  BookingAccountUiSimplified,
  ChargeSimplified,
  HotelInfoSimplified,
  PayTypeEnum,
  PaymentSimplified,
  PaymentStatusSimplified,
  PaymentTotalSimplified,
  RoomSimplified,
  UnitInterfaceUiSimplified,
  addonStatusSimplifed,
  totalBookingStatusSimplified,
} from "src/app/_interfaces/bookingAccountSimplified";
import { HelperService } from "src/app/_services/helper.service";
import { GSTInfoUiPb } from "src/app/com/staybloom/common/uiproto/gstInfoUiProto";
import {
  BookingAccountUiPb,
  ProductsUiPb,
} from "src/app/com/staybloom/reservation/uiproto/bookingAccountUiProto";
import { DeliveryStatusUi } from "src/app/com/staybloom/reservation/uiproto/deliveryUiProto";
import { InventoryStoreReferenceUiPb } from "src/app/com/staybloom/uiproto/inventoryStore/inventoryStoreUiProto";
import {
  ProductTypeUiPbEnum,
  ProductUiPb,
} from "src/app/com/staybloom/uiproto/product/productUiProto";
// import { BookingAccountStoreService } from "./bookingAccountStore.service";
import { PayTypeUiPb } from "src/app/com/staybloom/reservation/uiproto/bookingServiceUiProto";
import { ChargeAndBillInfosForPLsUiPb } from "src/app/com/staybloom/reservation/uiproto/chargeAndBillUiProto";
import { BaseProductLineUiPb } from "src/app/com/staybloom/reservation/uiproto/productLineUiProto";

import { ProfileStoreTransformerService } from "../profileStore/profile-store-transformer.service";
import {
  PaymentStateUiEnum,
  PaymentsUiPb,
} from "src/app/com/staybloom/reservation/uiproto/billingAccountUiProto";
import { BaseProductLineState } from "src/app/com/staybloom/reservation/uiproto/productLineStateUiProto";
import { BookingAccountStoreService } from "./bookingAccountStore.service";
import { EncasedBillingReportRowType } from "src/app/com/staybloom/reports/uiproto/encasedGuestLedgerUiProto";
import { DbCrudLayerService } from "src/app/_services/dbCrudLayer.service";
import { PMS_INDEX_DB_CONSTANT } from "src/app/constants/indexDBConstant";

@Injectable({
  providedIn: "root",
})
export class BookingAccountTransformerService {
  currentBookingData: BookingAccountUiPb;
  allProducts: ProductUiPb[];
  constructor(
    private helperService: HelperService,
    public bookingAccountService: BookingAccountStoreService,
    public profileStoreTransformer: ProfileStoreTransformerService,
    public indexDBLayer: DbCrudLayerService,
    public _helperService: HelperService
  ) {
    this.indexDBLayer
      .get(PMS_INDEX_DB_CONSTANT.PRODUCT_DETAILS_STORE_INDEX_CONFIG.STORE_NAME)
      .then((data) => (this.allProducts = data));
  }

  async transformBookingAccountToSimplifiedVersion(data: BookingAccountUiPb) {
    let result: BookingAccountUiSimplified = {};
    const { allAddons, allPayments, paymentStatus, addonStatus } =
      this.getAddonsAndExpenses(data);

    if (data) {
      result.id = data?.id;
      result.reservationType = data?.reservationType;
      result.payType = this._helperService.getPayTypeFromBookingAccount(
        data
      ) as any;
      result.segmentTag = data?.tag?.name;
      result.primaryGuest = this.profileStoreTransformer.getPrimaryGuest(
        data.guestListInfo
      );
      result.masterGuestList = this.profileStoreTransformer.getMasterGuestList(
        data.guestListInfo.masterGuest
      );
      result.cancellationPolicy = data?.cancellationPolicyEnum;
      result.booker = this.profileStoreTransformer.getBooker(
        data?.buyerAccountId
      );
      result.bookersRepresentative = data?.bookersRepresentative
        ? this.profileStoreTransformer.getBookersRepresentativeProfile(
            data?.bookersRepresentative
          )
        : undefined;
      result.gstDetails = this.getGSTDetails(data);
      result.hotelInfo = this.getHotelInfo(data?.inventoryStore);
      result.bookingAccountState = data?.state && data?.state;
      result.addonResId = data?.addonRes && data?.addonRes;
      result.rooms = await this.getRooms(data?.products, data?.id);
      result.addons = allAddons;
      result.payments = allPayments;
      result.bookingStatus = this.getStatusCounts(result?.rooms);
      result.addonsStatus = addonStatus;
      result.paymentStatus = paymentStatus;
      result.creditNoteRes = data?.creditNoteRes;
      result.CrManager = data?.crManager;
      result.voucherNumber = data?.comDetail?.voucherNumber;
      result.mainReservationAddons = this.getReservationAddons(
        data?.products,
        data?.id
      );
      result.mainReservationPayments = data?.billingAccount
        ?.containedBillingAccount?.payments
        ? this.getMainReservationPaymentDetails(
            data?.billingAccount?.containedBillingAccount?.payments
          )
        : [];
      result.invoiceComment = data?.invoiceComment;
      result.primaryGuestIndex = data?.guestListInfo?.primaryGuestIndex;
      result.mainResId = data?.mainRes;
      result.tagId = data?.tag?.id;
      result.sellerDBId = data?.inventoryStore?.sellerDBId?.id;
    }
    console.log(result);

    return result;
  }

  async transformCurrentBookingToSimplified(data: BookingAccountUiPb) {
    let result: BookingAccountUiSimplified = {};

    console.log(data);

    if (data) {
      result.id = data?.id;
      result.reservationType = data?.reservationType;
      result.payType = this._helperService.getPayTypeFromBookingAccount(
        data
      ) as any;
      result.segmentTag = data?.tag?.name;
      result.primaryGuest = this.profileStoreTransformer.getPrimaryGuest(
        data?.guestListInfo
      );
      result.masterGuestList = this.profileStoreTransformer.getMasterGuestList(
        data?.guestListInfo?.masterGuest
      );
      result.cancellationPolicy = data?.cancellationPolicyEnum;
      result.booker = this.profileStoreTransformer.getBooker(
        data?.buyerAccountId
      );
      result.bookersRepresentative = data?.bookersRepresentative
        ? this.profileStoreTransformer.getBookersRepresentativeProfile(
            data?.bookersRepresentative
          )
        : undefined;
      result.gstDetails = this.getGSTDetails(data);
      result.hotelInfo = this.getHotelInfo(data?.inventoryStore);
      result.bookingAccountState = data?.state;
      result.addonResId = data?.addonRes && data?.addonRes;
      result.rooms = await this.getRooms(data?.products, data?.id);
      result.bookingStatus = this.getStatusCounts(result?.rooms);
      result.creditNoteRes = data?.creditNoteRes;
      result.CrManager = data?.crManager;
      result.voucherNumber = data?.comDetail?.voucherNumber;
      result.mainReservationAddons = this.getReservationAddons(
        data?.products,
        data?.id
      );
      result.mainReservationPayments = data?.billingAccount
        ?.containedBillingAccount?.payments
        ? this.getMainReservationPaymentDetails(
            data?.billingAccount?.containedBillingAccount?.payments
          )
        : [];
      result.invoiceComment = data?.invoiceComment;
      result.primaryGuestIndex = data?.guestListInfo?.primaryGuestIndex;
      result.mainResId = data?.mainRes;
      result.tagId = data?.tag?.id;
      result.sellerDBId = data?.inventoryStore?.sellerDBId?.id;
    }

    // console.log(this._helperService.getPayTypeFromBookingAccount(data));

    return result;
  }

  getGSTDetails(bookingAccount: BookingAccountUiPb) {
    let result: GSTInfoUiPb = {};
    if (bookingAccount?.gstBeneficiaryOtherThanBooker) {
      result.state = bookingAccount?.gstBeneficiaryOtherThanBooker?.state;
      result.GSTIN = bookingAccount?.gstBeneficiaryOtherThanBooker?.GSTIN;
      result.GSTBeneficiary =
        bookingAccount?.gstBeneficiaryOtherThanBooker?.GSTBeneficiary;
      result.taxExempt =
        bookingAccount?.gstBeneficiaryOtherThanBooker?.taxExempt;
      result.address1 = bookingAccount?.gstBeneficiaryOtherThanBooker?.address1;
      result.address2 = bookingAccount?.gstBeneficiaryOtherThanBooker?.address2;
      result.location = bookingAccount?.gstBeneficiaryOtherThanBooker?.location;
      result.pinCode = bookingAccount?.gstBeneficiaryOtherThanBooker?.pinCode;
    } else {
      result.state =
        bookingAccount.buyerAccountId?.gstInfo?.state &&
        bookingAccount.buyerAccountId?.gstInfo?.state;
      result.GSTIN = bookingAccount?.buyerAccountId?.gstInfo?.GSTIN;
      result.GSTBeneficiary =
        bookingAccount.buyerAccountId?.gstInfo?.GSTBeneficiary;
      result.taxExempt = bookingAccount?.buyerAccountId?.gstInfo?.taxExempt;
      result.address1 = bookingAccount?.buyerAccountId?.gstInfo?.address1;
      result.address2 = bookingAccount?.buyerAccountId?.gstInfo?.address2;
      result.location = bookingAccount?.buyerAccountId?.gstInfo?.location;
      result.pinCode = bookingAccount?.buyerAccountId?.gstInfo?.pinCode;
    }

    return result;
  }

  getHotelInfo(inventory: InventoryStoreReferenceUiPb) {
    let result: HotelInfoSimplified = {};
    let timeFormat = "HH:mm";

    result.name = inventory?.propertyRef?.fullName;
    result.standardCheckInTime = moment(
      inventory?.propertyRef?.masterCheckIn,
      timeFormat
    );
    result.standardCheckoutTime = moment(
      inventory?.propertyRef?.masterCheckOut,
      timeFormat
    );
    result.imageUrl = inventory?.propertyRef?.imageUrl;
    result.inventoryStoreId = inventory?.inventoryStoreId;
    result.propertyId = inventory?.propertyRef?.id;
    result.sellerId = inventory?.sellerDBId?.id;

    return result;
  }

  async getRooms(products: ProductsUiPb, bookingId: string) {
    let result: RoomSimplified[] = [];
    if (products && products.baseProductLines) {
      let productsArray = products?.baseProductLines;
      for (let p = 0; p < productsArray.length; p++) {
        let product = productsArray[p];
        if (
          product?.productReference?.productType === ProductTypeUiPbEnum.ROOM
        ) {
          for (let i = 0; i < product?.count; i++) {
            let productSimplified: RoomSimplified = {};
            productSimplified.productLinePath = `${bookingId}/${p}/${i}`;
            productSimplified.assignedRoomNo = this.helperService.getRoomIndex(
              product?.timeRange?.timeStart?.formattedDate,
              product?.timeRange?.timeEnd?.formattedDate,
              product.licenses.fulfillmentsForPLs.individualFulfillment[i]
                .individualFulfillment
            );
            productSimplified.checkinTimeAndDate = moment(
              product?.timeRange?.timeStart?.formattedDate
            ).toDate();
            productSimplified.checkoutTimeAndDate = moment(
              product?.timeRange?.timeEnd?.formattedDate
            )
              .add(1, "days")
              .toDate();
            (productSimplified.actualCheckinTimeAndDate =
              await this.getActualCheckInCheckOutTime(product, "checkin", i)),
              (productSimplified.actualCheckoutTimeAndDate =
                await this.getActualCheckInCheckOutTime(
                  product,
                  "checkout",
                  i
                ));
            productSimplified.roomTypeName =
              product?.productReference?.productName;
            productSimplified.roomTypeId =
              product?.licenses?.fulfillmentsForPLs.individualFulfillment[
                i
              ].individualFulfillment[0]?.roomReference?.roomId;
            productSimplified.baseProductLineState =
              product.baseProductLineState;
            productSimplified.productId = product?.productReference?.productId;
            productSimplified.productType =
              product?.productReference?.productType;
            productSimplified.inclusionPlanName =
              product?.roomInclusionPlan?.inclusionPlanName || "European Plan";
            productSimplified.inclusionPlanCode =
              product?.roomInclusionPlan?.inclusionPlanCode || "EP";
            productSimplified.extraInfo = product?.extraInfos?.commonInfo;
            productSimplified.blockedBy =
              product?.licenses?.timesForPLs?.individualTimesForPL[
                i
              ]?.individualTimes[i]?.blockedBy;
            productSimplified.blockedAt = moment(
              product?.licenses?.timesForPLs?.individualTimesForPL[i]
                ?.individualTimes[i]?.blockedTime?.milliSeconds
            ).toDate();
            productSimplified.deliveryStatus =
              product.currentUsages.individualUsageState[
                i
              ].delivery.deliveryStatus;
            productSimplified.deliveryStartedBy =
              product.currentUsages.individualUsageState[
                i
              ].delivery.deliveryStartBy;
            productSimplified.guestCount =
              product?.extraInfos?.commonInfo?.infoRoomInfo?.persons;
            result.push(productSimplified);
            productSimplified.charge = this.getRoomCharge(
              product?.licenses?.chargesForPLs,
              i
            );
            productSimplified.verifiedGuests =
              this.profileStoreTransformer.getVerifiedUsers(
                product?.currentUsages?.individualUsageState[i]?.verifiedUsers
                  ?.users
              );
            productSimplified.baseProductLineId =
              product?.licenses?.returnForPLs?.baseProductLineRef?.baseProductLineId;
          }
        }
      }
      // products?.baseProductLines.forEach((product) => {

      // });
    } else {
      result = [];
    }

    return result;
  }

  async getActualCheckInCheckOutTime(
    product: BaseProductLineUiPb,
    type: string,
    i: number
  ) {
    let allProduts = await this.indexDBLayer.get(
      PMS_INDEX_DB_CONSTANT.PRODUCT_DETAILS_STORE_INDEX_CONFIG.STORE_NAME
    );
    const currentProduct = allProduts?.find(
      (pro) => pro?.id === product?.productReference?.productId
    );
    if (type === "checkin") {
      if (
        product?.currentUsages?.individualUsageState[i]?.delivery
          ?.deliveryStatus === DeliveryStatusUi.DELIVERY_STARTED ||
        product?.currentUsages?.individualUsageState[i]?.delivery
          ?.deliveryStatus === DeliveryStatusUi.DELIVERED ||
        product?.currentUsages?.individualUsageState[i]?.delivery
          ?.deliveryStatus === DeliveryStatusUi.DELIVERY_STOPPED
      ) {
        let time = moment(
          product?.currentUsages?.individualUsageState[i]?.delivery.deliveryTime
            ?.startTime?.recordTime?.milliSeconds
        ).toDate();

        return time;
      } else {
        if (product?.extraInfos?.commonInfo?.infoRoomInfo?.expectedInTime) {
          return moment(
            product?.extraInfos?.commonInfo?.infoRoomInfo?.expectedInTime
              ?.formattedDateTime,
            "YYYYMMDD HH:mm"
          ).toDate();
        } else {
          const date = product?.timeRange?.timeStart?.formattedDate;
          // const time =
          //   product?.productReference?.inventoryStore?.propertyRef
          //     ?.masterCheckIn;

          const dateTimeStr =
            date + " " + currentProduct?.checkin?.formattedTime;
          const dateTime = moment(dateTimeStr, "YYYYMMDD HH:mm:ss").toDate();
          return dateTime;
        }
      }
    } else if (type === "checkout") {
      if (
        product?.currentUsages?.individualUsageState[i]?.delivery
          ?.deliveryStatus === DeliveryStatusUi.DELIVERY_STOPPED
      ) {
        return moment(
          product?.currentUsages?.individualUsageState[i]?.delivery.deliveryTime
            ?.endTime?.recordTime?.milliSeconds
        ).toDate();
      } else {
        if (product?.extraInfos?.commonInfo?.infoRoomInfo?.expectedOutTime) {
          return moment(
            product?.extraInfos?.commonInfo?.infoRoomInfo?.expectedOutTime
              ?.formattedDateTime,
            "YYYYMMDD HH:mm"
          ).toDate();
        } else {
          const date = product?.timeRange?.timeEnd?.formattedDate;
          // const time =
          //   product?.productReference?.inventoryStore?.propertyRef
          //     ?.masterCheckOut;

          const dateTimeStr =
            date + " " + currentProduct?.checkout?.formattedTime;
          const dateTime = moment(dateTimeStr, "YYYYMMDD HH:mm:ss")
            .add(1, "days")
            .toDate();
          return dateTime;
        }
      }
    } else {
      throw new Error("invalid type requested in License", { cause: true });
    }
  }

  getRoomCharge(charges: ChargeAndBillInfosForPLsUiPb, index: number) {
    let response: ChargeSimplified = {
      tariff: 0,
      inclusion: 0,
      commission: 0,
      charge: 0,
      tax: 0,
      totalCharge: 0,
    };

    charges?.individualChargesForPL[index]?.individualCharges.forEach(
      (charge) => {
        response.tax += Math.round(
          charge?.currentCharges?.charge?.chargeInfo?.taxes?.totalTax
            ?.priceFloat
        );
        response.totalCharge += Math.round(
          charge?.currentCharges?.charge?.chargeInfo?.finalPrice?.priceFloat
        );
        response.charge += Math.round(
          charge?.currentCharges?.charge?.chargeInfo?.basePrice?.price
            ?.priceFloat
        );
        response.tariff += Math.round(
          charge?.currentCharges?.charge?.chargeInfo?.inclusionAdjustedPrice
            ?.priceFloat
        );
        response.inclusion += Math.round(
          charge?.currentCharges?.charge?.chargeInfo?.basePrice?.price
            ?.priceFloat -
            charge?.currentCharges?.charge?.chargeInfo?.inclusionAdjustedPrice
              ?.priceFloat
        );

        if (
          charge?.currentCharges?.charge?.chargeOverride?.basePrice &&
          charge?.currentCharges?.charge?.chargeOverride?.otherPrice
        ) {
          response.commission += Math.round(
            charge?.currentCharges?.charge?.chargeOverride?.basePrice?.price
              ?.priceFloat -
              charge?.currentCharges?.charge?.chargeOverride?.otherPrice?.price
                ?.priceFloat
          );
        }
      }
    );

    return response;
  }

  getAddonsAndExpenses(data: BookingAccountUiPb) {
    const allAddons: AddonsAndExpensesSimplified[] = [];
    const allPayments: PaymentTotalSimplified[] = [];
    const paymentStatus: PaymentStatusSimplified = {
      mainBillPending: 0,
      otherBillsPending: 0,
    };
    let addonStatus: addonStatusSimplifed = {
      totalPending: 0,
      totalTracked: 0,
    };

    allAddons.push(...this.getAddon(data));
    allPayments.push(this.getPayment(data));
    paymentStatus.mainBillPending = allPayments[0].pendingAmount;
    allAddons.forEach((addon) => {
      addonStatus.totalPending +=
        Number(addon.totalUnits) - Number(addon.totalTracked);
      addonStatus.totalTracked += Number(addon.totalTracked);
    });

    this.getBookingDetailsAndExtract(
      data,
      allAddons,
      allPayments,
      paymentStatus,
      addonStatus
    );

    return { allAddons, allPayments, paymentStatus, addonStatus };
  }

  getBookingDetailsAndExtract(
    data: BookingAccountUiPb,
    allAddons: AddonsAndExpensesSimplified[],
    allPayments: PaymentTotalSimplified[],
    otherPayment: PaymentStatusSimplified,
    addonStatus: addonStatusSimplifed
  ) {
    if (data && data.addonRes) {
      this.bookingAccountService
        .getBookingAccountDetail(data.addonRes)
        .then((res) => {
          const newAddonStatus = { ...addonStatus }; // Create a new object
          allAddons.push(...this.getAddon(res));
          allPayments.push(this.getPayment(res));
          otherPayment.otherBillsPending += this.getPayment(res).pendingAmount;
          allAddons.forEach((addon) => {
            newAddonStatus.totalPending +=
              Number(addon.totalUnits) - Number(addon.totalTracked);
            newAddonStatus.totalTracked += Number(addon.totalTracked);
          });

          this.getBookingDetailsAndExtract(
            res,
            allAddons,
            allPayments,
            otherPayment,
            newAddonStatus // Pass the new object to the recursive call
          );
        });
    } else {
      let totalPayment: PaymentTotalSimplified = {
        totalAmount: 0,
        totalPayment: 0,
        pendingAmount: 0,
      };
    }
  }

  getAddon(data: BookingAccountUiPb) {
    let addons: AddonsAndExpensesSimplified[] = [];
    if (data && data?.products && data?.products?.baseProductLines) {
      data?.products?.baseProductLines.forEach((product) => {
        if (
          product.productReference.productType === ProductTypeUiPbEnum.ADD_ON ||
          product.productReference.productType === ProductTypeUiPbEnum.EXPENSE
        ) {
          if (
            product.baseProductLineState ===
            BaseProductLineState.BASE_PRODUCT_LINE_ACTIVE
          ) {
            let addExpObj: AddonsAndExpensesSimplified = {};

            addExpObj.name = product?.productReference?.productName;
            addExpObj.type = product?.productReference?.productType;
            addExpObj.totalUnits = product?.count;

            addExpObj.totalTracked = 0;

            product?.currentUsages?.individualUsageState.forEach((usage) => {
              if (
                usage.delivery.deliveryStatus === DeliveryStatusUi.DELIVERED
              ) {
                addExpObj.totalTracked += 1;
              }
            });

            addons.push(addExpObj);
          }
        }
      });
    }

    return addons;
  }

  getPayment(data: BookingAccountUiPb) {
    let singlePayment: PaymentTotalSimplified = {
      totalAmount: 0,
      totalPayment: 0,
      pendingAmount: 0,
    };
    singlePayment.id = data?.id || "";

    data?.products?.baseProductLines?.forEach((productLine) => {
      // productLine.licenses?.chargesForPLs.
      if (
        productLine?.licenses?.chargesForPLs?.individualChargesForPL?.length > 0
      ) {
        productLine.licenses?.chargesForPLs?.individualChargesForPL?.forEach(
          (indivChargePL) => {
            if (indivChargePL.individualCharges?.length > 0) {
              indivChargePL.individualCharges?.forEach((individualCharge) => {
                singlePayment.totalAmount +=
                  individualCharge.currentCharges?.charge?.chargeInfo
                    ?.finalPrice?.priceFloat || 0;
              });
            }
          }
        );
      } else {
        throw new Error("Error in calculating price", { cause: true });
      }
    });

    if (data && data.billingAccount) {
      if (
        data.billingAccount.containedBillingAccount?.payments?.payment?.length >
        0
      ) {
        data.billingAccount.containedBillingAccount?.payments?.payment?.forEach(
          (payment) => {
            if (payment?.paymentState === PaymentStateUiEnum.ACTIVE)
              singlePayment.totalPayment += payment.amount?.priceFloat || 0;
          }
        );
      }
    }

    singlePayment.pendingAmount =
      singlePayment.totalAmount - singlePayment.totalPayment;

    return singlePayment;
  }

  getStatusCounts(rooms: RoomSimplified[]) {
    let totalBookingStatus: totalBookingStatusSimplified = {
      totalCheckIns: 0,
      totalCheckedOut: 0,
      totalArrivals: 0,
      totalNoShows: 0,
      totalDelivered: 0,
      totalCancelled: 0,
    };

    if (rooms) {
      rooms.forEach((room) => {
        if (
          room.baseProductLineState ===
          BaseProductLineState.BASE_PRODUCT_LINE_CANCELLED
        ) {
          totalBookingStatus.totalCancelled += 1;
        } else {
          if (room.deliveryStatus === DeliveryStatusUi.DELIVERY_NOT_REQUESTED) {
            totalBookingStatus.totalArrivals += 1;
          } else if (
            room.deliveryStatus === DeliveryStatusUi.DELIVERY_STARTED
          ) {
            totalBookingStatus.totalCheckIns += 1;
          } else if (
            room.deliveryStatus === DeliveryStatusUi.DELIVERY_EXPIRED
          ) {
            totalBookingStatus.totalNoShows += 1;
          } else if (
            room.deliveryStatus === DeliveryStatusUi.DELIVERY_STOPPED
          ) {
            totalBookingStatus.totalCheckedOut += 1;
          } else {
            totalBookingStatus.totalDelivered += 1;
          }
        }
      });
    }

    return totalBookingStatus;
  }

  getPayTypeFromBookingAccount(data: BookingAccountUiPb) {
    let response;
    if (
      data?.comDetail?.payType === PayTypeUiPb.PART_PAYMENT &&
      data?.billingAccount &&
      data?.billingAccount?.externalBillingAccount
    ) {
      response = PayTypeEnum.PARTIAL_PAYMENT;
    } else if (
      data?.billingAccount &&
      data?.billingAccount?.externalBillingAccount
    ) {
      response = PayTypeEnum.BILL_TO_COMPANY;
    } else {
      if (data?.comDetail?.payType === PayTypeUiPb.ADHOC_CREDIT) {
        response = PayTypeEnum.ADHOC_CREDIT;
      } else {
        response = PayTypeEnum.PAY_AT_HOTEL;
      }
    }
    return response;
  }

  getReservationAddons(data: ProductsUiPb, id: string) {
    let response: AddonDetailUiSimplified[] = [];
    let products = data?.baseProductLines;
    if (products) {
      for (let i = 0; i < products.length; i++) {
        if (
          products[i]?.productReference?.productType ===
            ProductTypeUiPbEnum.ADD_ON ||
          products[i]?.productReference?.productType ===
            ProductTypeUiPbEnum.EXPENSE
        ) {
          const addon: AddonDetailUiSimplified = {
            productName: products[i]?.productReference?.productName,
            productType: products[i]?.productReference?.productType,
            produtctCode: products[i]?.productReference?.productCode,
            revenueHead: products[i]?.productReference?.revenueHead,
            currency: products[i]?.productReference?.currency,
            units: this.getUnits(products[i]),
            charge: this.getCharges(products[i]),
            notes: products[i]?.extraInfos?.commonInfo,
            infoType: products[i]?.productReference?.infoType,
            productLinePath: `${id}/${i}`,
            baseProductLineState: products[i].baseProductLineState,
          };
          response.push(addon);
        }
      }
    }

    return response;
  }

  getCharges(data: BaseProductLineUiPb) {
    let charges: ChargeSimplified = {
      charge: 0,
      tax: 0,
      totalCharge: 0,
    };

    data?.licenses?.chargesForPLs?.individualChargesForPL.forEach((charge) => {
      charges.charge +=
        charge.individualCharges[0].currentCharges?.charge?.chargeInfo?.priceExcludingTax?.priceFloat;
      charges.tax +=
        charge.individualCharges[0].currentCharges?.charge?.chargeInfo?.taxes?.totalTax?.priceFloat;
      charges.totalCharge +=
        charge.individualCharges[0].currentCharges?.charge?.chargeInfo?.finalPrice?.priceFloat;
    });

    return charges;
  }

  getUnits(data: BaseProductLineUiPb) {
    let units: UnitInterfaceUiSimplified = {
      tracked: 0,
      total: 0,
      remaining: 0,
    };
    if (
      data.baseProductLineState ===
      BaseProductLineState.BASE_PRODUCT_LINE_ACTIVE
    ) {
      units.total = data.count;
      data.currentUsages.individualUsageState.forEach((d) => {
        if (d.delivery.deliveryStatus === DeliveryStatusUi.DELIVERED) {
          units.tracked += 1;
        }
      });
      units.remaining = units.total - units.tracked;
    }

    return units;
  }

  getMainReservationPaymentDetails(data: PaymentsUiPb) {
    let payments: PaymentSimplified[] = [];

    if (data && data?.payment) {
      data?.payment.forEach((pay) => {
        const payment: PaymentSimplified = {
          mode: pay.modeOfPayment,
          state: pay.paymentState,
          paymentTime: pay.paymentTime?.milliSeconds
            ? moment(pay.paymentTime?.milliSeconds).toDate()
            : moment(
                pay.paymentTime?.formattedDateTime,
                "YYYYMMDD HH:mm:ss"
              ).toDate(),
          amount: pay.amount?.priceFloat,
          postedBy:
            pay?.timesForPayment?.postedBy &&
            pay?.timesForPayment?.postedBy.split("@")[0],
          isInternationalPayment: pay?.isInternationalPayment,
          transactionId: pay.transactionId,
        };
        payments.push(payment);
      });
    } else {
      payments = [];
    }

    return payments;
  }

  transformGSTToPatchValue(data: GSTInfoUiPb) {
    if (data && data.GSTIN) {
      return {
        benficiary: data?.GSTBeneficiary,
        gstin: data?.GSTIN,
        addressLine1: data?.address1,
        addressLine2: data?.address2,
        city: data?.location,
        pincode: isNaN(data.pinCode) ? undefined : Number(data.pinCode),
        state: data?.state,
      };
    }
  }

  allConnectedBookingAccounts: any[] = [];

  async getParentAndChildBookingAccountUiPb(id: string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (!id) {
        // If id is not provided, reject the Promise with an error
        reject(new Error("ID is not provided"));
        return;
      }

      try {
        const response: BookingAccountUiPb = await this.bookingAccountService
          .getBookingAccountData(id)
          .toPromise();

        const tranformedData = await this.transformCurrentBookingToSimplified(
          response
        );
        const promises: Promise<void>[] = [];

        if (!tranformedData?.mainResId) {
          this.allConnectedBookingAccounts.push({
            row: EncasedBillingReportRowType.MASTER_BILL,
            data: tranformedData,
          });
        } else {
          this.allConnectedBookingAccounts.push({
            row: EncasedBillingReportRowType.ADD_ON_BILL,
            data: tranformedData,
          });
        }

        if (tranformedData.mainResId) {
          promises.push(this.getDataForParent(tranformedData));
        }

        if (tranformedData.addonResId) {
          promises.push(this.getDataForChild(tranformedData));
        }

        await Promise.all(promises);

        resolve();
      } catch (error) {
        reject(
          new Error("Error while fetching booking detail of Res id " + id)
        );
      }
    });
  }

  async getDataForParent(data: BookingAccountUiSimplified): Promise<void> {
    const parent: any[] = [];
    const child: any[] = [];

    if (data.mainResId) {
      const res: BookingAccountUiPb = await this.bookingAccountService
        .getBookingAccountData(data?.mainResId)
        .toPromise();

      if (res) {
        const t = await this.transformCurrentBookingToSimplified(res);

        if (!t.mainResId) {
          parent.push({
            row: EncasedBillingReportRowType.MASTER_BILL,
            data: t,
          });
          this.allConnectedBookingAccounts.unshift(...parent);
        } else {
          child.push({ row: EncasedBillingReportRowType.ADD_ON_BILL, data: t });
          this.allConnectedBookingAccounts.push(...child);
          await this.getDataForParent(t);
        }
      }
    }
  }

  async getDataForChild(data: BookingAccountUiSimplified): Promise<void> {
    const child: any[] = [];

    if (data.addonResId) {
      const res: BookingAccountUiPb = await this.bookingAccountService
        .getBookingAccountData(data?.addonResId)
        .toPromise();

      if (res) {
        const t = await this.transformCurrentBookingToSimplified(res);
        child.push({ row: EncasedBillingReportRowType.ADD_ON_BILL, data: t });
        this.allConnectedBookingAccounts.push(...child);

        if (t.addonResId) {
          await this.getDataForChild(t);
        }
      }
    }
  }
}
