import {
  Compiler,
  Directive,
  Injector,
  Input,
  OnChanges,
  SimpleChanges,
  ViewContainerRef,
} from "@angular/core";
import { Loader } from "./loader.interface";
import { LoaderService } from "./loader.service";

@Directive({
  selector: "[appLazyLoader]",
})
export class LazyLoaderDirective implements OnChanges {
  @Input() appLazyLoader!: Loader;
  constructor(
    private container: ViewContainerRef,
    private injector: Injector,
    private compiler: Compiler,
    private loader: LoaderService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes["appLazyLoader"].currentValue) {
      return;
    }
    const { loader, module, component, data } = this.appLazyLoader;
    if (component) {
      // alert("1");
      this.loader.loadComponent({
        loader,
        component,
        container: this.container,
        data,
      });
    } else if (module) {
      this.loader.loadModule({
        loader,
        module,
        container: this.container,
        data,
        injector: this.injector,

        // compiler: this.compiler,
      });
    }
  }
}
