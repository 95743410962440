import { Injectable } from "@angular/core";
import { BookingAccountUiPb } from "../com/staybloom/reservation/uiproto/bookingAccountUiProto";
import {
  AddonQuickViewSimplified,
  InvoicePaymentSimiplified,
  PayTypeSimplified,
  ProfileSimplified,
} from "../_interfaces/licenseSimplifiedVersion";
import {
  ComDetailUiPb,
  PayTypeUiPb,
} from "../com/staybloom/reservation/uiproto/bookingServiceUiProto";
import { LOYALTY_PROGRAM_CONSTANTS } from "../constants/loyaltyProgramConstant";
import { ProductTypeUiPbEnum } from "../com/staybloom/uiproto/product/productUiProto";
import { DeliveryStatusUi } from "../com/staybloom/reservation/uiproto/deliveryUiProto";
import moment from "moment";
import { CancellationPolicyEnum } from "../com/staybloom/price/uiproto/cancellationPolicyUiProto";
import { CANCELLATION_POLICY_CONSTANT } from "../constants/cancellationPolicyConstant";
import { GenderUiPbEnum } from "../com/staybloom/common/uiproto/genderUiProto";
import { PayTypeEnum } from "../_interfaces/bookingAccountSimplified";
import { LicenseLineIndexUiPb } from "../com/staybloom/licenseIndex/uiproto/licenseSearchUiProto";
import {
  BillingAccountScopeUiPb,
  PaymentStateUiEnum,
} from "../com/staybloom/reservation/uiproto/billingAccountUiProto";
import { EncasedBillingReportUiPb } from "../com/staybloom/reports/uiproto/encasedGuestLedgerUiProto";
// import { PAY_TYPE_ENUM_SIMPLIFIED } from "../_enums/payments/PayTypeEnumSimplified";
// import { dialogTemplateContextSimplified } from "../_components";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  PayTypeUiPb: PayTypeUiPb;
  templateContext = {};
  constructor() {}

  getBookingAccountPaymentSummary(data: BookingAccountUiPb | undefined) {
    let InvoicePaymentDetail: InvoicePaymentSimiplified = {
      bookingId: "",
      finalPrice: 0,
      totalPayment: 0,
      balanceAmount: 0,
      accountType: "",

      addons: [],
    };

    if (data) {
      InvoicePaymentDetail.bookingId = data.id || "";
      data.products?.baseProductLines?.forEach((productLine) => {
        // productLine.licenses?.chargesForPLs.
        if (
          productLine?.licenses?.chargesForPLs?.individualChargesForPL
            ?.length ||
          0 > 0
        ) {
          productLine.licenses?.chargesForPLs?.individualChargesForPL?.forEach(
            (indivChargePL) => {
              if (indivChargePL.individualCharges?.length || 0 > 0) {
                indivChargePL.individualCharges?.forEach((individualCharge) => {
                  InvoicePaymentDetail.finalPrice +=
                    individualCharge.currentCharges?.charge?.chargeInfo
                      ?.finalPrice?.priceFloat || 0;
                });
              }
            }
          );
        } else {
          throw new Error("Error in calculating price", { cause: true });
        }
      });
    }

    if (data && data.billingAccount) {
      if (
        data.billingAccount.containedBillingAccount?.payments?.payment
          ?.length ||
        0 > 0
      ) {
        data.billingAccount.containedBillingAccount?.payments?.payment?.forEach(
          (payment) => {
            if (payment.paymentState !== PaymentStateUiEnum.VOID) {
              InvoicePaymentDetail.totalPayment +=
                payment.amount?.priceFloat || 0;
            }
          }
        );
      }
    }

    InvoicePaymentDetail.accountType = this.getPayTypeFromBookingAccount(data);

    if (data && data.products && data.products.baseProductLines) {
      data.products.baseProductLines.forEach((productLine) => {
        if (
          productLine.productReference?.productType ===
            ProductTypeUiPbEnum.ADD_ON ||
          productLine.productReference?.productType ===
            ProductTypeUiPbEnum.EXPENSE
        ) {
          // Check whether the group all units are tracked or not

          let addonDetail: AddonQuickViewSimplified = {
            name: productLine.productReference.productName || "",
            tracked: false,
            charge: 0,
            units: productLine.count || 1,
          };
          // Reusing above function to calculate the price
          if (
            productLine?.licenses?.chargesForPLs?.individualChargesForPL
              ?.length ||
            0 > 0
          ) {
            productLine.licenses?.chargesForPLs?.individualChargesForPL?.forEach(
              (indivChargePL) => {
                if (indivChargePL.individualCharges?.length || 0 > 0) {
                  indivChargePL.individualCharges?.forEach(
                    (individualCharge) => {
                      addonDetail.charge +=
                        individualCharge.currentCharges?.charge?.chargeInfo
                          ?.finalPrice?.priceFloat || 0;
                    }
                  );
                }
              }
            );
          } else {
            throw new Error("Error in calculating price", { cause: true });
          }

          // Check for Tracked

          if (productLine.currentUsages?.individualUsageState) {
            productLine.currentUsages.individualUsageState.forEach((usage) => {
              if (
                usage.delivery?.deliveryStatus ===
                DeliveryStatusUi.DELIVERY_NOT_REQUESTED
              ) {
                addonDetail.tracked = false;
              } else {
                addonDetail.tracked = true;
              }
            });
          }

          InvoicePaymentDetail.addons &&
            InvoicePaymentDetail.addons.push(addonDetail);
        }
      });
    }

    InvoicePaymentDetail.balanceAmount =
      InvoicePaymentDetail.finalPrice - InvoicePaymentDetail.totalPayment;
    console.log(data);
    InvoicePaymentDetail.accountStatus = data?.state;
    console.log(InvoicePaymentDetail);
    return InvoicePaymentDetail;
  }

  // Get Loyalty
  getGuestLoyalty(count: number) {
    if (count > 0) {
      if (count > 0 && count <= 3) {
        return LOYALTY_PROGRAM_CONSTANTS.ROAD_WARRIOR;
      } else if (count > 3 && count < 10) {
        return LOYALTY_PROGRAM_CONSTANTS.JET_SETTER;
      } else {
        return LOYALTY_PROGRAM_CONSTANTS.AMBASSADOR;
      }
    }
  }

  // Get Paytype of Hotel
  getPayTypeOfBooking(type: PayTypeUiPb) {
    if (type === PayTypeUiPb.ADHOC_CREDIT) {
      return "Adhoc Credit";
    } else if (type === PayTypeUiPb.BILL_TO_COMPANY) {
      return "Bill to Company";
    } else if (type === PayTypeUiPb.CREDIT_NOTE) {
      return "Credit Note";
    } else if (type === PayTypeUiPb.PART_PAYMENT) {
      return "Partial Payment";
    } else if (type === PayTypeUiPb.PAY_BY_COMPANY) {
      return "Pay by company";
    } else {
      return "Pay at hotel";
    }
  }

  // Get Current Date Room Fulfillment
  getRoomIndex(sTime: any, eTime: any, data: any) {
    const startDate = moment(sTime, "YYYYMMDD");
    const endDate = moment(eTime, "YYYYMMDD");
    const currentDate = moment();
    const roomArray = data;

    if (roomArray.length > 0 && roomArray[0]?.roomReference?.roomName) {
      if (currentDate.isBefore(startDate)) {
        return roomArray[0]?.roomReference?.roomName;
      } else if (currentDate.isAfter(endDate)) {
        return roomArray[roomArray.length - 1].roomReference.roomName;
      } else {
        const nDate = currentDate.diff(startDate, "days");
        return roomArray[nDate].roomReference.roomName;
      }
    } else {
      return "-";
    }
  }

  getAge(bdayDate: string) {
    var bDay = moment().diff(moment(bdayDate).format(), "years");

    if (bDay <= 0) {
      return undefined; // Return undefined for ages <= 0
    }

    var year = Math.floor(bDay / 10) * 10;
    var bdayYear;
    if (bDay >= 20) {
      bdayYear = year + "'s";
    } else {
      bdayYear = bDay;
    }
    return bdayYear;
  }

  getCancellationPolicy(data: CancellationPolicyEnum) {
    let response = "";
    if (data === CancellationPolicyEnum.HR_24_CANCELLATION_POLICY) {
      response = CANCELLATION_POLICY_CONSTANT.HOURS24;
    } else if (data === CancellationPolicyEnum.HR_48_CANCELLATION_POLICY) {
      response = CANCELLATION_POLICY_CONSTANT.HOURS48;
    } else if (data === CancellationPolicyEnum.NON_REFUNDABLE_POLICY) {
      response = CANCELLATION_POLICY_CONSTANT.NONREFUNDABLE;
    } else if (data === CancellationPolicyEnum.FREE_CANCELLATION_POLICY) {
      response = CANCELLATION_POLICY_CONSTANT.FREECANCELLATION;
    } else if (data === CancellationPolicyEnum.GROUP_BOOKING_POLICY) {
      response = CANCELLATION_POLICY_CONSTANT.GROUPCANCELLATION;
    } else if (data === CancellationPolicyEnum.DAY_07_CANCELLATION_POLICY) {
      response = CANCELLATION_POLICY_CONSTANT.SEVENDAY;
    } else if (data === CancellationPolicyEnum.DAY_14_CANCELLATION_POLICY) {
      response = CANCELLATION_POLICY_CONSTANT.FOURTEENDAY;
    } else {
      return;
    }

    return response;
  }

  getStandardHex(status: string) {
    let hex;

    if (status === DeliveryStatusUi.DELIVERY_NOT_REQUESTED) {
      hex = "#5ca100";
    } else if (status === DeliveryStatusUi.DELIVERY_STARTED) {
      hex = "#ffcc09";
    } else if (
      status === DeliveryStatusUi.DELIVERY_EXPIRED ||
      status === DeliveryStatusUi.DELIVERED
    ) {
      hex = "#F47D7D";
    } else if (status === DeliveryStatusUi.DELIVERY_STOPPED) {
      hex = "#9190DB";
    }

    return hex;
  }
  enumConvertorForSelectOption(data: any) {
    return Object.keys(data).map((key) => ({
      key,
      value: data[key as keyof typeof data],
    }));
  }

  getUserDetails(user: ProfileSimplified) {
    let totalStays = user?.stayCount;
    let age = this.getAge(user?.age);
    let nationality = user?.nationality;

    let totalStayText =
      totalStays === 0
        ? ""
        : totalStays === 1
        ? `${totalStays} stay`
        : totalStays > 1
        ? `${totalStays} stays`
        : "";
    let gender = !user.gender
      ? ""
      : user.gender === GenderUiPbEnum.Male
      ? "Male"
      : user.gender === GenderUiPbEnum.Female
      ? "Female"
      : "";

    return `${age} ${gender && `• ${gender}`} ${
      nationality && `• ${nationality}`
    } ${totalStayText && `• ${totalStayText}`}`;
  }

  getBookingStatus(state: DeliveryStatusUi) {
    let result = "";
    if (state === DeliveryStatusUi.DELIVERY_NOT_REQUESTED) {
      result = "Not Checked-In";
    } else if (state === DeliveryStatusUi.DELIVERY_STARTED) {
      result = "Checked-In";
    } else if (state === DeliveryStatusUi.DELIVERY_STOPPED) {
      result = "Checked-Out";
    } else if (state === DeliveryStatusUi.DELIVERY_EXPIRED) {
      result = "No Show";
    } else if (state === DeliveryStatusUi.DELIVERED) {
      result = "Delivered";
    }
    return result;
  }

  getRoomNo(roomNo: string) {
    let response: any;

    if (roomNo === "-") {
      response = "-";
    } else {
      const roomArr = roomNo.split(" ");
      response = roomArr[roomArr.length - 1];
    }
    return response;
  }

  removeNullUndefinedProperties(obj: any) {
    for (var key in obj) {
      if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
        delete obj[key];
      } else if (typeof obj[key] === "object") {
        this.removeNullUndefinedProperties(obj[key]); // Recursively check nested objects
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key]; // Remove the nested object if it's empty
        }
      }
    }
  }

  downloadCSV(data: any) {
    const flatData = this.flattenData(data);
    const headers = this.extractHeaders(flatData);
    const csvContent =
      "data:text/csv;charset=utf-8," + this.convertToCSV(headers, flatData);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);

    link.click();

    // Clean up
    document.body.removeChild(link);
  }

  // Flatten nested objects
  private flattenData(data: any[]): any[] {
    return data.map((item) => {
      const flatItem: any = {};
      this.flattenObject(flatItem, item, "");
      return flatItem;
    });
  }

  // Recursively flatten nested objects
  private flattenObject(result: any, obj: any, prefix: string): void {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const propName = prefix ? `${prefix}.${key}` : key;
        if (typeof obj[key] === "object" && obj[key] !== null) {
          this.flattenObject(result, obj[key], propName);
        } else {
          result[propName] = obj[key];
        }
      }
    }
  }

  // Extract headers from flattened data
  private extractHeaders(data: any[]): string[] {
    const headers = new Set<string>();
    data.forEach((item) => {
      Object.keys(item).forEach((key) => headers.add(key));
    });
    return Array.from(headers);
  }

  private convertToCSV(headers: string[], data: any[]): string {
    const headerRow = headers.join(",") + "\n";
    const bodyRows = data.map((row) =>
      headers.map((header) => row[header] || "").join(",")
    );

    return headerRow + bodyRows.join("\n");
  }

  exportCsv(
    data: any,
    reportName: string,
    keyArray: any[],
    columnNamesArray: any[]
  ) {
    this.downloadFile(data, reportName, keyArray, columnNamesArray);
  }

  downloadFile(
    data: any[],
    reportName: string,
    keyArray: any[],
    columnNamesArray: any[]
  ) {
    let arrHeader = keyArray;
    let csvData = this.ConvertToCSV(data, arrHeader, columnNamesArray);
    let blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", reportName + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(
    objArray: any[],
    headerList: Array<string>,
    columnNamesArray: any[]
  ) {
    let array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "S.No,";

    let newHeaders = columnNamesArray;

    for (let index in newHeaders) {
      row += newHeaders[index] + ",";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";

    function parseNestedObject(obj: any, header: string) {
      if (header.includes(".")) {
        const nestedHeaders = header.split(".");
        let nestedObj = obj;
        for (let i = 0; i < nestedHeaders.length; i++) {
          if (nestedObj[nestedHeaders[i]] !== undefined) {
            nestedObj = nestedObj[nestedHeaders[i]];
          } else {
            nestedObj = null;
            break;
          }
        }
        return nestedObj;
      } else {
        return obj[header];
      }
    }

    for (let i = 0; i < array.length; i++) {
      let line = i + 1 + "";
      for (let index in headerList) {
        let head = headerList[index];

        line += "," + this.strRep(parseNestedObject(array[i], head));
      }
      str += line + "\r\n";
    }
    return str;
  }

  strRep(data: string | number) {
    if (typeof data == "string") {
      let newData = data.replace(/,/g, " ");
      return newData;
    } else if (data == undefined || data === null) {
      return "";
    } else if (typeof data == "number") {
      return data.toString();
    } else {
      return data;
    }
  }

  getPayTypeFromLicense(license: LicenseLineIndexUiPb) {
    return this.getPayTypeEnum(
      license?.billingAccRef?.billingAccountScope,
      license.comDetail
    );
  }

  getPayTypeFromBookingAccount(data: BookingAccountUiPb): PayTypeSimplified {
    if (data?.billingAccount && data?.billingAccount?.externalBillingAccount) {
      return this.getPayTypeEnum(
        BillingAccountScopeUiPb.LONG_TERM,
        data?.comDetail
      );
    } else {
      return this.getPayTypeEnum(
        BillingAccountScopeUiPb.RESERVATION,
        data?.comDetail
      );
    }
  }

  getPayTypeEnum(
    billingAccoundScopeType: BillingAccountScopeUiPb,
    comDetail: ComDetailUiPb
  ): PayTypeSimplified {
    if (billingAccoundScopeType == BillingAccountScopeUiPb.LONG_TERM) {
      if (comDetail?.payType == PayTypeUiPb.BILL_TO_COMPANY) {
        return PayTypeSimplified.BTC;
      } else if (comDetail?.payType == PayTypeUiPb.PART_PAYMENT) {
        return PayTypeSimplified.PARTIAL_PAYMENT;
      } else if (comDetail?.payType == PayTypeUiPb.CREDIT_NOTE) {
        return PayTypeSimplified.CREDIT_NOTE;
      } else {
        return PayTypeSimplified.BTC;
      }
    } else if (billingAccoundScopeType == BillingAccountScopeUiPb.RESERVATION) {
      if (comDetail?.payType == PayTypeUiPb.PAY_BY_COMPANY) {
        return PayTypeSimplified.PAY_BY_COMPANY;
      } else if (comDetail?.payType == PayTypeUiPb.ADHOC_CREDIT) {
        return PayTypeSimplified.PARTIAL_PAYMENT;
      } else if (comDetail?.payType == PayTypeUiPb.CREDIT_NOTE) {
        return PayTypeSimplified.CREDIT_NOTE;
      } else {
        return PayTypeSimplified.PAY_AT_HOTEL;
      }
    } else {
      return PayTypeSimplified.PAY_AT_HOTEL;
    }
  }

  getPayTypeFromEncased(bill: EncasedBillingReportUiPb): PayTypeSimplified {
    let comDetail: ComDetailUiPb = {
      payType: bill?.payType,
    };
    return this.getPayTypeEnum(bill?.accountScope, comDetail);
  }

  fetchPayTypeOptions() {
    const payType = Object.keys(PayTypeUiPb).map((key) => {
      if (key === PayTypeUiPb.POSTPAID) {
        return {
          key: "Pay at hotel",
          value: PayTypeUiPb[key as keyof typeof PayTypeUiPb],
        };
      } else if (key == PayTypeUiPb.PART_PAYMENT) {
        return {
          key: "Part payment",
          value: PayTypeUiPb[key as keyof typeof PayTypeUiPb],
        };
      } else {
        return {
          key: key
            .replace(/_/g, " ")
            .toLowerCase()
            .replace(/\b\w/g, (c) => c.toUpperCase()),
          value: PayTypeUiPb[key as keyof typeof PayTypeUiPb],
        };
      }
    });
    return payType;
  }
}
