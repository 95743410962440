<div class="dialog_box_container">
  <!-- <button mat-button mat-dialog-close class="close">x</button> -->
  <div class="dialogbox">
    <div mat-dialog-content>
      <div
        class="container-full"
        *ngIf="data.layout === DialogLayoutEnum.FULLLAYOUT"
      >
        <ng-container [appLazyLoader]="lazyModuleConfig"></ng-container>
      </div>
      <ng-container *ngIf="data.layout == DialogLayoutEnum.COLUMNLAYOUT">
        <div class="container">
          <div class="booking_reference_cards">
            <app-profile-reference-cards
              [checkInType]="data?.checkInType"
              [profileUiPb]="data?.profileUiPb"
              [bookingData]="data?.bookingAccount"
              [data]="data.license"
              *ngIf="data.type === DialogTypeEnum.PROFILETYPE; else BOOKINGTYPE"
            ></app-profile-reference-cards>

            <ng-template #BOOKINGTYPE>
              <app-booking-reference-cards
                [data]="data.license"
                [contextData]="contextData"
                [bookingDetail]="simplifiedBookingAccount"
              ></app-booking-reference-cards>
            </ng-template>
          </div>
          <div class="randomTemplateContainer">
            <div class="widget_wrapper">
              <!-- <ng-container #container [ngComponentOutlet]="data.component" ></ng-container>  Please done remove this snippet code-->
              <ng-container
                *ngIf="showModule && lazyModuleConfig?.data"
                [appLazyLoader]="lazyModuleConfig"
              ></ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="data.layout == DialogLayoutEnum.LEGEND">
        <div [ngClass]="data?.legendDetails?.class">
          <img [src]="data?.legendDetails?.imageUrl" alt="" />
        </div>
      </ng-container>
    </div>
  </div>
</div>
