import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/_services/auth.service";
import { StorageService } from "src/app/_services/storage.service";

@Component({
  selector: "app-unit-level-layout",
  templateUrl: "./unit-level-layout.component.html",
})
export class UnitLevelLayoutComponent implements OnInit {
  constructor(private _authService: AuthService) {}
  ngOnInit() {
    this._authService.validateLoginUser();
  }
}
