import { Injectable } from "@angular/core";
import Dexie from "dexie";
import { PMS_INDEX_DB_CONSTANT } from "../constants/indexDBConstant";

@Injectable({
  providedIn: "root",
})
export class DbService extends Dexie {
  constructor() {
    super(PMS_INDEX_DB_CONSTANT.NAME);

    let data = {
      inventoryStore: `${PMS_INDEX_DB_CONSTANT.INVENTORY_STORE_CONFIG.INVENTORY_STORE_INDEX}, ${PMS_INDEX_DB_CONSTANT.INVENTORY_STORE_CONFIG.PROPERTY_REF_INDEX}`,
      inHouseGuestDetails: `${PMS_INDEX_DB_CONSTANT.INHOUSE_GUEST_DETAIL_STORE_INDEX_CONFIG.INHOUSE_GUEST_DETAIL_STORE_INDEX}`,
      productDetails: `${PMS_INDEX_DB_CONSTANT.PRODUCT_DETAILS_STORE_INDEX_CONFIG.PRODUCT_DETAILS_STORE_INDEX}`,
      sellerDbConfig: `${PMS_INDEX_DB_CONSTANT.SELLER_DB_STORE_INDEX_CONFIG.SELLER_DB_STORE_INDEX}`,
      CRManagerStore: `${PMS_INDEX_DB_CONSTANT.CR_MANAGER_STORE_INDEX_CONFIG.CR_MANAGER_STORE_INDEX}`,
      corporatePlanStore: `${PMS_INDEX_DB_CONSTANT.CORPORATE_PLAN_STORE_INDEX_CONFIG.CORPORATE_PLAN_STORE_INDEX}`,
      CustomerProfilesStore: `${PMS_INDEX_DB_CONSTANT.NON_INDIVIDUALS_STORE_INDEX_CONFIG.NON_INDIVIDUALS_STORE_INDEX}`,
    };

    this.version(1).stores(data);

    this.open()
      .then((data) => {
        // console.log(`IndexDB Database named ${data.name} started...`);
      })
      .catch((err) => console.log(err.message));
  }
}
