<header class="header" #mainContainer>
  <div #propertyDiv class="property">
    <div class="property__image" *ngIf="data.propertyImage">
      <img
        [src]="
          data.propertyImage
            ? data.propertyImage
            : '/assets/images/property_image_holder.png'
        "
        alt="{{ data.propertyName }}"
      />
    </div>
    <div class="content">
      <h1 class="property__page">
        <div>{{ data.pageTitle }}</div>
        <div class="property__page__container" *ngIf="!isPropertyPage">
          <img
            *ngIf="!isFavorite"
            (click)="storePageTitle()"
            class="property__page__container image"
            src="/assets/icons/Bookmark (2).svg"
            alt=""
          />
          <img
            (click)="!isFavorite ? storePageTitle() : removeFromShorcut()"
            title="{{
              !isFavorite ? 'Add to shortcut' : 'Remove from shortcut'
            }}"
            class="property__page__container hoverimage"
            src="/assets/icons/Bookmark.svg"
            alt=""
          />
          <img
            *ngIf="isFavorite"
            (click)="removeFromShorcut()"
            matTooltip="{{ 'Remove from shortcut' }}"
            class="property__page__container favimage"
            src="/assets/icons/Bookmark (1).svg"
            alt=""
          />
        </div>
      </h1>
      <p class="property__name">{{ data?.propertyName }}</p>
    </div>
  </div>
  <div class="action_container">
    <div class="btn_containers">
      <div class="btn_containers_icons">
        <button class="button disabled" title="">
          <img src="/assets/icons/page_header/globe.svg" alt="" />
        </button>
        <a title="Properties" routerLink="/unit/properties" class="button">
          <img src="/assets/icons/page_header/Hotels.svg" alt="" />
        </a>
        <button title="Logout" class="button" (click)="logout()">
          <img src="/assets/icons/page_header/log-out.svg" alt="" />
        </button>
      </div>
      <div
        class="btn_containers_favlink"
        #favlinkContainer
        *ngIf="favLinks?.length > 0 && !isPropertyPage"
      >
        <div
          class="btn_containers_favlink_previousbookmarks"
          #leftScrollButton
          (click)="scrollToRight()"
        >
          <img src="/assets/icons/Vector (1).svg" alt="" />
        </div>
        <div class="btn_containers_favlink_bookmarkslinks" #scrollContainer>
          <div
            class="btn_containers_favlink_bookmarkslinks_container"
            *ngFor="let favs of favLinks as array; let i = index"
            [ngClass]="{ selected: isSelected(favs) }"
            (click)="selectTab(favs)"
          >
            <div
              class="btn_containers_favlink_bookmarkslinks_container_blocks"
              (click)="redirectOnFavLink(favs?.url)"
            >
              <div>{{ favs?.pagetitle }}</div>
              <div>
                <img
                  *ngIf="!isSelected(favs)"
                  class="btn_containers_favlink_bookmarkslinks_container_blocks_image image"
                  src="/assets/icons/Group 358.svg"
                  alt=""
                />
                <img
                  class="btn_containers_favlink_bookmarkslinks_container_blocks_hoverimage hoverimage"
                  src="/assets/icons/Group 358 (2).svg"
                  alt=""
                />
                <img
                  *ngIf="isSelected(favs)"
                  class="btn_containers_favlink_bookmarkslinks_container_blocks_favimage favimage"
                  src="/assets/icons/Group 358 (1).svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="btn_containers_favlink_morebookmarks"
          #rightScrollButton
          (click)="scrollToLeft()"
        >
          <img src="/assets/icons/Vector (1).svg" alt="" />
        </div>
      </div>
    </div>
    <div class="others">
      <!-- <img src="/assets/icons/page_header/bloom_tech_yellow.svg" alt="" /> -->
      <object
        data="/assets/icons/animated/header_bloom_tech_logo.svg"
        type="image/svg+xml"
      ></object>
      <p>{{ getAuthUser }}</p>
    </div>
  </div>
</header>
