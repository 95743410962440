import { GenderUiPbEnum } from "../com/staybloom/common/uiproto/genderUiProto";
import {
  TimeUiPb,
  TimeRangeUiPb,
} from "../com/staybloom/common/uiproto/timeUiProto";
import { ProfileTypeUi } from "../com/staybloom/profile/uiproto/profileUiProto";
import { S3ObjectUiPb } from "../com/staybloom/s3Object/uiproto/s3ObjectUiProto";
import { CountryUiEnum } from "../com/staybloom/uiproto/address/countryUiProto";

export enum SideKickDeviceFunctionTypeUiEnum {
  SELF_CHECK_IN = "SELF_CHECK_IN",
  ROOM_SIGNAGE = "ROOM_SIGNAGE",
  FRONT_DESK_ASSISTANT = "FRONT_DESK_ASSISTANT",
  RESERVATION_WATCH = "RESERVATION_WATCH",
  WEBSITE_WATCH = "WEBSITE_WATCH",
}

export enum SideKickInterfaceTypeUiEnum {
  SIDEKICK_SCREEN_TEST = "SIDEKICK_SCREEN_TEST",
  SELF_CHECK_IN_DEFAULT = "SELF_CHECK_IN_DEFAULT",
  SELF_CHECK_IN_INITIATED = "SELF_CHECK_IN_INITIATED",
  SELF_CHECK_IN_IMAGE_CAPTURE = "SELF_CHECK_IN_IMAGE_CAPTURE",
  SELF_CHECK_IN_PROFILE_NOT_VERIFIED = "SELF_CHECK_IN_PROFILE_NOT_VERIFIED",
  SELF_CHECK_IN_NO_BOOKING = "SELF_CHECK_IN_NO_BOOKING",
  SELF_CHECK_IN_UNAUTHENTICATED = "SELF_CHECK_IN_UNAUTHENTICATED",
  SELF_CHECK_IN_UNHANDLED_ERROR = "SELF_CHECK_IN_UNHANDLED_ERROR",
  SELF_CHECK_IN_ALREADY_CHECKED_IN = "SELF_CHECK_IN_ALREADY_CHECKED_IN",
  SELF_CHECK_IN_CANCELLED = "SELF_CHECK_IN_CANCELLED",
  SELF_CHECK_IN_COMPLETED = "SELF_CHECK_IN_COMPLETED",
  FRONT_DESK_ASSISTANT_DEFAULT = "FRONT_DESK_ASSISTANT_DEFAULT",
  FRONT_DESK_ASSISTANT_TEST = "FRONT_DESK_ASSISTANT_TEST",
  FRONT_DESK_ASSISTANT_PROFILE_CHECK = "FRONT_DESK_ASSISTANT_PROFILE_CHECK",
  FRONT_DESK_ASSISTANT_UPI_QR_PAYMENT_REQUEST = "FRONT_DESK_ASSISTANT_UPI_QR_PAYMENT_REQUEST",
  FRONT_DESK_ASSISTANT_UPI_QR_PAYMENT_SUCCESS = "FRONT_DESK_ASSISTANT_UPI_QR_PAYMENT_SUCCESS",
  FRONT_DESK_ASSISTANT_UPI_QR_PAYMENT_ERROR = "FRONT_DESK_ASSISTANT_UPI_QR_PAYMENT_ERROR",
  BOOKING_DEFAULT = "BOOKING_DEFAULT",
  ROOM_SIGNAGE_DEFAULT = "ROOM_SIGNAGE_DEFAULT",
}

export enum UPIPaymentStatusUiEnum {
  INITIATED = "INITIATED",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export enum PayTypeUiEnum {
  PREPAID = "PREPAID",
  POSTPAID = "POSTPAID",
  BILL_TO_COMPANY = "BILL_TO_COMPANY",
  PART_PAYMENT = "PART_PAYMENT",
  ADHOC_CREDIT = "ADHOC_CREDIT",
  CREDIT_NOTE = "CREDIT_NOTE",
  PAY_BY_COMPANY = "PAY_BY_COMPANY",
}

export enum PlaceOccupancyUiEnum {
  VACANT = "VACANT",
  EX_ARRIVAL = "EX_ARRIVAL",
  OCCUPIED = "OCCUPIED",
  EX_DEPARTURE = "EX_DEPARTURE",
}

export enum PlaceStateUiEnum {
  OUT_OF_ORDER = "OUT_OF_ORDER",
  DIRTY = "DIRTY",
  TOUCH_UP = "TOUCH_UP",
  QUICK_CLEAN = "QUICK_CLEAN",
  INSPECT = "INSPECT",
  CLEAN = "CLEAN",
}

export enum TaskStateUiEnum {
  UN_ASSIGNED = "UN_ASSIGNED",
  ASSIGNED = "ASSIGNED",
  IN_PROCESS = "IN_PROCESS",
  REVISIT_SUBTASK = "REVISIT_SUBTASK",
  PAUSED = "PAUSED",
  DONE = "DONE",
}

export enum TaskTypeUiEnum {
  GUEST_REQUEST = "GUEST_REQUEST",
  PREVENTIVE = "PREVENTIVE",
  WORK_ORDER = "WORK_ORDER",
}

export interface SideKickStateUiPb {
  id?: string;
  inventoryStoreId?: string;
  skdFunctionType?: SideKickDeviceFunctionTypeUiEnum;
  roomName?: string;
  lastSideKickTransactionUpdateTime?: TimeUiPb;
  sideKickTransaction?: SideKickTransactionUiPb[];
}

export interface SideKickTransactionUiPb {
  sideKickDeviceId?: string;
  sideKickInterfaceTypeEnum?: SideKickInterfaceTypeUiEnum;
  selfCheckInTransactionData?: SelfCheckInTransactionDataUiPb;
  frontDeskAssistantTransactionData?: FrontdeskAssistantTransactionDataUiPb;
  roomSignageTransactionData?: RoomSignageTransactionDataUiPb;
  userRef?: UserReferenceUiPb;
  unitRef?: UnitRefUiPb;
  bookingRef?: BookingRefUiPb;
  bookingTransactionData?: BookingTransactionData;
}

export interface BookingTransactionData {
  allowPayAtHotel?: boolean;
  bookingURL?: String;
}

export interface SelfCheckInTransactionDataUiPb {
  sideKickDeviceId?: string;
}

export interface FrontdeskAssistantTransactionDataUiPb {
  upiQRPaymentInfo?: UPIQRPaymentInfoUiPb;
}

export interface RoomSignageTransactionDataUiPb {
  placeOccupancy?: PlaceOccupancyUiEnum;
  placeState?: PlaceStateUiEnum;
  roomUnlockVideoURL?: string;
  defaultVideoURL?: string;
  dndActivated?: boolean;
  evacuationActivated?: boolean;
  outOfOrderActivated?: boolean;
  task?: TaskRefUiPb[];
}

export interface UPIQRPaymentInfoUiPb {
  qrCodeId?: string;
  qrCodeURL?: string;
  paymentAmount?: number;
  transactionId?: string;
  message?: string;
  status?: UPIPaymentStatusUiEnum;
}

export interface UserReferenceUiPb {
  id?: string;
  profileType?: ProfileTypeUi;
  firstName?: string;
  lastName?: string;
  email?: string;
  mobile?: string;
  gender?: GenderUiPbEnum;
  dob?: string;
  stayCount?: number;
  profilePictureURL?: S3ObjectUiPb;
  nationality?: CountryUiEnum;
  guestTypeEnum?: GuestTypeEnum;
}

export interface UnitRefUiPb {
  inventoryStoreId?: string;
  propertyId?: string;
  blootifiConfigId?: string;
  bridgeConfigId?: string;
  hotelFullName?: string;
  hotelBrandName?: string;
  city?: string;
  hotelPhotoURL?: string;
}

export interface BookingRefUiPb {
  bookingId?: string;
  tagName?: string;
  payType?: PayTypeUiEnum;
  roomRef?: RoomRefUiPb[];
}

export interface RoomRefUiPb {
  productName?: string;
  roomName?: string;
  inclusionPlanName?: string;
  inclusions?: string[];
  stayRange?: TimeRangeUiPb;
  expectedArrivalTime?: TimeUiPb;
  expectedDepartureTime?: TimeUiPb;
  pax?: number;
  verifiedGuests?: UserReferenceUiPb[];
}

export interface TaskRefUiPb {
  taskId?: string;
  taskName?: string;
  taskState?: TaskStateUiEnum;
  taskType?: TaskTypeUiEnum;
  deadlineTime?: TimeUiPb;
  taskMaster?: BridgeUserRefUiPb;
}

export interface BridgeUserRefUiPb {
  username?: string;
  profilePictureURL?: string;
}

export interface SideKickStateChangeReqUiPb {
  sideKickEvent?: SideKickEventUiPb[];
}

export interface SideKickEventUiPb {
  password?: string;
  sideKickTransaction?: SideKickTransactionUiPb;
  pushedToDevice?: boolean;
  errorMessage?: string;
}

export interface SideKickStateChangeRespUiPb {
  sideKickEvent?: string[];
}

export interface SideKickCheckConnectionRespUiPb {
  deviceId?: string;
  deviceReachable?: boolean;
  errorMessage?: string;
}

export enum GuestTypeEnum {
  USER = "USER",
  CLIENT = "CLIENT",
}
