<!-- <footer>

</footer> -->

<aside class="sidebar" (mouseleave)="mouseleave()">
  <!-- Main Menu -->
  <div class="main_menu">
    <div class="logo">
      <img src="/assets/icons/pms_logo.svg" alt="" />
    </div>
    <!-- Top Level Menu -->
    <div class="top_level_menu">
      <div class="item" *ngFor="let page of FoNavigationMenu">
        <div
          class="nav_item"
          #nav_element
          (mouseenter)="mouseenter(nav_element)"
        >
          <div (click)="showDropDown(page.label)">
            <a
              [routerLink]="[page.path, currentHotelInventoryId]"
              class="link"
              [class.activeRoute]="
                currentUrl?.includes(page.label.toLowerCase())
              "
            >
              <div class="icon_container">
                <img src="{{ page.icon }}" alt="" class="icon" />
              </div>
              <span class="label">{{ page.label }}</span>
            </a>
          </div>
          <div class="dropdown_container">
            <a
              class="child_link"
              *ngFor="let child of page.children"
              [routerLink]="[child.path, currentHotelInventoryId]"
              routerLinkActive="childActiveRoute"
              [class.disableLink]="child.disable"
            >
              {{ child.label }}
            </a>
          </div>
          <div class="angle"></div>
        </div>
        <div class="fixed_sub_menu" *ngIf="page.isOpen">
          <nav class="header">
            <div *ngFor="let child of page.children" class="child_route">
              <a
                [routerLink]="[child.path, currentHotelInventoryId]"
                routerLinkActive="childActiveRoute"
                class="link"
                >{{ child.label }}</a
              >
            </div>
          </nav>
          <footer class="footer">
            <!-- <div class="bottom_fixed_menu">
              <a routerLink="/unit/properties" class="link">
                <img src="/assets/icons/Hotels.svg" alt="" class="icon" />
                <span class="label">Hotels</span>
              </a>

              <button class="link">
                <img src="/assets/icons/log-out.svg" alt="" class="icon" />
                <span class="label">Logout</span>
              </button>
            </div> -->
            <div class="user_detail">
              <!-- <p class="email">umar.bashir<br /><span>@staybloom.com</span></p> -->
              <p class="job_title">Reservation manager</p>
            </div>
            <div class="tech_logo">
              <img src="/assets/images/bloom_tech_logo.svg" alt="" />
            </div>
          </footer>
        </div>
      </div>
    </div>

    <!-- Bottom Fixed Menu -->
  </div>
  <!-- Fixed Sub Menu -->
</aside>
