export let ModuleMap: any = {
  //lazy load Modules
  PAYMENT: {
    loader: () => import("../_shared/payment/payment-widget.module"),
    module: "PaymentWidgetModule",
    data: {},
    // Add more modules here based on your requirements
  },
  ASSIGN_ROOM: {
    loader: () => import("../_shared/assignRoom/assignRoom-shared.module"),
    module: "AssignRoomSharedModule",
    data: {},
  },
  SEARCH: {
    loader: () => import("../_shared/searchModal/search.module"),
    module: "SearchModule",
    data: {},
  },

  ADD_ON: {
    loader: () => import("../_shared/addOn/addOn.module"),
    module: "AddOnModule",
    data: {},
  },
  NOSHOW: {
    loader: () => import("../_shared/noShow/noShow.module"),
    module: "NoShowModule",
    data: {},
  },
  ROOM_CHECKOUT: {
    loader: () => import("../_shared/checkout/checkout.module"),
    module: "CheckoutModule",
    data: {},
  },
  WEB_CHECKIN: {
    loader: () => import("../_shared/webCheckin/web-checkin-module"),
    module: "WebCheckinModule",
    data: {},
  },

  ////==================================================================================////

  //Lazy load standalone components
  MOREACTIONS: {
    loader: () =>
      import(
        "../_components/dialogs/more-actions-widget/more-actions-widget.component"
      ),
    component: "MoreActionsWidgetComponent",
  },
  PROFILE: {
    loader: () => import("../_shared/profile/profile-shared.module"),
    module: "ProfileSharedModule",
    data: {},
  },
  GUESTCHECKIN: {
    loader: () =>
      import("../_shared/guest-checkin-shared/guest-checkin-shared.module"),
    module: "GuestCheckinSharedModule",
    data: {},
  },
  SEARCHPROFILE: {
    loader: () =>
      import("../_shared/search-profile-shared/search-profile-shared.module"),
    module: "SearchProfileSharedModule",
    data: {},
  },
  UPGRADE_ROOM: {
    loader: () => import("../_shared/upgradeRoom/upgrade-room.module"),
    module: "UpgradeRoomModule",
    data: {},
    // Add more modules here based on your requirements
  },
  BILLS_INVOICE: {
    loader: () => import("../_shared/bill-invoice/bill-invoice.module"),
    module: "BillInvoiceModule",
    data: {},
  },
  MULTI_ASSIGN_ROOMS_WIDGET: {
    loader: () =>
      import(
        "../_shared/multi-assign-room-widget/multi-assign-room-widget.module"
      ),
    module: "MultiAssignRoomWidgetModule",
    data: {},
  },
  MODIFY_STAY_DATE: {
    loader: () =>
      import("../_shared/modify-booking-date/modify-booking-date.module"),
    module: "ModifyBookingDateModule",
    data: {},
  },

  ROOM_SELECTION_CARD: {
    loader: () =>
      import("../_shared/roomselectioncard/room-selection-card.module"),
    module: "RoomSelectionModule",
    data: {},
  },
};
